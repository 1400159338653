<template>
    <el-form-item :label="label || $t('Cost Type')" :prop="prop">
        <el-select style="width: 100%;" :value="selected" @input="dispatch" :placeholder="plc || $t('Cost Type')" filterable clearable :size="size">
            <el-option v-for="item in costs" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
        </el-select>
    </el-form-item>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import mix from '@/utils/mixins/fields/form-item';

    export default {
        name: 'costs',
        mixins: [mix],
        props: {
            cost_id: {
                default: null
            },
            prop: {
                default: 'cost_id'
            },
        },
        watch: {
            cost_id: {
                handler: function () {
                    this.dispatch(this.cost_id);
                }
            },
            immediate: true,
            deep: true,
        },
        mounted() {
            if (this.costs && this.costs.length === 0) this.updateInventory();
        },
        computed: {
            ...mapGetters({
                costs: 'costs/inventory'
            })
        },
        methods: {
            ...mapActions({
                updateInventory: "costs/inventory"
            })
        }
    }
</script>