import { getPaymentTypeSubOnline, getPaymentTypeSubCard } from '@/api/paymentTypeSubs';

export const actions = {
  getPaymentTypeSubOnline({ commit }) {
    return new Promise((resolve, reject) => {
      getPaymentTypeSubOnline().then(res => {
            resolve(res.data.result)
            commit("SET_PAYMENT_TYPE_SUB_ONLINE", res.data.result.data.paymentTypeSubs);
        }).catch(err => {
            reject(err.response.data)
        })
    })
  },

  getPaymentTypeSubCard({ commit }) {
    return new Promise((resolve, reject) => {
      getPaymentTypeSubCard().then(res => {
            resolve(res.data.result)
            commit("SET_PAYMENT_TYPE_SUB_CARD", res.data.result.data.paymentTypeSubs);
        }).catch(err => {
            reject(err.response.data)
        })
    })
  }
}


