import { getClientByCardNumber } from '@/api/clients';

export const actions = {
  getClientByCardNumber({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      getClientByCardNumber(params).then(res => {
            resolve(res.data.result)
            commit("SET_CLIENT", res.data.result.data.client);
        }).catch(err => {
            reject(err.response.data)
        })
    })
  },

  emptyClient({ commit }) {
      return new Promise((resolve, reject) => {
          commit("EMPTY_CLIENT");
          resolve()
      })
  },
}
