import { en_messages } from './messages/en/messages';
import { ru_messages } from './messages/ru/messages';
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = {
  locale: "ru-RU",
  fallbackLocale: "ru-RU",
  messages: {
    "en-EN": en_messages,
    "ru-RU": ru_messages
  }
};

export default i18n;
