import auth from "../../../utils/auth";
import { includes, map, filter } from "lodash";

export function hasToken(state) {
  return !!auth.getToken();
}

export function getPermissions(state) {
  return state.permissions;
}
export function hasRole(state) {
  return slug => includes(map(state.permissions, "slug"), slug);
}

export function user_shops(state){ return state.user_shops }
export function default_shop_id(state){ return state.default_shop_id }
export function user_branches(state){ return state.user_branches }
export function default_branch_id(state){ return state.default_branch_id }
