<template>
  <el-drawer
    title="I am the title"
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    direction="rtl"
    size="50%"    
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="clents-blocks" v-loading="loading">
      <div class="title-modal">
        <p class="flex1">{{ $t("Consultants") }}</p>
        
        <el-button
         type="danger" plain
          style="margin-right: 1vw"
          @click="$emit('drawer:close', false)"
        >
          {{ $t("close") }}
        </el-button>
      </div>
      <el-form ref="form" :model="form">
        <div class="d-flex style-scorch-form new-style-scorch-form">
          <el-form-item class="input-poli">
            <el-input
              :placeholder="$t('To find')"
              suffix-icon="el-icon-search"
              v-model="filter_name"
              @keydown.enter.native="onEnter"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>

      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th style="width: 20%">ID</th>
            <th>{{ $t('name') }}</th>            
          </tr>
        </thead>
        <tbody>
          <tr v-for="consultant in consultants" :key="'consultants' + consultant.id" @click="selectConsultant(consultant)">
            <td>{{ consultant.id }}</td>            
            <td>{{ consultant.name }}</td>            
          </tr>
        </tbody>
      </table>
    </div>
  </el-drawer>
</template>

<script>
import VueTypes from "vue-types";
import { debounce } from "lodash";
import auth from "@/utils/auth";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    open: VueTypes.bool.isRequired
  },
  data() {
    return {
      form: {},
      filter_name: "",
      loading: false,
    };
  },
  watch: {
    filter_name: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.debouncedLoadData();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      consultants: 'consultants/inventory',
    })
  },
  created() {
    this.debouncedLoadData = debounce(this.loadConsultantsOfShop, 1000);
  },
  methods: {
    ...mapActions({
      getConsultantsOfShop: "consultants/getConsultantsOfShop",        
    }),
    afterOpen() {
      if (this.consultants && this.consultants.length == 0) this.loadConsultantsOfShop();
    },
    loadConsultantsOfShop() {
      this.loading = true;
      this.getConsultantsOfShop({
          shop_id: auth.getShop(),
          filter_name: this.filter_name
        })
        .then(res => {
          this.loading = false;
        })
        .catch(err => {
            this.loading = false;
            this.$alert(err);
        });
    },        
    selectConsultant(consultant) {      
      this.$emit("setConsultantToOrder", consultant)
      this.$emit("drawer:close", false);
    },
    handleClose(done) {
      this.filter_name = "";
      done();
      return this.$emit("drawer:close", false);
    },
    onEnter(event) {
      event.preventDefault();
    },
    afterLeave() {
      
    }
  }
};
</script>

<style scoped>
.drawer {
  overflow-y: scroll;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  color: black;
}
.table th {
  border-bottom-width: 0.1rem;
}

.table td,
.table th {
  border-bottom: 0.05rem solid #dadee4;
  padding: 1.2rem 0.4rem;
}
.table.table-hover tbody tr:hover {
  background: #eef0f3;
}
.types-button {
  width: 100%;
  line-height: 2;
  font-size: 23px;
}
</style>
