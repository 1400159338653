import {createCostTransaction, inventory, getCostTransactionHistoryByRetail, getCashoutFromLoyaltyCardHistory} from '@/api/costs';

export const actions = {
    inventory({ commit }) {
        return new Promise((resolve, reject) => {
          inventory().then(res => {
                resolve(res.data.result)
                commit("SET_INVENTORY", res.data.result.data.costs);
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    createCostTransaction({ commit }, data) {
        return new Promise((resolve, reject) => {
            createCostTransaction(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getCostTransactionHistoryByRetail({ commit }, id) {
        return new Promise((resolve, reject) => {
          getCostTransactionHistoryByRetail(id).then(res => {
                resolve(res.data.result)
                commit("SET_HISTORY", res.data.result.data.retailCostTransaction);
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getCashoutFromLoyaltyCardHistory({ commit }, id) {
        return new Promise((resolve, reject) => {
          getCashoutFromLoyaltyCardHistory(id).then(res => {
                resolve(res.data.result)
                commit("SET_CASHOUT_FROM_LOYALTY_CARD_HISTORY", res.data.result.data.cashoutFromLoyaltyCardHistory);
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
}
