export default function() {
  return {
    token: "",
    name: "",
    role: null,
    expires_at: 600000,
    permissions: null,
    last_login: null,
    user_shops: [],
    default_shop_id: null,    
    user_branches: [],
    default_branch_id: null,
  };
}
