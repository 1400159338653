<template>
  <el-drawer
    title="I am the title"
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    direction="rtl"
    size="50%"
    @open="loadData"
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="clents-blocks" v-loading="loading">
      <div class="title-modal">
        <p class="flex1">{{ $t("Return") }}</p>        
        <el-button
          type="danger" plain
          style="margin-right: 1vw"
          @click="$emit('drawer:close', false)"
        >
          {{ $t("close") }}
        </el-button>
      </div>
      <el-form ref="form" :model="filterForm">
        <div class="d-flex style-scorch-form new-style-scorch-form">
          <el-form-item class="input-poli">
            <el-input
              class="search_return_orders"
              suffix-icon="el-icon-search"
              v-model="filterForm.search"
              :placeholder="$t('Product Name, Application number')"
              @keydown.enter.native="onEnter"
            ></el-input>
          </el-form-item>

          <el-button type="text" class="search-btn" @click="refreshData">
            <i class="el-icon-refresh"></i
          ></el-button>
        </div>
      </el-form>

      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th style="width: 60px">ID</th>
            <th>{{ $t('client') }}</th>
            <th>{{ $t('total') }}</th>
            <th>{{ $t('created_at') }}</th>
            <th>{{ $t('Revert & exchange') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in tableData" :key="order.id + '-orders'">
            <td>{{ order.id }}</td>
            <td>{{ order.client ? order.client.name : '' }}</td>
            <td>{{ order.total_price | formatMoney(2) }}</td>
            <td>{{ order.created_at }}</td>
            <td>
              <el-button type="primary" icon="el-icon-refresh-left" circle @click="openReturnFormDrawer(order)"/>
              <el-button v-if="globalCheckHasPermission('exchangeProducts.create')" type="success" @click="openExchangeFormDrawer(order)" round>{{  $t('Exchange') }}</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <return-form
      :open="returnFormDrawer"
      :order="selectedOrder"
      @drawer:close="returnFormDrawer = false"
      />

    <ExchangeForm 
      :open="exchangeFormDrawer"
      :order="selectedOrder"
      @drawer:close="exchangeFormDrawer = false"
    ></ExchangeForm>

  </el-drawer>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";
import auth from '@/utils/auth';
import ReturnForm from "./include/ReturnForm";
import ExchangeForm from "./include/ExchangeForm";

export default {  
  props: ['open'],
  components: {
    ReturnForm,
    ExchangeForm
  },
  data() {
    return {
      filterForm: {
        search: '',
      },
      tableData: [],
      loading: false,
      returnFormDrawer: false,
      selectedOrder: null,
      exchangeFormDrawer: false
    }
  },
  
  created() {
    this.debouncedLoadData = debounce(this.loadData, 1000);
  },

  watch: {
    "filterForm.search": {
      handler(newVal, oldVal) {
        if (newVal) {          
          this.debouncedLoadData();
        }
      }
    },
  },

  methods: {
    ...mapActions({
				getClosedRetailOrders: 'order/getClosedRetailOrders',
    }),

    async loadData() {
      let params = {
        shop_id: auth.getShop(),
        search: this.filterForm.search,
      }

      if (!this.loading) {
        this.loading = true;
        this.getClosedRetailOrders(params)
            .then(res => {
                this.tableData = res.data.retailOrders;
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.$alert(err)
            });
      }
    },

    async refreshData() {
      this.filterForm.search = '';
      let params = {
        shop_id: auth.getShop(),
      }
      if (!this.loading) {
        this.loading = true;
        this.getClosedRetailOrders(params)
            .then(res => {
                this.tableData = res.data.retailOrders;
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.$alert(err)
            });
      }
    },

    afterOpen() {
      
    },

    openReturnFormDrawer(retail_order) {
      this.selectedOrder = retail_order;
      this.returnFormDrawer = true;
    },

    openExchangeFormDrawer(retail_order) {
      this.selectedOrder = retail_order;
      this.exchangeFormDrawer = true;
    },

    onEnter(event) {
      event.preventDefault();
    },

    handleClose(done) {
      done();
      return this.$emit("drawer:close", false);
    },
    
    afterLeave() {
      this.tableData = [];
    }
  },
}
</script>

<style scoped lang="scss">
$primary: #0076aa;
.search-btn {
  background: transparent;
  color: $primary;
  border: none;
  font-size: 25px;
  padding: 12px 20px;
}

.drawer {
  overflow-y: scroll;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  color: black;
}
.table th {
  border-bottom-width: 0.1rem;
}

.table td,
.table th {
  border-bottom: 0.05rem solid #dadee4;
  padding: 0.6rem 0.4rem;
}
.table.table-hover tbody tr:hover {
  background: #eef0f3;
}
</style>
