<template>
  <el-drawer
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    direction="ltr"
    size="67%"
    append-to-body
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="payment">
      <div class="payment-blocks">
        <div class="title-modal">
          <div class="ml-20 flex1">

          </div>
          <el-button
            type="warning"
            class="bg-success"
            style="margin-right: 1vw"
            @click="$emit('drawer:close', false)"
          >
          {{ $t('close') }}
          </el-button>
        </div>
      </div>

      <div class="keyboard">
        <el-row :gutter="20" class="dfJ">
          <el-col :span="6">
            <div class="pras-list">
              <div class="flex1">
                <div class="itme-payments">
                  <span>{{ $t("totalDebt") }}</span
                  ><b
                    >{{ total | formatNumber }}
                    {{ currencySymbol }}</b
                  >
                </div>
                <div class="itme-payments">
                  <span>{{ $t("client") }}</span
                  ><b>{{ client ? client.name : '' }}</b>
                </div>
              </div>
              <div class="paymentType currency-t" @click="currencyModal = true">
                <!-- <div class="itme-paymentType">Dollar <b>$</b></div> -->
                <div class="itme-paymentType" >
                  <b><i class="el-icon-back"></i>{{ currencySymbol }}</b>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="13">
            <div v-for="(type, index) in transaction.payment_types" :key="type.payment_type + '-' + index">        
              <template v-if="(Object.keys(transaction.payment_types).length == 1)">
                <input
                  v-model="type.amount"
                  ref="input" 
                  :id="type.payment_type" 
                  :placeholder="$t(paymentTypeTitles[type.payment_type].name)"
                  class="keyboard-input"
                  autocomplete="off"
                  @input="input"
                  @focus="setFocus(type.payment_type)"
                />
              </template>

              <template v-if="(Object.keys(transaction.payment_types).length == 2)">
                <template v-if="type.payment_type == 'card'">
                  <el-row>
                    <el-col :span="6">
                      <div class="itme-bottom-keyboard-custom">
                        <el-button type="primary" @click="selectCard()">
                          <template v-if="(Object.keys(paymentTypeSubName).length === 0)">
                            {{ $t("Type of card") }}
                          </template>
                          <template v-else-if="(Object.keys(paymentTypeSubName).length > 0) && (paymentTypeSubName.type == 'combination')">
                            {{ paymentTypeSubName.name }}
                          </template>
                          <template v-else>
                            {{ $t("Type of card") }}
                          </template>
                        </el-button>
                      </div>
                    </el-col>
                    <el-col :span="18">
                      <input
                      v-model="type.amount"
                      ref="input" 
                      :id="type.payment_type" 
                      :placeholder="$t(paymentTypeTitles[type.payment_type].name)"
                      class="keyboard-input"
                      autocomplete="off"
                      @input="input"
                      @focus="setFocus(type.payment_type)"
                    />
                    </el-col>
                  </el-row>
                </template>
                <template v-else>
                  <input
                    v-model="type.amount"
                    ref="input" 
                    :id="type.payment_type" 
                    :placeholder="$t(paymentTypeTitles[type.payment_type].name)"
                    class="keyboard-input"
                    autocomplete="off"
                    @input="input"
                    @focus="setFocus(type.payment_type)"
                  />
                </template>
              </template>
            </div>

            <div class="keyboard-nombeer">
              <el-button @click="append(1)">1</el-button>
              <el-button @click="append(2)">2</el-button>
              <el-button @click="append(3)">3</el-button>
              <el-button @click="append(4)">4</el-button>
              <el-button @click="append(5)">5</el-button>
              <el-button @click="append(6)">6</el-button>
              <el-button @click="append(7)">7</el-button>
              <el-button @click="append(8)">8</el-button>
              <el-button @click="append(9)">9</el-button>
              <el-button @click="append('dot')">.</el-button>
              <el-button @click="append(0)">0</el-button>
              <el-button @click="append('splice')"
                ><i class="el-icon-house"></i
              ></el-button>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="dfJ">
          <el-col :span="19">
            <div class="itme-bottom-keyboard 2">
              <el-button
                :class="activeButton === 'cash' ? 'activeButton' : ''"
                @click="changePaymentType('cash')"
                >{{ $t("cash") }}
              </el-button>
              <el-button
                :class="activeButton === 'card' ? 'activeButton' : ''"
                @click="changePaymentType('card')">
                <template v-if="(Object.keys(paymentTypeSubName).length === 0)">
                  {{ $t("card") }}
                </template>
                <template v-else-if="(Object.keys(paymentTypeSubName).length > 0) && (paymentTypeSubName.type == 'card')">
                  {{ paymentTypeSubName.name }}
                </template>
                <template v-else>
                  {{ $t("card") }}
                </template>
              </el-button>
              <el-button
                :class="activeButton === 'combination' ? 'activeButton' : ''"               
                @click="changePaymentType('combination')"
              >
                {{ $t("combination") }}
              </el-button>
              <el-button
                  :class="activeButton === 'online' ? 'activeButton' : ''"
                  @click="changePaymentType('online')"
                  >
                  <template v-if="(Object.keys(paymentTypeSubName).length === 0)">
                    {{ $t("Online") }}
                  </template>
                  <template
                   v-else-if="(Object.keys(paymentTypeSubName).length > 0) && (paymentTypeSubName.type == 'online')">
                    {{ paymentTypeSubName.name }}
                  </template>
                  <template v-else>
                    {{ $t("Online") }}
                  </template>
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="dfJ">
          <el-col :span="19">
            <div class="itme-bottom-keyboard">
              <el-button type="success" @click="submit">{{ $t("pay") }}</el-button>
            </div>
          </el-col>
        </el-row>

        <div class="modal-center currency x-right-top typeCarts-modal">
          <el-dialog
              :visible="paymentTypeSubVisibleOnline"
              width="50%"
              :center="true"
              @close="paymentTypeSubVisibleOnline = false" 
              @closed="afterPaymentTypeSubClosed()" 
              :append-to-body="true"
            >
              <div v-loading="paymentTypeSubLoading">
                  <el-radio-group v-model="paymentTypeSub" class="pay-img">
                    <el-radio-button v-for="(item, index) in paymentTypeSubsOnline" :key="index + '-payment-type-sub'" :label="item.key">
                      <div v-if="(item.key == 'click')" class="img-typeCarts"><img src="./../../../public/img/Click-01.png" alt=""/></div>
                      <div v-else-if="(item.key == 'payme')" class="img-typeCarts"><img src="./../../../public/img/payme-01.png" alt=""/></div>
                      <div v-else-if="(item.key == 'uzum_bank')"  class="img-typeCarts"><img src="./../../../public/img/UZUM_BANK-01.png" alt="" /></div>
                    </el-radio-button>
                  </el-radio-group>
              </div>
            </el-dialog>

            <el-dialog
              :visible="paymentTypeSubVisibleCard"
              width="50%"
              :center="true"
              @close="paymentTypeSubVisibleCard = false" 
              @closed="afterPaymentTypeSubClosed()" 
              :append-to-body="true"
            >
              <div v-loading="paymentTypeSubLoading">
                  <el-radio-group v-model="paymentTypeSub" class="pay-img">
                    <el-radio-button v-for="(item, index) in paymentTypeSubsCard" :key="index + '-payment-type-sub'" :label="item.key">
                      <div v-if="(item.key == 'humo')" class="img-typeCarts"><img style="transform: scale(0.6);" src="./../../../public/img/humo.png" alt=""/></div>
                      <div v-else-if="(item.key == 'uzcard')" class="img-typeCarts"><img style="transform: scale(0.6);" src="./../../../public/img/uzcard.png" alt=""/></div>
                    </el-radio-button>
                  </el-radio-group>
              </div>
            </el-dialog>
        </div>

        <div class="modal-center currency x-right-top">
          <el-dialog
            :visible="currencyModal"
            width="50%"
            :center="true"
            append-to-body
            @close="currencyModal = false"
          >
            <el-radio-group  v-model="currentCurrency"
              value-key="name"
              @change="changeCurrency" class="currency-my-khan">
                <el-radio-button
                  v-for="currency in currencies"
                  :key="currency.id"
                  :label="currency"
                  :value="currency">{{ currency.name }}
                </el-radio-button>
            </el-radio-group>
            <div class="buttonSModal">
              <el-button  type="info"  @click="currencyModal = false"
                >{{ $t("save") }}
              </el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueTypes from "vue-types";
import auth from "@/utils/auth";
import ClientApi from "@/api/Client";
import { filter, flatMap, every, forEach, round } from "lodash";

export default {
  props: {
    open: VueTypes.bool.isRequired,
    client: {
      default: null
    },
    debt: {
      default: 0,
    },
    is_by_default: {
      default: false,
    },
    selected_order_ids: {
        type: Array,
        default: () => []
    }
  },
  data() {
    return {
      transaction: {
        currency_id: 1,
        rate: 1,
        payment_types: [
          {
            payment_type: "cash",
            amount: ""
          }
        ]
      },
      paymentTypeTitles: {
        cash: {
          name: "cash"
        },
        card: {
          name: "card"
        },
        online: {
          name: "online"
        }
      },
      activeButton: "cash",
      focusedInput: null,
      currentCurrency: "",
      currencySymbol: "",
      currencyModal: false,
      paymentTypeSubVisibleOnline: false,
      paymentTypeSubVisibleCard: false,
      paymentTypeSub: null,
      paymentTypeSubLoading: false,
      paymentTypeSubName: {},
    };
  },
  watch: {
    paymentTypeSub: {
      handler(newVal) {
        if (newVal) {
          if (this.transaction.payment_types.length == 1) {
            if (this.transaction.payment_types[0].payment_type == 'online') {
              this.paymentTypeSubName.name = (_.find(this.paymentTypeSubsOnline, { 'key': newVal })).name;
              this.transaction.payment_types[0].payment_type_sub = newVal;
              this.paymentTypeSubName.type = 'online';
              this.paymentTypeSubVisibleOnline = false;
            } else if(this.transaction.payment_types[0].payment_type == 'card') {
              this.paymentTypeSubName.name = (_.find(this.paymentTypeSubsCard, { 'key': newVal })).name;
              this.transaction.payment_types[0].payment_type_sub = newVal;
              this.paymentTypeSubName.type = 'card';
              this.paymentTypeSubVisibleCard = false;
            }
          } else if(this.transaction.payment_types.length == 2) {
            this.paymentTypeSubName.name = (_.find(this.paymentTypeSubsCard, { 'key': newVal })).name;
            this.transaction.payment_types[1].payment_type_sub = newVal;
            this.paymentTypeSubName.type = 'combination';
            this.paymentTypeSubVisibleCard = false;
          }
        }
      }
    },
    total: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.transaction.payment_types[0].amount = this.formatNumber(
            String(newVal)
          );
        }
      }
    },
    currencies: {
      immediate: true,
      handler(newVal) {
        const activeCurrency = filter(newVal, "active")[0];
        if (activeCurrency) {
          this.currentCurrency = activeCurrency.name;
          this.currencySymbol = activeCurrency.symbol;
          this.transaction.rate = activeCurrency.rate;
          this.transaction.currency_id = activeCurrency.id;
        }
      }
    },
    currentCurrency: {
      handler(newVal) {
        if (newVal.rate) {
          this.transaction.rate = newVal.rate;
        }
        if (newVal.id) {
          this.transaction.currency_id = newVal.id;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: "cart/getCurrency",
      paymentTypeSubsOnline: 'paymentTypeSubs/paymentTypeSubsOnline',
      paymentTypeSubsCard: 'paymentTypeSubs/paymentTypeSubsCard'
    }),
    total() {
      const total = (this.currentCurrency.rate > 0) ? this.debt / this.currentCurrency.rate : this.debt;
      return round(total, 2);
    }
  },
  created() {

  },
  methods: {
    ...mapActions({        
      getPaymentTypeSubOnline: "paymentTypeSubs/getPaymentTypeSubOnline",
      getPaymentTypeSubCard: "paymentTypeSubs/getPaymentTypeSubCard",
    }),
    afterOpen() {
      this.activeButton = 'cash';
      this.setFocus("cash");
      this.transaction = {
        currency_id: 1,
        rate: 1,
        payment_types: [
          {
            payment_type: "cash",
            amount: this.total.toString()
          }
        ]
      };
      this.paymentTypeSub = null;
      this.paymentTypeSubName = {};

      const activeCurrency = filter(this.currencies, "active")[0];
      if (activeCurrency) {
        this.currentCurrency = activeCurrency.name;
        this.currencySymbol = activeCurrency.symbol;
        this.transaction.rate = activeCurrency.rate;
        this.transaction.currency_id = activeCurrency.id;
      }
    },
    formatNumber(value) {
      if (typeof(value) == 'number') {
        value = value.toString();
      }

      if (value.indexOf(".") >= 0) {
        let decimal_pos = value.indexOf(".");

        let left_side = value.substring(0, decimal_pos);
        let right_side = value.substring(decimal_pos);

        left_side = left_side
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        right_side = right_side
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        if (right_side.length > 2) {
          if (left_side.charAt(0) === "0") {
            left_side = left_side.slice(1) + right_side.slice(-3, -2);
          } else {
            left_side += right_side.slice(-3, -2);
          }
          left_side.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          right_side = right_side.slice(-2);
        }
        value = left_side + "." + right_side;
      } else {
        value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
      return value;
    },
    input(e) {
      const activeInput = filter(this.transaction.payment_types, [
        "payment_type",
        this.focusedInput
      ])[0];
      activeInput.amount = this.formatNumber(e.target.value);
    },
    setFocus(type) {
      this.focusedInput = type;
    },
    append(number) {
      const activeInput = filter(this.transaction.payment_types, [
        "payment_type",
        this.focusedInput
      ])[0];

      if (
        typeof number === "number" ||
        number === "dot" ||
        number === "splice"
      ) {
        switch (number) {
          case "splice":
            activeInput.amount = activeInput.amount.substring(
              0,
              activeInput.amount.length - 1
            );
            break;
          case "dot":
            if (!activeInput.amount.includes(".")) {
              activeInput.amount += ".";
            }
            break;
          default:
            const value = (activeInput.amount += number);
            activeInput.amount = this.formatNumber(value);
            break;
        }
      }
    },
    changePaymentType(type) {
      switch (type) {
        case "cash":
          this.setPaymentTypeSubNameEmpty();
          this.activeButton = type;
          this.focusedInput = type;

          this.transaction.payment_types = [
            {
              payment_type: type,
              amount: this.formatNumber(String(this.total))
            }
          ];
          break;
        case "card":
          this.activeButton = type;
          this.focusedInput = type;
          this.fetchPaymentTypeSubCard();

          this.transaction.payment_types = [
            {
              payment_type: type,
              amount: this.formatNumber(String(this.total))
            }
          ];
          break;
        case "combination":
          this.setPaymentTypeSubNameEmpty();
          this.activeButton = type;
          this.transaction.payment_types = [
            {
              payment_type: "cash",
              amount: ""
            },
            {
              payment_type: "card",
              amount: ""
            }
          ];
          break;
        case "online":
          this.activeButton = type;
          this.focusedInput = type;
          this.fetchPaymentTypeSubOnline();

          this.transaction.payment_types = [
            {
              payment_type: type,
              amount: this.formatNumber(String(this.total))
            }
          ];
          break;
      }
    },
    submit() {
      const paymentMessages = {
        cash: {
          message: this.$t("surrender")
        },
        card: {
          message: this.$t("paymentMessageCart")
        }
      };
      let message;
      const transactions = this.transaction;
      transactions.payment_types = flatMap(
        transactions.payment_types,
        payment_type => {
          return {
            ...payment_type,
            amount: payment_type.amount !== 0 ? Number(payment_type.amount.toString().replace(/ /gi, "")) : 0
          };
        }
      );

      const hasEmptyAmount = every(transactions.payment_types, "amount");
      if (!hasEmptyAmount) {
        this.$notify({
          type: "error",
          message: "Введите сумму.",
          showClose: true
        });
      } else {
        let totalPaymentSumm = 0;
        forEach(transactions.payment_types, function(item) {
          totalPaymentSumm += item.amount;
        });

        const includeCashType = flatMap(
            transactions.payment_types,
            "payment_type"
          );
        if (includeCashType.length > 1) {
          message = paymentMessages["cash"].message;
        } else {
          if (includeCashType[0] != 'online') {
            message = paymentMessages[includeCashType].message;
          }          
        }

        // sdacha summasi 0 dan kichik bolsa 0 qilib olish
        const deliveryPrice = round((totalPaymentSumm - this.total < 0) ? 0 : totalPaymentSumm - this.total, 2);

        if ((includeCashType.length == 1) && (includeCashType[0] == 'online')) {
          message = this.$t("Do you confirm?");
        } else {
          message = message + "  " + deliveryPrice + "  " + this.currencySymbol;
        }

        this.$confirm("", message, {
          confirmButtonClass: "success",
          confirmButtonText: this.$t("save"),
          cancelButtonText: this.$t("cancel"),
          center: true
        })
          .then(async () => {
            if (
              transactions.payment_types[0].payment_type === "cash" &&
              !transactions.payment_types[1] &&
              transactions.payment_types[0].amount > this.total
            ) {
              transactions.payment_types[0].amount = this.total;
            }

            const loading = this.$loading({
              customClass: "login-loading",
              lock: true,
              text: "Загрузка",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            let is_loading = true;

            let default_branch_id = localStorage.getItem('default_branch_id');
            default_branch_id = default_branch_id === null ? null : parseInt(default_branch_id);

            let data = {
              branch_id: default_branch_id,
              shop_id: auth.getShop(),
              client_id: this.client ? this.client.id : null,
              is_by_default: this.is_by_default,
              selected_order_ids: this.selected_order_ids,
              transactions: transactions,
            }

            await ClientApi.paymentForDebtorOrders(data)
              .then(async response => {
                loading.close();
                is_loading = false;
                this.$emit('drawer:close', false)
              })
              .catch(err => {
                loading.close();
                is_loading = false;
                if (err.response && err.response.data.error.code === 422) {
                  let ms = "";
                  for (const key in err.response.data.validation_errors) {
                    if (
                      err.response.data.validation_errors.hasOwnProperty(key)
                    ) {
                      const element = err.response.data.validation_errors[key];
                      ms += element.join("<br>") + "<br>";
                    }
                  }
                  this.$message({
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    title: this.$t("warning"),
                    message: ms,
                    duration: 15000,
                    showClose: true
                  });
                } else {
                  this.$message({
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    message: err.message,
                    showClose: true
                  });
                }
              });
          })
          .catch(err => {
            // loading.close();
            // is_loading = false;
          });
      }
    },

    selectCard() {
      this.fetchPaymentTypeSubCard(); 
    },

    fetchPaymentTypeSubCard() {
      this.paymentTypeSubVisibleCard = true;
      if (this.paymentTypeSubsCard && this.paymentTypeSubsCard.length === 0) {
        this.paymentTypeSubLoading = true;
        this.getPaymentTypeSubCard().then(res => {
          this.paymentTypeSubLoading = false;
        })
        .catch(err => {
          this.paymentTypeSubLoading = false;
        });
      }
    },

    fetchPaymentTypeSubOnline() {
      this.paymentTypeSubVisibleOnline = true;
      if (this.paymentTypeSubsOnline && this.paymentTypeSubsOnline.length === 0) {
        this.paymentTypeSubLoading = true;
        this.getPaymentTypeSubOnline().then(res => {
          this.paymentTypeSubLoading = false;
        })
        .catch(err => {
          this.paymentTypeSubLoading = false;
        });
      }
    },

    changeCurrency(e) {
      this.currencyModal = false;
      this.transaction.rate = e.rate;
      this.transaction.currency_id = e.id;
      this.currencySymbol = e.symbol;
    },

    setPaymentTypeSubNameEmpty() {
      this.paymentTypeSubName = {};
    },

    afterPaymentTypeSubClosed() {
      this.paymentTypeSub = null;
    },

    handleClose(done) {
      done();
      return this.$emit("drawer:close", false);
    },
    afterLeave() {
      this.paymentTypeSub = null;
      this.paymentTypeSubName = {};
    }
  }
};
</script>

<style scoped>
.keyboard-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 6.5vw;
  font-size: 3vw;
  text-align: right;
  border-radius: 0px;
}

.keyboard-input:hover {
  border-color: #c0c4cc;
}
.payment {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    height: 100%;
    font-weight: 400;
    overflow-x: hidden;
    max-width: 100%;
    transition: background-color 0.3s ease;
    background-color: #0076aa;
    margin: 0px;
    padding: 0px;
    color: #fff
}
.payment-blocks .title-modal {
    max-height: 8vh;
    height: 8vh;
    background-color: #00a1dd;
    padding: 0 0.5vw;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 0.7vw;
    font-weight: 700;
}
.payment-debt-currency-select {
  width: 100%;
}
.payment-debt-currency-select input {
  height: 5vw;
}

.pay-img,
.pay-img .el-radio-group{
  display: flex;
}
.pay-img .el-radio-group .el-radio-button{
  flex: 1;
}
</style>
