export default {
    props: {
        label: {
            default: null
        },
        size: {
            default: 'small',
        },
        plc: {
            default: null,
        }
    },
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        showModal(modal) {
            this.$modal.show(modal);
        },
        dispatch(e) {
            this.$emit('input', e)
            this.selected = e
        }
    },
}
