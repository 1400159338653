<template>
  <el-drawer
    title="I am the title"
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    append-to-body
    direction="rtl"
    size="100%"
    @open="loadData"
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="clents-blocks" v-loading="loading">
      <div class="title-modal">
        <p class="flex1">{{ $t("Return") }}</p>
        <el-button
          v-if="globalCheckHasPermission('retailOrders.revert')"
          type="success"
          style="margin-right: 1vw"
          :loading="waiting"
          @click="submit()"
        >
          {{ $t("save") }}
        </el-button>
        <el-button
          type="danger" plain
          style="margin-right: 1vw"
          @click="$emit('drawer:close', false)"
        >
          {{ $t("close") }}
        </el-button>
      </div>

      <el-main>
        <el-row>
          <el-col>
            <el-button
              type="primary"
              @click="openModalForSelectReason()"
              plain
            >
              {{ $t("reason") }}
            </el-button>
          </el-col>
        </el-row>

        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>{{ $t('name') }}</th>
              <th>{{ $t('Shipped') }}</th>
              <th>{{ $t('Qty of return') }}</th>
              <th style="width: 180px">{{ $t('quantity') }}</th>
              <th>{{ $t('measurement') }}</th>
              <th style="width: 180px">{{ $t('price') }}</th>
              <th>{{ $t('totalPrice') }}</th>
              <th>{{ $t('delete') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.retail_order_item_id + '-retail_order_items'">
              <td>{{ item.product_name }}</td>
              <td>{{ item.shipped_qty | formatNumber }}</td>
              <td>{{ item.return_qty | formatNumber }}</td>
              <td>
                <el-input-number
                  v-model="item.quantity"
                  :min="0"
                  :max="item.shipped_qty"
                ></el-input-number></td>
              <td>{{ item.measurement_name }}</td>
              <td>
                <price-input v-model="item.price" :max="item.max_price.toString()">
                </price-input>
              </td>
              <td>{{ getTotalPriceOfItem(item) | formatMoney(2) }}</td>
              <td>
                <el-button type="danger" icon="el-icon-delete" circle @click="item.quantity = 0"/>
              </td>
            </tr>
          </tbody>
        </table>
      </el-main>
    </div>

    <select-reason
      :open="selectReasonDrawer"
      :old_reason_id="reason_id"
      @setReasonId="setReasonId"
      @drawer:close="selectReasonDrawer = false"
      ref="selectReason"
      />

    <div class="modal-center currency x-right-top">
      <el-dialog
        :visible="paymentForRevertPriceAskModal"
        width="40%"
        append-to-body
        :center="true"
        @close="paymentForRevertPriceAskModal = false"
      >
        <span slot="title">
          <h2 style="color: black">
            {{ $t('Do you want to pay a refundable amount - total_price ?', { 'total_price': not_paid_revert_price, 'currency_symbol': global_currency_symbol }) }}
          </h2>
        </span>
        <div>
          <el-row>
            <el-col :span="11">
              <el-button class="types-button" type="primary" @click="openPaymentForRevertPrice()">
                {{ $t("pay") }}
              </el-button>
            </el-col>
            <el-col :offset="2" :span="11">
              <el-button class="types-button" type="warning" @click="$emit('drawer:close', false)">
                {{ $t("cancel") }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>

    <payment
      :open="paymentForRevertPriceDrawer"
      :order="order"
      :amount="not_paid_revert_price"
      :reverts_ids="reverts_ids"
      @drawer:close="closePaymentForRevertPriceDrawer"
      />

  </el-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PriceInput from "@/components/global/crm-price-input"
import SelectReason from "./include/select-reason";
import payment from "./include/PaymentOfReturn";
import OrderApi from "@/api/Order";

export default {
  props: ['open', 'order'],
  components: { PriceInput, SelectReason, payment },
  data() {
    return {
      items: [],
      loading: false,
      reason_id: null,
      selectReasonDrawer: false,
      waiting: false,
      not_paid_revert_price: 0,
      paymentForRevertPriceAskModal: false,
      paymentForRevertPriceDrawer: false,
      reverts_ids: [],
    }
  },
  methods: {
    ...mapActions({
				getShippedProductsOfRetailOrder: 'order/getShippedProductsOfRetailOrder',
        returnBack: 'order/returnBack',
    }),

    async loadData() {
      this.setDefaultValueData();

      if (!this.loading && this.order) {
        this.loading = true;
        this.getShippedProductsOfRetailOrder(this.order.id)
            .then(res => {
              this.items = JSON.parse(JSON.stringify(res.data.shipped_products));
              this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.$alert(err)
            });
      }
    },
    afterOpen() {

    },
    submit() {
      if (!this.waiting) {
        let filtered_items = _.filter(this.items, function(o) {
          return parseFloat(o.quantity) > 0;
        });

        const form = {
          retail_order_id: this.order ? this.order.id : null,
          reason_id: this.reason_id,
          returning_products: _.map(filtered_items, function(item) {
              return {
                retail_order_item_id: item.retail_order_item_id,
                product_id: item.product_id,
                return_qty: parseFloat(item.quantity),
                price: item.price
              }
          })
        }
        this.waiting = true;
        this.returnBack(form)
            .then(res => {
                this.$alert(res);
                this.waiting = false;
                this.reverts_ids = res.data.reverts_ids;

                this.not_paid_revert_price = res.data.not_paid;
                if (this.not_paid_revert_price > 0) {
                  this.paymentForRevertPriceAskModal = true;
                }
                else {
                  // vozvrat chekini chiqarishni sorash
                  this.$confirm(this.$t("printMessage"), {
                      confirmButtonClass: "success",
                      confirmButtonText: this.$t("yes"),
                      cancelButtonText: this.$t("no"),
                      center: true
                  })
                    .then(async () => {
                        const data = {
                            retail_order_id: this.order ? this.order.id : null,
                            reverts_ids: this.reverts_ids,
                            is_with_transaction: false,
                        };
                        await OrderApi.printReturnReceipt(data)
                          .then(async response => {
                              const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                              WinPrint.document.write(response.data);
                              WinPrint.document.close();
                              WinPrint.focus();
                              setTimeout(() => {
                                WinPrint.print();
                                WinPrint.close();
                              }, 1000);                            
                          })
                          .catch(err => {
                            
                          });
                    })
                    .catch(async err => {
                        
                    });

                  return this.$emit("drawer:close", false);
                }
            })
            .catch(err => {
                this.waiting = false;
                this.$alert(err)
            })
      }
    },
    openPaymentForRevertPrice() {
      this.paymentForRevertPriceAskModal = false;
      this.paymentForRevertPriceDrawer = true;
    },
    closePaymentForRevertPriceDrawer() {
      this.paymentForRevertPriceDrawer = false;
      this.$emit("drawer:close", false);
    },
    getTotalPriceOfItem(item) {
      return _.round(+item.quantity * +item.price, 2);
    },
    openModalForSelectReason() {
      this.selectReasonDrawer = true;
    },
    setReasonId(reason_id) {
      this.reason_id = reason_id;
    },
    handleClose(done) {
      done();
      return this.$emit("drawer:close", false);
    },
    afterLeave() {
      this.setDefaultValueData();
    },
    setDefaultValueData() {
      this.items = [];
      this.reason_id = null;
      this.not_paid_revert_price = 0;
      this.paymentForRevertPriceAskModal = false;
      this.paymentForRevertPriceDrawer = false;
    }
  },
}
</script>

<style scoped lang="scss">
.drawer {
  overflow-y: scroll;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  color: black;
}
.table th {
  border-bottom-width: 0.1rem;
}

.table td,
.table th {
  border-bottom: 0.05rem solid #dadee4;
  padding: 0.6rem 0.4rem;
}
.table.table-hover tbody tr:hover {
  background: #eef0f3;
}
.types-button {
  width: 100%;
  line-height: 2;
  font-size: 23px;
}
</style>

