import i18n from "@/plugins/i18n";

const validate = (rule, price, callback) => {
    if (price <= 0) {
        callback(new Error(i18n.t('The amount must be greater than zero')));
    } else {
        callback();
    }
};

export const rules = {
    datetime: [
        { required: true, message: i18n.t('This field is required'), trigger: 'change' }
    ],
    payment_type_id: [
        { required: true, message: i18n.t('This field is required'), trigger: 'change' }
    ],
    transactionable_id: [
        { required: true, message: i18n.t('This field is required'), trigger: 'change' }
    ],
    amount: [
        { required: true, message: i18n.t('This field is required'), trigger: 'change' },
        { validator: validate, trigger: 'blur' }
    ],
    currency_id: [
        { required: true, message: i18n.t('This field is required'), trigger: 'change' }
    ],
    rate: [
        { required: true,message: i18n.t('This field is required'), trigger: 'change' },
        { validator: validate, trigger: 'change' }
    ]
};
