import request from '@/utils/request'

export function getParentCategories() {
  return request({
      url: '/categories/getParentCategories',
      method: 'get'
  })
}

export function getChildrenCategories(params) {
  return request({
      url: '/categories/getChildrenCategories',
      method: 'get',
      params
  })
}

export function getProductsAndModelOfCategory(params) {
  return request({
      url: '/categories/getProductsAndModelOfCategory',
      method: 'get',
      params
  })
}

