<template>
  <div>
    <div class="haeder-content haederLeft">
      <div class="img-logo"><img src="../../public/img/logo2.png" alt="" /></div>
      <div class="top-h-date ji-s right-border">
        <span>Vaqt</span>
        <b>{{ currentTime  }}</b>
      </div>
      <div class="top-h-date ji-s right-border">
        <span>Kassir</span>
        <b>{{ username }}</b>
      </div>
      <div class="top-h-date ji-s right-border">
        <span>Ish Vaqt</span>
        {{workingHours}} {{ $t('hour') }} 
        {{workingMinutes}} {{ $t('minute') }}
      </div>
      <div class="top-h-date ji-s right-border">
        <template v-if="global_variable_is_work_with_branch">
            <el-button @click="$emit('c-openDialogToSelectDefaultBranch')" :type="(!checkHasDefaultBranch) ? 'danger' : '' ">{{ getDefaultBranchName }}</el-button>
        </template>
        <template v-else>
            <el-button @click="$emit('c-openDialogToSelectDefaultBranch')" :type="(!checkHasDefaultShop) ? 'danger' : '' ">{{ getDefaultShopName }}</el-button>
        </template>
      </div>
      <div class="flex-1"></div>
      <div class="icon-sorche d-flex">
        <div class="searchProductByCode-s">
            <el-tooltip :content="getSearchTooltipContent" placement="top">
            <el-switch
              v-model="value"
              active-color="#13ce66"
              active-value="name"
              inactive-value="code"
              class="switch"
            >
            </el-switch>
          </el-tooltip>
          <el-input
            :placeholder="value === 'code' ? $t('code') : $t('name')"
            v-model="searchProductByCode"
            @keydown.enter.native="onEnter"
          >
          </el-input>
        </div>
        <div class="top-right-icon">
          <div class="theme-switch-wrapper ji-s right-border">
              <label class="theme-switch" for="checkbox">
                  <input type="checkbox" id="checkbox" />
                  <i class="el-icon-moon icon-font-size"></i>
              </label>
          </div>
          <div class="theme-switch-wrapper ji-s right-border">
              <el-button
              type="text"
              icon="el-icon-edit  icon-font-size"
              class="logout-btn ji-s"
              @click="setCommentForOrder()"
            >
            </el-button>
          </div>
          <el-button
            type="text"
            icon="el-icon-switch-button  icon-font-size"
            class="logout-btn ji-s"
            @click="logOut"
          >
          </el-button>
        </div>

      </div>

      <comment-for-order-modal
        :open="commentForOrderModal.open"
        :comment="commentForOrderModal.comment"
        @modal:close="commentForOrderModal.open = $event"
        @change:commentForOrder="saveCommentForOrder"
      />
    </div>
  </div>
</template>

<script>
import auth from "../api/auth/auth";
import authUtil from "../utils/auth";
import ProductsService from "../api/product";
import { debounce } from "lodash";
import OrderItem from "../models/OrderItem";
import CommentForOrderModal from "./CommentForOrderModal";
import Order from "../models/Order";
import { mapGetters } from "vuex";

export default {
  name: "Header",
  components: {
    CommentForOrderModal,
  },

  data() {
    return {
      centerDialogVisible: false,
      searchProductByCode: "",
      value: "",
      username: null,
      workingHours: null,
      workingMinutes: null,
      currentTime: '',
      commentForOrderModal: {
        comment: "",
        open: false,
      },
      form_default_branch_id: null,
    };
  },
  computed: {
      ...mapGetters({
          user_shops: "auth/user_shops",
          default_shop_id: "auth/default_shop_id",
          user_branches: "auth/user_branches",
          default_branch_id: "auth/default_branch_id",
          getPermissions: "auth/getPermissions",
      }),
      getDefaultShopName: function() {
          let default_shop = _.find(this.user_shops, ['id', this.default_shop_id]);

          if (default_shop) {
              return default_shop.name;
          }
          else {
              return this.$t('No shop selected');
          }
      },
      checkHasDefaultShop: function() {
          let default_shop = _.find(this.user_shops, ['id', this.default_shop_id]);

          if (default_shop) {
              return true;
          }
          else {
              return false;
          }
      },
      getDefaultBranchName: function() {
          let default_branch = _.find(this.user_branches, ['id', this.default_branch_id]);

          if (default_branch) {
              return default_branch.name;
          }
          else {
              return this.$t('No branch selected');
          }
      },
      checkHasDefaultBranch: function() {
          let default_branch = _.find(this.user_branches, ['id', this.default_branch_id]);

          if (default_branch) {
              return true;
          }
          else {
              return false;
          }
      },
      getSearchTooltipContent: function() {
          if (this.value == 'code') {
            return this.$t('Search by code');
          }
          else {
            return this.$t('Search by name');
          }
      }
  },

  watch: {
    searchProductByCode: {
      handler() {
        this.debouncedLoadModel();
      }
    }
  },

  created() {
    // Home page dan form_default_branch_id ga value berilgan
    this.debouncedLoadModel = debounce(this.loadModel, 2000);
    this.username = authUtil.getName();
    this.setWorkingHours();
    this.updateTime();
    setInterval(() => {
      this.setWorkingHours();
      this.updateTime();
    }, 60000); // Update every minute
  },

  methods: {
    setWorkingHours() {
      const now = new Date(); 
      const lastLogin  = new Date(authUtil.getLastLogin());
      const diffInMilliseconds = Math.abs(now.getTime() - lastLogin.getTime());

      const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
      const diffInMinutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

      this.workingHours = diffInHours;
      this.workingMinutes = diffInMinutes
    },
    updateTime() {
      const date = new Date();
      const hours = this.formatDigits(date.getHours());
      const minutes = this.formatDigits(date.getMinutes());
      this.currentTime = `${hours}:${minutes}`;
    },

    formatDigits(value) {
      return value.toString().padStart(2, '0');
    },

    setCommentForOrder() {
      this.commentForOrderModal.open = true;
      this.commentForOrderModal.comment = this.order ? this.order.comment : '';
    },

    saveCommentForOrder(e) {
      this.commentForOrderModal.open = e.open;
      this.$store.dispatch("order/setComment", e.comment);
    },
    
    async loadModel() {
      if (this.searchProductByCode && this.value === "code") {
        await ProductsService.getProductByCode({
          shop_id: authUtil.getShop(),
          barcode: this.searchProductByCode,
        })
          .then(async res => {
            const order = await this.$store.getters["order/getOrder"];
            if (!order.retail_order_id) {
              await this.$store.dispatch("order/create", Order.newOrder());
            }

            let product = res.data.result.data.product;
            const item = OrderItem.reverseProduct(product, 1, product.price, product.barcode);
            this.searchProductByCode = "";
            return await this.$store.dispatch("cart/add", item);
          })
          .catch(err => {
            this.searchProductByCode = "";
            if (err.response.status === 404) {
              this.searchProductByCode = "";
              return this.$notify({
                title: 'Ошибка ',
                type: "error",
                message: err.response.data.error.message
              });
            } else if(err.response.status === 423) {
              return this.$notify({
                title: 'Предупреждение ',
                type: "warning",
                message: err.response.data.error.message
              });
            }
          });
      }
      else if (this.searchProductByCode && this.value === "name") {
        this.$emit("emit:searchByName", this.searchProductByCode);
        this.searchProductByCode = "";
      }
    },
    onEnter(event) {
      event.preventDefault();
      this.loadModel();
    },
    async logOut() {
      this.$confirm(this.$t("logOutMessage"), {
        confirmButtonClass: "danger",
        confirmButtonText: this.$t("exit"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
        center: true
      })
        .then(async () => {
          const response = await auth.logout();
          this.$notify({
            type: "success",
            message: response.data.result.data.message,
            duration: 1000,
            onClose: () => {
              authUtil.clearStorge();
              this.$store.dispatch("cart/clear");
              this.$store.dispatch("order/clear");
              authUtil.removeClientData();
              this.client = "";
              this.$router.push({ name: "login" });
            }
          });
        })
        .catch(err => {
          this.$message({
            type: "info",
            showClose: true,
            message: this.$t("canceled")
          });
        });
    }
  },
  mounted() {
      const toggleSwitch = document.querySelector(
          '.theme-switch input[type="checkbox"]'
      );
      const currentTheme = localStorage.getItem("theme");

      if (currentTheme) {
          document.documentElement.setAttribute("data-theme", currentTheme);

          if (currentTheme === "dark") {
              toggleSwitch.checked = true;
          }
      }

      function switchTheme(e) {
          if (e.target.checked) {
              document.documentElement.setAttribute("data-theme", "dark");
              localStorage.setItem("theme", "dark");
          } else {
              document.documentElement.setAttribute("data-theme", "light");
              localStorage.setItem("theme", "light");
          }
      }

      toggleSwitch.addEventListener("change", switchTheme, false);
    },
};
</script>

<style scoped lang="scss">
.logout-btn {
  &:active,
  &:hover {
    color: white;
  }
  background: transparent;
  color: white;
  border: none;
  font-size: 25px;
  padding: 12px 20px;
}
</style>
