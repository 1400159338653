import request from '@/utils/request'

export function inventory() {
  return request({
      url: '/costs/inventory',
      method: 'get'
  })
}

export function createCostTransaction(data) {
  return request({
      url: '/costs/createCostTransaction',
      method: 'post',
      data
  })
}

export function getCostTransactionHistoryByRetail(id) {
  return request({
      url: `/costs/getCostTransactionHistoryByRetail/${id}`,
      method: 'get'
  })
}

export function getCashoutFromLoyaltyCardHistory(id) {
  return request({
      url: `/costs/getCashoutFromLoyaltyCardHistory/${id}`,
      method: 'get'
  })
}

