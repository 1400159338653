<template>
    <el-form-item :label="label || $t('currency')" :prop="prop">
        <el-select style="width: 100%;" @change="cChange()" :value="selected" @input="dispatch" :placeholder="plc || $t('currency')" filterable clearable :size="size">
            <el-option v-for="(currency, index) in currencies" :key="'currencies-' + index" :label="currency.name"
                :value="currency.id"></el-option>
        </el-select>
    </el-form-item>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mix from '@/utils/mixins/fields/form-item';

export default {
    name: 'currencies',
    mixins: [mix],
    props: {
        currency_id: {
            default: null
        },
        prop: {
            default: 'currency_id'
        },
    },
    watch: {
        currency_id: {
            handler: function () {
                this.dispatch(this.currency_id);
            }
        },
        immediate: true,
        deep: true,
    },
    mounted() {
        if (this.currencies && this.currencies.length === 0) this.updateInventory()
    },
    computed: {
        ...mapGetters({
            currencies: 'currencies/inventory'
        })
    },
    methods: {
        ...mapActions({
            updateInventory: 'currencies/inventory'
        }),
        cChange(){
            this.$emit('c-change');
        }
    },
}
</script>