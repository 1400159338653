<template>
  <div class="modal-center x-right-top">
    <el-dialog
      :visible="open"
      width="40%"
      :center="true"
      :before-close="handleClose"
      :title="$t('Withdrawal from the balance')"
      @opened="afterOpen"
      @closed="afterLeave"
    >
      <el-row>
        <el-col>
          <price-input v-model="fromBalance" :max="String(maxWithdraw)"></price-input>
        </el-col>        
      </el-row>
      
      <div class="d-flex buttonSModal">
        <el-button @click="getFromBalance()" type="success">{{ $t('Apply') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Total from "../../utils/Total";
import VueTypes from "vue-types";
import PriceInput from "@/components/global/crm-price-input"
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    open: VueTypes.bool.isRequired,
    currency: {
      required: true
    },
    amountFromBalance: {
      required: true
    }
  },
  components: {
    PriceInput
  },
  data() {
    return {
      fromBalance: 0
    };
  },
  computed: {
    ...mapGetters({
      client: "order/getClient",
      products: "cart/getProducts",
    }),

    productsTotalPrice() {
      const total = Total.totalCost(this.products, this.currency.rate);
      return total.totalCostPrice;
    },

    maxWithdraw() {
      return (this.client && (this.client.card_balance > 0)) ? ((this.client.card_balance < this.productsTotalPrice) ? this.client.card_balance : this.productsTotalPrice) : 0
    }
  },
  methods: {
    ...mapActions({
    }),

    afterOpen() {
      this.fromBalance = this.amountFromBalance;
    },

    getFromBalance() {
      this.$emit("setFromBalance", this.fromBalance);
      this.$emit("modal:close", false);
    },

    handleClose(done) {
      done();
      this.$emit("modal:close", false);
    },

    afterLeave() {
      this.clearData();
    },

    clearData() {
      this.fromBalance = 0;
    }
  }
};
</script>
