import { getDataOfModel } from '@/api/productModels';

export const actions = {
    getDataOfModel({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        getDataOfModel(params).then(res => {
              resolve(res.data.result)
          }).catch(err => {
              reject(err.response.data)
          })
      })
    },
}
