export const en_messages = {
  save: "Save",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  totalPrice: "Total price",
  discount: "Discount",
  elements: "Elements",
  client: "Client",
  user: "User",
  cashier: "Cashier",
  seller: "Seller",
  totalDiscont: "Total discount",
  print: "Print",
  clear: "Clear",
  pay: "Pay",
  postponement: "Postponement",
  name: "Name",
  namePerson: "Name",
  price: "Price",
  qty: "qty",
  total: "Total",
  basket: "Basket",
  application: "Application",
  code: "Code",
  home: "Home",
  payment: "Payment",
  cash: "Cash",
  card: "Card",
  combination: "Combination",
  currency: "Currency",
  changeClient: "Select client",
  deleteProduct: "Want to delete a product?",
  delete: "Delete",
  savePostPonement: "Save postponement",
  warning: "Warning",
  printMessage: "Want to withdraw on check?",
  surrender: "Surrender",
  noProducts: "No Products",
  add: "Add",
  close: "Close",
  addClient: "Add new client",
  number: "Phone",
  birthday: "Date of Birth",
  phone: "Phone number",
  note: "Note",
  all: "All",
  logOutMessage: "Are you sure you want to log out?",
  exit: "Exit",
  warehouse: "Warehouse",
  remainder: "Remainder",
  clearCart: "Want to empty your trash?",
  paymentMessageCart: "Did you withdraw money from the card? Delivery:",
  enterPassword: "Enter Password",
  ok: "Ok",
  totalDiscount: "Total Discount",
  canceled: "Canceled"
}
