import request from '@/utils/request'

export function getPaymentTypeSubOnline() {
  return request({
      url: '/paymentTypeSubs/getPaymentTypeSubOnline',
      method: 'get'
  })
}

export function getPaymentTypeSubCard() {
  return request({
      url: '/paymentTypeSubs/getPaymentTypeSubCard',
      method: 'get'
  })
}
