<template>
  <el-drawer
    title="I am the title"
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    direction="rtl"
    size="60%"
    @open="loadModel"
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="clents-blocks">
      <div class="title-modal">
        <p class="flex1">{{ $t("client") }}</p>
        <el-button
          v-can="create"
          style="margin-right: 1vw"
          @click="openCreateClientModal()"
        >
          {{ $t("add") }}
        </el-button>
        <el-button
         type="danger" plain
          style="margin-right: 1vw"
          @click="$emit('drawer:close', false)"
        >
          {{ $t("close") }}
        </el-button>
      </div>
      <el-form ref="form" :model="form">
        <div class="d-flex style-scorch-form new-style-scorch-form">
          <el-form-item class="input-poli">
            <el-input
              ref="searchInput"
              placeholder="Поиск клиентов"
              suffix-icon="el-icon-search"
              v-model="search"
              @keydown.enter.native="onEnter"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>

      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th style="width: 60px">ID</th>
            <th>{{ $t('number') }}</th>
            <th>{{ $t('client') }}</th>
            <th>{{ $t('balance') }}</th>
            <th>{{ $t('Card number') }}</th>
            <th>{{ $t('Card balance') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in tableData" :key="client.id">
            <td @click="returnValue(client)">{{ client.id }}</td>
            <td @click="returnValue(client)">{{ client.phone }}</td>
            <td @click="returnValue(client)">{{ client.name }}</td>
            <td @click="returnValue(client)">{{ client.balance | formatMoney(2) }}</td>
            <td @click="returnValue(client)">{{ client.card_number }}</td>
            <td @click="returnValue(client)">{{ client.card_balance | formatMoney(2) }}</td>
            <td>
              <el-button type="success" icon="el-icon-shopping-cart-2" circle @click="paymentForDebt(client)"/>
            </td>
          </tr>
        </tbody>
      </table>
      
      <el-drawer
        :visible="clientModal"
        :with-header="false"
        direction="ttb"
        :append-to-body="true"
        :before-close="closeDrawerIn"
        size="50%"
      >
        <div class="title-modal">
          <div class="ml-20 flex1">{{ $t("addClient") }}</div>
          <el-button
            style="margin-right: 1vw"
            type="success"
            @click="addNewClient"
          >
            {{ $t("add") }}
          </el-button>
          <el-button  type="danger" plain  style="margin-right: 1vw" @click="clientModal = false" >
            {{ $t("close") }}
          </el-button>
        </div>
        <el-form
          ref="form"
          :model="form"
          class="add-clent-form my-form-khan"
          @keydown.enter.native="onEnter"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item class="add-clent">
                <el-input
                  v-model="form.name"
                  :placeholder="$t('namePerson')"
                ></el-input>
              </el-form-item>
              <el-form-item class="add-clent">
                <el-date-picker
                  v-model="form.date_of_birth"
                  type="date"
                  value-format="yyyy-MM-dd"
                  :placeholder="$t('birthday')"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item class="add-clent">
                <el-input
                  v-model="form.phone"
                  :placeholder="$t('phone')"
                ></el-input>
              </el-form-item>
              
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item class="add-clent">
                    <el-select v-model="form.source_id" :disabled="form.is_create_new_source" :placeholder="$t('Source')">
                      <el-option
                        v-for="item in sources"
                        :key="'sources-' + item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item class="add-clent">
                    <el-switch
                      v-model="form.is_create_new_source"
                      :active-text="$t('Create a source and attach')">
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item class="add-clent">
                    <el-input
                      v-model="form.new_source_name"
                      :disabled="!form.is_create_new_source"
                      :placeholder="$t('New source')"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </el-drawer>
    </div>

    <div class="modal-center currency x-right-top">
      <el-dialog        
        :visible="paymentForDebtTypeModal"
        width="50%"
        append-to-body
        :center="true"
        @close="paymentForDebtTypeModal = false"
      >
        <span slot="title">
          <h2 style="color: black">
            {{ $t('What type of payment do you want?') }}            
          </h2>
        </span>
        <div>
          <el-row>            
            <el-col :span="11">
              <el-button class="types-button" type="success" @click="paymentForDebtByDefault">
                {{ $t("Default") }}
              </el-button>
            </el-col>
            <el-col :offset="2" :span="11">
              <el-button class="types-button" type="primary" @click="paymentForDebtByDetail">
                {{ $t("According to details") }}
              </el-button>
            </el-col>
          </el-row>          
        </div>
      </el-dialog>
    </div>

    <!-- balansi minusda bolsa qarzi bor dgani -->
    <payment
      :open="paymentDrawerByDefault"
      :client="selectedClient"
      :debt="(selectedClient && selectedClient.balance < 0) ? (-1 * selectedClient.balance) : 0"
      :is_by_default="true"
      @drawer:close="closePaymentDrawer"      
      />

    <debtor-order
      :open="debtorOrderDrawer"
      :client="selectedClient"      
      @drawer:close="closeDebtorOrderDrawer"      
      />
  </el-drawer>
</template>

<script>
import VueTypes from "vue-types";
import Client from "../api/Client";
import payment from "./includeForClientDrawer/payment";
import debtorOrder from "./includeForClientDrawer/debtor-order";
import auth from "../utils/auth";
import { debounce, includes, map } from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ClientsDrawer",
  props: {
    open: VueTypes.bool.isRequired
  },
  components: {
    payment,
    debtorOrder
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        date_of_birth: null,
        source_id: null,
        is_create_new_source: false,
        new_source_name: '',
      },
      create: false,
      search: "",
      clientModal: false,
      tableData: [],
      paymentForDebtTypeModal: false,
      selectedClient: null,
      paymentDrawerByDefault: false,
      debtorOrderDrawer: false,
    };
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        this.debouncedLoadModel();
      }
    },
    permissions: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.create = includes(
            map(this.permissions, "slug"),
            "clients.create"
          );
        }
      }
    }
  },
  
  computed: {
    ...mapGetters({
      hasPermission: "auth/hasRole",
      permissions: "auth/getPermissions",
      sources: "sources/inventory",
    })
  },

  created() {
    this.debouncedLoadModel = debounce(this.loadModel, 1000);
  },

  methods: {
    ...mapActions({
        loadSourceInventory: 'sources/inventory',
    }),
    afterOpen() {
      this.selectedClient = null;
      this.$refs.searchInput.focus();
    },

    async loadModel() {
      const { data: response } = (
        await Client.getClient({ search: this.search })
      ).data.result;

      this.tableData = response;
      if (response && (response.length == 1) && (response[0].card_number == this.search)) {
        this.$emit("drawer:close", false);
        this.$emit("change:client", response[0]);
      }
    },   

    returnValue(e) {
      this.$emit("drawer:close", false);
      return this.$emit("change:client", e);
    },

    openCreateClientModal() {
      this.form = {
        name: "",
        phone: "",
        date_of_birth: null,
        source_id: null,
        is_create_new_source: false,
        new_source_name: '',
      };
      this.clientModal = true;
      if (this.sources && !this.sources.length) this.loadSourceInventory();
    },
    closeDrawerIn(done) {
      done();
      this.clientModal = false;
      this.form = {};
    },

    handleClose(done) {
      this.search = "";
      done();
      return this.$emit("drawer:close", false);
    },
    
    onEnter(event) {
      event.preventDefault();
    },

    async addNewClient() {
      if (this.form.name) {
        const loading = this.$loading({
          customClass: "login-loading",
          lock: true,
          text: "Загрузка",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        let is_loading = true;

        let default_branch_id = localStorage.getItem('default_branch_id');
        default_branch_id = default_branch_id === null ? null : parseInt(default_branch_id);

        const data = {
          ...this.form,
          branch_id: default_branch_id,
          shop_id: auth.getShop()
        };
        await Client.createClient(data)
          .then(response => {
            if (response.status === 200) {
              const { result } = response.data;
              if (result.success) {
                this.$notify({
                  type: "success",
                  message: result.message
                });
                this.tableData.push(result.data.client);
                loading.close();
                (this.form = {
                  name: "",
                  phone: "",
                  date_of_birth: null
                }),
                  (is_loading = false);

                // Yengi source qoshib qilsa, source ning inventory sini yengilash
                if (this.form.is_create_new_source) {
                  this.loadSourceInventory();
                }
                this.clientModal = false;
                this.returnValue(result.data.client);
                return;
              }
            }
          })
          .catch(err => {
            loading.close();
            is_loading = false;
            if (err.response && err.response.status === 422) {
              let ms = "";
              for (const key in err.response.data.validation_errors) {
                if (err.response.data.validation_errors.hasOwnProperty(key)) {
                  const element = err.response.data.validation_errors[key];
                  ms += element.join("<br>") + "<br>";
                }
              }
              this.$message({
                dangerouslyUseHTMLString: true,
                type: "warning",
                title: this.$t("warning"),
                message: ms,
                duration: 15000,
                showClose: true
              });
            } else {
              this.$message({
                dangerouslyUseHTMLString: true,
                type: "warning",
                title: this.$t("warning"),
                message: err.message,
                showClose: true
              });
            }
          });
      } else {
        this.clientModal = false;
      }
    },
    paymentForDebt(item) {
      this.selectedClient = item;
      this.paymentForDebtTypeModal = true;
    },
    paymentForDebtByDefault() {
      this.paymentForDebtTypeModal = false;
      this.paymentDrawerByDefault = true;
    },
    closePaymentDrawer() {
      this.paymentDrawerByDefault = false;
      this.loadModel();
    },
    paymentForDebtByDetail() {
      this.paymentForDebtTypeModal = false;
      this.debtorOrderDrawer = true;
    },
    closeDebtorOrderDrawer() {
      this.debtorOrderDrawer = false;
      this.loadModel();
    },
    afterLeave() {
      this.search = '';
    }
  }
};
</script>

<style scoped>
.drawer {
  overflow-y: scroll;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  color: black;
}
.table th {
  border-bottom-width: 0.1rem;
}

.table td,
.table th {
  border-bottom: 0.05rem solid #dadee4;
  padding: 0.6rem 0.4rem;
}
.table.table-hover tbody tr:hover {
  background: #eef0f3;
}
.types-button {
  width: 100%;
  line-height: 2;
  font-size: 23px;
}
</style>
