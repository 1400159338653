<template>
  <div>
    <div style="text-align: center; margin-bottom: 20px;">
      {{ form.exchangeProduct ? form.exchangeProduct.name : '' }}
    </div>
    <el-form ref="form" :model="form" label-width="150px">
        <el-form-item :label="$t('Product of exchange')">
          <el-row :gutter="10">
            <el-col :span="12">
              <productSelect style="width: 100%" size="large" v-model="form.exchange_product_id" :product_id="form.exchange_product_id">
              </productSelect>
            </el-col>
            <el-col :span="10">
              <el-input :placeholder="$t('Barcode')" v-model="form.barcode" clearable>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item :label="$t('Quantity of exchange')">
          <el-input-number v-model="form.exchange_quantity" @change="totalExchangeQuantityChanged()" :min="0" :max="maxExchangeQty"></el-input-number>
        </el-form-item>

        <el-form-item :label="$t('price')">
          <price-input v-model="form.price"></price-input>
        </el-form-item>

        <el-form-item :label="$t('discount')">
          <el-row>
            <el-col :span="6">
              <el-radio-group v-model="form.discount_type" value-key="name">
                <el-radio-button label="sum">{{ $t('total') }}</el-radio-button>
                <el-radio-button label="percent">%</el-radio-button>
              </el-radio-group>
            </el-col>
            <el-col :span="18">
              <price-input v-if="form.discount_type == 'sum'" v-model="form.discount_amount" :max="String(form.price)" @change="changeDiscountAmount"></price-input>
              <el-input-number v-else-if="form.discount_type == 'percent'" v-model="form.discount" :min="0" :max="100" @change="changeDiscount"></el-input-number>
            </el-col>
          </el-row>
        </el-form-item>

        <el-table
          v-loading="is_loading"
          :data="form.warehouses"
          style="width: 100%">
            <el-table-column :label="$t('warehouse')">
              <template slot-scope="item">
                {{ item.row.warehouse_name }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('remainder')">
              <template slot-scope="item">
                {{ item.row.total_remainder }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('Exchange quantity')">
              <template slot-scope="item">
                <el-input-number
                  v-model="item.row.exchange_quantity"
                  @change="warehouseExchangeQuantityChanged()"
                  :min="0"
                  :max="item.row.total_remainder">
                </el-input-number>
              </template>
            </el-table-column>
        </el-table>
    </el-form>

    <span slot="footer" class="d-flex buttonSModal">
      <el-button @click="cancel()" type="warning">Отмена</el-button>
      <el-button @click="submit()" type="primary">Сохранить</el-button>
    </span>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import PriceInput from "@/components/global/crm-price-input"
  import auth from "@/utils/auth";
  import productSelect from "@/includes/fields/inventory/crm-product-select";

  export default {
    props: ['returnItem'],
    components: {
      PriceInput,
      productSelect
    },

    data() {
      return {
        is_loading: false,
        form: {
            exchange_product_id: null,
            exchange_quantity: 0,
            maxExchangeQty: 0,
            barcode: null,
            price: 0,
            discount_type: 'percent',
            discount_amount: 0,
            discount: 0,
            warehouses: [],
            exchangeProduct: null
        },
        oldExchangeProduct: null
      }
    },

    computed: {
      ...mapGetters({
        products: "products/inventory",
        productsRemainder: 'warehouses/productsRemainder'
      }),

      maxExchangeQty() {
        let maxExchangeQty = (this.form.warehouses ? _.sumBy(this.form.warehouses, function(o) { return parseFloat(o.total_remainder); }) : 0);
        this.form.maxExchangeQty = maxExchangeQty;
        return maxExchangeQty;
      }
    },

    watch: {
      'form.exchange_product_id': {
        handler(newVal, oldVal) {
          if (this.form.exchange_product_id != null) {
            this.form.barcode = null;
            this.getWarehouses();
          }
        },
        deep: true
      },
      'form.barcode': {
        handler(newVal, oldVal) {
          if (this.form.barcode != null) {
            this.form.exchange_product_id = null;
            this.debouncedGetWarehouse();
          }
        },
        deep: true
      }
    },

    methods: {
      ...mapActions({
          getProductRemainder: 'warehouses/getProductRemainder'
      }),

      afterOpen() {
        if (this.returnItem.exchangeProductInfo) {
          this.oldExchangeProduct = JSON.parse(JSON.stringify(this.returnItem.exchangeProductInfo));
          this.form =  JSON.parse(JSON.stringify(this.returnItem.exchangeProductInfo));
        }
      
        this.form.retail_order_item_id = this.returnItem.retail_order_item_id;
      },

      cancel() {
        this.clearData();
        this.form.retail_order_item_id = this.returnItem.retail_order_item_id;
        this.$emit('setExchangeProductInfo', this.form);
      },

      submit() {
        this.$emit('setExchangeProductInfo', this.form);
      },

      getWarehouses() {
        this.emptyFieldsBeforeFetch();
        if (this.form.exchange_product_id || this.form.barcode) {
          this.getWarehousesProduct();
        }
      },

      debouncedGetWarehouse: _.debounce(function () {
            this.getWarehouses();
        }, 800),

      emptyFieldsBeforeFetch() {
        this.form.warehouses = [];
        this.form.exchangeProduct = null;
        this.form.price = 0;
        this.form.exchange_quantity = 0;
        this.form.discount_amount = 0;
        this.form.discount = 0;
        this.form.discount_type = 'percent';
      },

      getWarehousesProduct() {
        this.is_loading = true;
          this.getProductRemainder({
              product_id: this.form.exchange_product_id,
              barcode: this.form.barcode,
              shop_id: auth.getShop()
            })
            .then(res => {
              this.form.warehouses = JSON.parse(JSON.stringify(this.productsRemainder.warehouses));
              this.setProduct();
              this.is_loading = false;
            })
            .catch(err => {
              this.is_loading = false;
            });
      },

      setProduct() {
          this.form.exchangeProduct = this.productsRemainder.product;
          this.form.price = this.productsRemainder.product ? this.productsRemainder.product.selling_price : 0;
          this.setOldProductInfoIfExist();
      },

      setOldProductInfoIfExist() {
        if (this.oldExchangeProduct && this.form.exchangeProduct && (this.form.exchangeProduct.id == this.oldExchangeProduct.exchangeProduct.id)) {
            this.form.price = this.oldExchangeProduct.price;
            this.form.exchange_quantity = this.oldExchangeProduct.exchange_quantity;
            this.form.discount_amount = this.oldExchangeProduct.discount_amount;
            this.form.discount = this.oldExchangeProduct.discount;
            this.form.discount_type = this.oldExchangeProduct.discount_type;
            this.totalExchangeQuantityChanged();
            this.oldExchangeProduct = null;
          }
      },

      warehouseExchangeQuantityChanged() {
        this.form.exchange_quantity = _.sumBy(this.form.warehouses, function(o) { return parseFloat(o.exchange_quantity); })
      },

      totalExchangeQuantityChanged() {
        var qty = this.form.exchange_quantity;

        _.forEach(this.form.warehouses, function(value) {
          if (parseFloat(value.total_remainder) >= qty) {
            value.exchange_quantity = qty;
            qty = 0;
          } else {
            value.exchange_quantity = parseFloat(value.total_remainder);
            qty -= parseFloat(value.total_remainder);
          }
        });
      },

      afterLeave() {
        this.clearData();
      },

      changeDiscountAmount(val) {
        if (this.form.price > 0) {
          this.form.discount = _.round((val * (100 / this.form.price)), 2);
        }
        else {
          this.form.discount = 0;
        }
      },

      changeDiscount(val) {
        this.form.discount_amount = _.round((val * (this.form.price / 100)), 2);
      },

      clearData() {
        this.oldExchangeProduct = null;
        this.form = {
            exchange_product_id: null,
            exchange_quantity: 0,
            maxExchangeQty: 0,
            barcode: null,
            price: 0,
            discount_type: 'percent',
            discount_amount: 0,
            discount: 0,
            warehouses: [],
            exchangeProduct: null
        };
      },
    },
  }
</script>
