var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input__box"},[_c('AutoNumericVue',{attrs:{"options":{
            digitGroupSeparator: ' ',
            allowDecimalPadding: false,
            decimalCharacter: ',',
            decimalCharacterAlternative: '.',
            roundingMethod: 'U',
            minimumValue: '0',
            caretPositionOnFocus: 'decimalLeft',
            selectOnFocus: false,
            decimalPlaces: 9,
            decimalPlacesShownOnFocus: 9,
            maximumValue: this.max,
        }},on:{"blur":_vm.onBlur},nativeOn:{"focus":function($event){return _vm.onFocus($event)}},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }