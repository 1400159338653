<template>
  <div class="homesPages">
    <el-row>
      <el-col :span="15">
        <Header ref="header" @emit:searchByName="eventSearchByName" @c-openDialogToSelectDefaultBranch="openDialogToSelectDefaultBranch" />
        <!-- <breadcrumb @c-change="changeMainComponent" :main="is_main" /> -->
        <div class="contentLeftCart">
          <template v-if="page == 'parent'">
            <ParentCategory
              @c-change="changePageFromParentComponent"
              :search_by_name="searchByName"
              :refresh="refresh"
              ref="parent-category">
            </ParentCategory>
          </template>
          <template v-else-if="page == 'children'">
            <ChildrenCategory :dataCategory="dataCategory" @c-change="changePageComponent"></ChildrenCategory>
          </template>
          <template v-else-if="page == 'payment'">
            <keyboard @c-change="changePageComponent" />
          </template>
        </div>
      </el-col>
      <!-- end el-col  -->
      <el-col :span="9">
        <div class="ovHidden">
          <haederRight
            @c-change="changeMainComponent"
            @refresh:products="refresh = $event"
            :ordersQuantity="ordersQuantity"
            :page="page"
            ref="header-right"
          />
        </div>
      </el-col>
      <!-- end el-col  -->
    </el-row>
    <!-- row el-col  -->

    <el-dialog
        class="default-branch_modal"
        :title="global_variable_is_work_with_branch ? $t('Select the branch and shop where you will work') : $t('Select the shop where you will work')"
        :visible.sync="dialogToSelectDefaultBranch"
        width="30%"
        center
        :before-close="handleCloseDialogDefaultBranchAndShop">
        <el-main>

            <template v-if="global_variable_is_work_with_branch">
              <div style="display: flex; flex-direction: column; align-items: flex-start;">
                  <label class="el-form-item__label">{{ $t("Branch") }}</label>
                  <div class="el-form-item__content" style="line-height: unset; width: 100%">
                    <el-select v-model="form_default_branch_id" @change="selectChangeDefaultBranchId" class="d-block" :placeholder="$t('Branch')">
                        <el-option
                            v-for="(item, index) in user_branches"
                            :key="index + '-user_branches'"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                  </div>
              </div>  
            </template>

            <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <label class="el-form-item__label">{{ $t("Shop") }}</label>
                <div class="el-form-item__content" style="line-height: unset; width: 100%">
                  <el-select v-model="form_default_shop_id" class="d-block" :placeholder="$t('Shop')">
                      <el-option
                          v-for="(item, index) in user_shops_filtered"
                          :key="index + '-user_shops'"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                  </el-select>
                </div>
            </div>
        </el-main>

        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelDialogDefaultBranchAndShop">{{ $t('close') }}</el-button>
            <el-button type="primary" @click="changeDefaultBranchAndShop">{{ $t('save') }}</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../components/Header";
import ParentCategory from "../../components/ParentCategory";
import ChildrenCategory from "../../components/ChildrenCategory";
import haederRight from "../../components/haederRight";
import keyboard from "../../components/keyboard ";
import Breadcrumb from "../../components/Breadcrumb";
import auth from "../../api/auth/auth";
import authUtil from "../../utils/auth";
import request from "../../utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      is_main: "home",
      ordersQuantity: 0,
      refresh: false,
      searchByName: "",
      page: 'parent',
      dataCategory: null,
      dialogToSelectDefaultBranch: false,
      form_default_branch_id: null,
      form_default_shop_id: null,
    };
  },

  name: "homesPages",
  components: {
    Breadcrumb,
    Header,
    haederRight,
    keyboard,
    ParentCategory,
    ChildrenCategory
  },

  asyncComputed: {
    async getOrdersQuantity() {
      this.$store.dispatch("order/getCount");
    }
  },

  computed: {
      ...mapGetters({
          user_shops: "auth/user_shops",
          user_branches: "auth/user_branches",
      }),

      user_shops_filtered: function() {
        let result = this.user_shops;

        if (this.global_variable_is_work_with_branch) {
          result = _.filter(result, ['branch_id', this.form_default_branch_id]);
        }

        return result;
      },
  },

  async created() {
    await auth
      .auth()
      .then(async res => {
        if (res.status === 200) {
          const permissions = res.data.result.data.role.permissions;          
          this.$store.dispatch("auth/savePermissions", permissions);

          // userni shop larini statega yoziw. Keyin qaysi shopda otirganini belgilab qoyiw ucun
          this.$store.commit('auth/SET_USER_SHOPS', res.data.result.data.shops);

          let auth_user_shop_id = parseInt(authUtil.getShop()); // localStorage dan shop_idni  olish

          if (this.global_variable_is_work_with_branch) {
              this.$store.commit('auth/SET_USER_BRANCHES', res.data.result.data.user_branches);
              this.$store.commit('auth/SET_DEFAULT_BRANCH_ID', res.data.result.data.default_branch_id);
              this.form_default_branch_id = res.data.result.data.default_branch_id;

              let default_branch_id = localStorage.getItem('default_branch_id');
              default_branch_id = default_branch_id === null ? null : parseInt(default_branch_id);

              // Agar faqat bitta filialda iwlasa owa filialni avtomatik default qiliw
              if (!default_branch_id && this.user_branches.length == 1) {
                  if (this.user_branches[0]) {
                      let first_branch_id = this.user_branches[0].id;

                      localStorage.setItem('default_branch_id', first_branch_id);
                      this.$store.commit('auth/SET_DEFAULT_BRANCH_ID', first_branch_id);

                      // Agar hali shop tanlanmagan va shoplar kop bolsa bittasini tanlaw ucun modalni ociw
                      if (!auth_user_shop_id && this.user_shops.length > 1) {
                          this.dialogToSelectDefaultBranch = true;
                      }
                      // Agar magazin bitta boladigan bolsa owani localStorage ga saqlaw va filialam bitta bolsa tepada uyam saqlanvotti.
                      // Bunda default_filial bilan shopning filial har xil bolib qoliwi mn
                      else if (!auth_user_shop_id && this.user_shops.length == 1) {
                          if (this.user_shops[0]) {
                              let first_shop = this.user_shops[0];
                              authUtil.setShop(first_shop.id);
                              this.$store.commit('auth/SET_DEFAULT_SHOP_ID', first_shop.id);

                              this.form_default_shop_id = first_shop.id;
                          }
                      }
                      else if (auth_user_shop_id) {
                          this.$store.commit('auth/SET_DEFAULT_SHOP_ID', auth_user_shop_id);
                          this.form_default_shop_id = auth_user_shop_id;
                      }
                  }
              }
              // Agar kop filialda iwlasa bittasini tanlab qoyiw ucun modal ociw
              else if (!default_branch_id && this.user_branches.length > 1) {
                  this.dialogToSelectDefaultBranch = true;
              }
              else if (default_branch_id) {
                  this.$store.commit('auth/SET_DEFAULT_BRANCH_ID', default_branch_id);
                  this.form_default_branch_id = default_branch_id;
                  
                  // Agar hali shop tanlanmagan va shoplar kop bolsa bittasini tanlaw ucun modalni ociw
                  if (!auth_user_shop_id && this.user_shops.length > 1) {
                      this.dialogToSelectDefaultBranch = true;
                  }
                  // Agar magazin bitta boladigan bolsa owani localStorage ga saqlaw
                  else if (!auth_user_shop_id && this.user_shops.length == 1) {
                      if (this.user_shops[0]) {
                          let first_shop = this.user_shops[0];
                          authUtil.setShop(first_shop.id);
                          this.$store.commit('auth/SET_DEFAULT_SHOP_ID', first_shop.id);

                          this.form_default_shop_id = first_shop.id;
                      }
                  }
                  else if (auth_user_shop_id) {                    
                      this.$store.commit('auth/SET_DEFAULT_SHOP_ID', auth_user_shop_id);
                      this.form_default_shop_id = auth_user_shop_id;
                  }
              }
          }
          else {
            // Agar hali shop tanlanmagan va shoplar kop bolsa bittasini tanlaw ucun modalni ociw
            if (!auth_user_shop_id && this.user_shops.length > 1) {
                this.dialogToSelectDefaultBranch = true;
            }
            // Agar magazin bitta boladigan bolsa owani localStorage ga saqlaw va filialam bitta bolsa tepada uyam saqlanvotti.
            // Bunda default_filial bilan shopning filial har xil bolib qoliwi mn
            else if (!auth_user_shop_id && this.user_shops.length == 1) {
                if (this.user_shops[0]) {
                    let first_shop = this.user_shops[0];
                    authUtil.setShop(first_shop.id);
                    this.$store.commit('auth/SET_DEFAULT_SHOP_ID', first_shop.id);
                }
            }
            else if (auth_user_shop_id) {
                this.$store.commit('auth/SET_DEFAULT_SHOP_ID', auth_user_shop_id);
                this.form_default_shop_id = auth_user_shop_id;
            }
          }
        }
      })
      .catch(err => {
        authUtil.clearStorge();
        this.$router.push({ name: "login" });
      });
    await request
      .get("/currencies/inventory")
      .then(async res => {
        if (res.status === 200) {
          const { result } = res.data;
          this.$store.dispatch("cart/saveCurrency", result.data.currencies);
        }
      })
      .catch();
  },

  methods: {
    changeMainComponent(data) {
      this.searchByName = ''; // parentga otganda eski qidirgan narsani tozalavorish
      this.page = data;
    },
    changePageFromParentComponent(data) {
      this.page = data.page;
      this.dataCategory = data;
    },
    changePageComponent(data) {
      this.searchByName = ''; // parentga otganda eski qidirgan narsani tozalavorish
      this.page = data.page;
    },
    eventSearchByName(data) {
      // name boyicha qidirish bolganda asosiy pagega otish
      this.page = 'parent';
      this.searchByName = data;
    },
    openDialogToSelectDefaultBranch() {
        let default_branch_id_inlocal_storage = localStorage.getItem('default_branch_id');
        this.form_default_branch_id = default_branch_id_inlocal_storage === null ? null : parseInt(default_branch_id_inlocal_storage);

        this.form_default_shop_id = authUtil.getShop() === null ? null : parseInt(authUtil.getShop());


        this.dialogToSelectDefaultBranch = true;
    },
    selectChangeDefaultBranchId() {
      this.form_default_shop_id = null;
    },
    changeDefaultBranchAndShop() {        
        if (!this.validateDefaultBranchAndShopForm()) {
          return;
        }

        localStorage.setItem('default_branch_id', this.form_default_branch_id);
        this.$store.commit('auth/SET_DEFAULT_BRANCH_ID', this.form_default_branch_id);

        authUtil.setShop(this.form_default_shop_id);
        this.$store.commit('auth/SET_DEFAULT_SHOP_ID', this.form_default_shop_id);

        this.dialogToSelectDefaultBranch = false;

        // Glavniy page ga qaytiw va produktlani spiskasini bowqattan olib keliw. 
        this.page = 'parent';
        if (this.$refs['parent-category']) {
          this.$refs['parent-category'].loadTrendProductsAndModel();
        }
        
        if (this.$refs['header-right']) {
          this.$refs['header-right'].activeTab = "cart";
        }
        this.$store.dispatch("order/getCount");

        this.$store.dispatch("cart/clear");
        this.$store.dispatch("order/clear");
        authUtil.removeClientData();
    },
    handleCloseDialogDefaultBranchAndShop(done) {      
      if (this.validateDefaultBranchAndShopFormInCancel()) {
        done();
      }
    },
    cancelDialogDefaultBranchAndShop() {
      if (!this.validateDefaultBranchAndShopFormInCancel()) {
        return;
      }

      this.dialogToSelectDefaultBranch = false;
    },
    validateDefaultBranchAndShopForm() {
      if (this.global_variable_is_work_with_branch) {
        if (!this.form_default_branch_id || !this.form_default_shop_id) {
          this.$message({
              type: "warning",
              title: this.$t("warning"),
              message: this.$t("Please select a branch and store"),
              duration: 10000,
              showClose: true
          });
          return false
        }
      }
      else {
        if (!this.form_default_shop_id) {
          this.$message({
              type: "warning",
              title: this.$t("warning"),
              message: this.$t("Please select a store"),
              duration: 10000,
              showClose: true
          });
          return false
        }
      }

      return true;
    },
    validateDefaultBranchAndShopFormInCancel() {
      if (this.global_variable_is_work_with_branch) {
        // localStorage da branch_id yoki shop_id yoq bolsa, faqat formani soxranit qilish kk
        let default_branch_id_inlocal_storage = localStorage.getItem('default_branch_id');
        default_branch_id_inlocal_storage = default_branch_id_inlocal_storage === null ? null : parseInt(default_branch_id_inlocal_storage);

        let default_shop_id_inlocal_storage = authUtil.getShop() === null ? null : parseInt(authUtil.getShop());

        if (!default_branch_id_inlocal_storage || !default_shop_id_inlocal_storage) {
          this.$message({
              type: "warning",
              title: this.$t("warning"),
              message: this.$t("Please select a branch and store and Save"),
              duration: 10000,
              showClose: true
          });
          return false
        }
      }
      else {
        // localStorage da shop_id yoq bolsa, faqat formani soxranit qilish kk
        let default_shop_id_inlocal_storage = authUtil.getShop() === null ? null : parseInt(authUtil.getShop());
        
        if (!default_shop_id_inlocal_storage) {
          this.$message({
              type: "warning",
              title: this.$t("warning"),
              message: this.$t("Please select a store and Save"),
              duration: 10000,
              showClose: true
          });
          return false
        }
      }

      return true;
    }
  }
};
</script>

<style scoped>
.default-branch_modal .dialog-footer button {
    min-width: 150px;
}
</style>
