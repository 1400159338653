<template>
  <div class="commentModal modal-center x-right-top">
    <el-dialog
      :visible="open"
      width="50%"
      :center="true"
      :before-close="handleClose"
      :title="$t('comment')"
    >
      <el-input
        v-model="result.comment"
        type="textarea"
        :rows="2"
        placeholder=""
        @change="result.comment = $event"
      >
      </el-input>
      <div class="d-flex buttonSModal">
        <el-button type="success" @click="returnValue">{{
          $t("save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VueTypes from "vue-types";

export default {
  name: "CommentModal",
  props: {
    open: VueTypes.bool.isRequired,
    comment: VueTypes.string.isRequired,
  },
  data() {
    return {
      result: {
        comment: "",
        open: false,
      }
    };
  },
  watch: {
    comment: {
      immediate: true,
      handler(newVal) {
        this.result.comment = newVal;
      }
    }
  },
  methods: {
    returnValue() {
      this.result.open = false;
      this.$emit("change:commentForOrder", this.result);
    },
    handleClose(done) {
      this.result.comment = this.comment;
      done();
      this.$emit("modal:close", false);
    }
  }
};
</script>

<style scoped></style>
