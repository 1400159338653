export const model = {
    transactionable_id: '',
    payment_type_id: '',
    amount: 0,
    currency: '',
    currency_id: null,
    rate: 1,
    comment: '',
    datetime: new Date(),
};
