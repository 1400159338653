import { getActivePromocodes, checkPromocodeIsActive, search } from '@/api/promocodes';

export const actions = {    
  getActivePromocodes({ commit }) {
        return new Promise((resolve, reject) => {
          getActivePromocodes().then(res => {                
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    checkPromocodeIsActive({ commit }, data) {
      return new Promise((resolve, reject) => {
        checkPromocodeIsActive(data).then(res => {
              resolve(res.data.result)
          }).catch(err => {
              reject(err.response.data)
          })
      })
    },

    search({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        search(params).then(res => {
              resolve(res.data.result)
          }).catch(err => {
              reject(err.response.data)
          })
      })
    },
}
