<template>
    <div class="new-product-block">
        <div class="top-title">
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-button @click="returnToPrevCategory()" type="success" size="medium">{{ $t('Back') }}</el-button>
                </el-col>
                <el-col :span="12">
                    <div class="name-block-product">
                        {{ dataCategory ? dataCategory.category_name : '' }}
                    </div>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
        </div>

        <el-row :gutter="10">
            <el-col v-for="(category, index) in categories" :key="'categories-' + index" :xs="6" :sm="6" :md="6" :lg="4" :xl="4">
                <div @click="setChildrenCategory(category)" class="itme-new-category card-shodow">
                    <img v-if="category.min_image_path" :src="global_backend_url + category.min_image_path" alt="">
                    <img v-else src="../../public/img/t-shirt.svg" alt="">
                    <div class="itme-new-category-name">{{ category.name }}</div>
                </div>
            </el-col>
        </el-row>

        <div class="new-product-block-tabs">
            <el-tabs type="border-card">
                <el-tab-pane>
                    <span slot="label"><i class="el-icon-s-grid"></i></span>

                    <el-row :gutter="10">
                        <el-col v-for="(product, index) in products" :key="'products1-' + index" :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                            <div @click="clickedProduct(product)" class="itme-new-pradoct card-shodow">
                                <div class="top-product">
                                    <img v-if="product.min_image_path" :src="global_backend_url + product.min_image_path" alt="">
                                    <img v-else src="../../public/img/t-shirt.svg" alt="">
                                    <div class="w-100">
                                        <div class="nwe-product-name">{{ product.name }}</div>
                                        <div class="nwe-product-code"> {{ product.code }}</div>
                                    </div>
                                </div>
                                <div class="bottom-product">
                                    <div class="t-left w-50">
                                        <div class="raz">{{ getProductSizeNames(product) }}</div>
                                        <div class="color-product">
                                            <span v-for="(color, index) in product.colors" :key="'colors-' + index" :style="{ backgroundColor: color.code ? color.code : white }">
                                              {{ color.remainder }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="t-right w-50">
                                        <div class="sum-product">{{ product.price | formatMoney(2) }}</div>
                                        <div class="son-product">{{ product.total_remainder }} {{ product.measurement ? product.measurement.name : '' }}</div>
                                    </div>
                                </div>

                            </div>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane>
                    <span slot="label"><i class="el-icon-c-scale-to-original"></i></span>
                    <table class="table table-product">
                        <thead>
                            <tr>
                                <th>{{ $t('name') }}</th>
                                <th>{{ $t('Color') }}</th>
                                <th>{{ $t('Available') }}</th>
                                <th>{{ $t('price') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, index) in products" :key="'products2-' + index"
                              @click="clickedProduct(product)">
                                <td>
                                    <div class="top-product">
                                        <img v-if="product.min_image_path" :src="global_backend_url + product.min_image_path" alt="">
                                        <img v-else src="../../public/img/t-shirt.svg" alt="">
                                        <div>
                                            <div class="nwe-product-name">{{ product.name }}</div>
                                            <div class="nwe-product-code"> {{ product.code }}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="raz">{{ getProductSizeNames(product) }}</div>
                                    <div class="color-product">
                                        <span v-for="(color, index) in product.colors" :key="'colors-' + index" :style="{ backgroundColor: color.code ? color.code : white }">
                                          {{ color.remainder }}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="son-product">{{ product.total_remainder }} {{ product.measurement ? product.measurement.name : '' }}</div>
                                </td>
                                <td>
                                    <div class="sum-product">{{ product.price | formatMoney(2) }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div class="discountModal modal-center x-right-top">
            <el-dialog
                :visible.sync="dialogModelForm"
                width="70%"
                @closed="closeModelForm"
                center>
                <div v-if="selectedModel" v-loading="loadingModel" class="modal-product">
                    <div class="left-modal-p">
                        <img v-if="image_path_of_color" :src="global_backend_url + image_path_of_color" alt="">
                        <img v-else src="../../public/img/t-shirt.svg" alt="">
                    </div>
                    <div class="right-modal-p">
                        <div class="name-modal-pcodcut">
                            <div class="nomee">{{ selectedModel.name }}</div>
                            <div class="cod"> {{ selectedModel.code }}</div>
                        </div>

                        <div class="name-modal-pcodcut">
                          <el-radio-group v-model="selectedSizeId">
                            <el-radio-button v-for="(size, index_size) in selectedModel.product_sizes" :key="'product_sizes' + index_size" :label="size.id">{{ size.name }}</el-radio-button>
                          </el-radio-group>
                        </div>

                        <div class="name-modal-pcodcut chekz">
                            <el-radio-group v-model="selectedColorId" @change="selectedColor">
                              <el-radio
                                v-for="(color, color_index) in selectedModelColors"
                                :key="'colors-' + color_index"
                                :style="{ backgroundColor: color.code ? color.code : white }"
                                :label="color.id"
                                class="sony-Product">
                                <span style="display:none;">{{ color.id }}</span>
                                <span class="sony-Product-i">{{ color.available | formatNumber }}</span>
                              </el-radio>
                            </el-radio-group>
                        </div>

                        <div class="name-modal-pcodcut new-InputNumber">
                            <el-input-number v-model="quantityOfProduct" :min="1"></el-input-number>

                            <span v-if="selectedColorId && selectedSizeId && product_available > 0" class="product-available">{{ $t('Available') }}: {{ product_available }}</span>
                            <span v-if="selectedColorId && selectedSizeId && product_available <= 0" class="product-not-available">{{ $t('Not available') }}</span>
                        </div>
                    </div>
                </div>
                <el-row class="modal-product-btn">
                    <el-button @click="closeModelForm()" type="warning">{{ $t('cancel') }}</el-button>
                    <el-button @click="addToCartProductOfModel()" type="success">{{ $t('In the Basket') }}</el-button>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Order from "../models/Order";
import OrderItem from "../models/OrderItem";
import authUtil from "@/utils/auth";

  export default {
    props: ['dataCategory'],
    data() {
      return {
        dialogModelForm: false,
        nested_categories: [],
        categories: [],
        products: [],
        selectedModel: null,
        selectedModelColors: [],
        selectedModelProducts: [],
        selectedColorId: null,
        selectedSizeId: null,
        quantityOfProduct: 1,
        image_path_of_color: '',
        loadingModel: false,
      };
    },
    mounted() {
      if (this.dataCategory) {
        this.nested_categories.push(this.dataCategory.category_id);

        this.loadChildrenCategories(this.dataCategory.category_id);
        this.loadProductsAndModelOfCategory(this.dataCategory.category_id);
      }
    },
    computed: {
      product_available: function() {
        let self = this;
        let product = _.find(this.selectedModelProducts, function(o) {
          return o.color_id == self.selectedColorId && o.size_id == self.selectedSizeId;
        })
        return product ? product.total_remainder : 0;
      },
    },
    methods: {
      ...mapActions({
        getChildrenCategories: "categories/getChildrenCategories",
        getProductsAndModelOfCategory: "categories/getProductsAndModelOfCategory",
        getDataOfModel: "productModels/getDataOfModel",
      }),
      loadChildrenCategories(category_id) {
        const loading = this.$loading({
          customClass: "login-loading",
          lock: true,
          text: "Загрузка",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        this.getChildrenCategories({
          parent_category_id: category_id,
        })
          .then(res => {
            this.categories = res.categories;
            loading.close();
          })
          .catch(err => {
            loading.close();
            this.$alert(err)
          })
      },
      loadProductsAndModelOfCategory(category_id) {
        const loading = this.$loading({
          customClass: "login-loading",
          lock: true,
          text: "Загрузка",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        this.getProductsAndModelOfCategory({
          shop_id: authUtil.getShop(),
          category_id: category_id,
        })
          .then(res => {
            this.products = res.products;
            loading.close();
          })
          .catch(err => {
            loading.close();
            this.$alert(err)
          })
      },
      setChildrenCategory(category) {
        this.categories = [];
        this.products = [];

        this.nested_categories.push(category.id);

        this.loadChildrenCategories(category.id);
        this.loadProductsAndModelOfCategory(category.id);
      },
      clickedProduct(product) {
        if (product.type == 'model') {
          this.loadingModel = true;          
          this.getDataOfModel({ 
              shop_id: authUtil.getShop(),
              model_id: product.id, // product.id bu model_id          
          }) 
            .then(res => {
              this.selectedModelColors = res.colors;
              this.selectedModelProducts = res.products;
              this.loadingModel = false;
            })
            .catch(err => {
              this.loadingModel = false;
              this.$alert(err);
            })

          this.selectedModel = product;
          this.image_path_of_color = product.min_image_path;
          this.dialogModelForm = true;
        }
        else {
          this.addToCart(product, 1, product.price);

          this.closeModelForm();
        }
      },
      selectedColor(val) {
        if (this.selectedModel) {
          let color = _.find(this.selectedModelColors, ['id', val]);
          if (color) {
            this.image_path_of_color = color.min_image_path;
          }
        }
      },
      addToCartProductOfModel() {
        if (!this.selectedModel) {
          this.$notify({
            type: "error",
            message: this.$t('Product model not selected'),
            showClose: true
          });
        }

        if (!this.selectedColorId) {
          this.$notify({
            type: "error",
            message: this.$t('Color not selected'),
            showClose: true
          });
        }

        if (!this.selectedSizeId) {
          this.$notify({
            type: "error",
            message: this.$t('Size not selected'),
            showClose: true
          });
        }

        let self = this;
        let product = _.find(this.selectedModelProducts, function(o) {
          return o.color_id == self.selectedColorId && o.size_id == self.selectedSizeId;
        })

        if (product) {
          if (product.total_remainder > 0 && this.quantityOfProduct <= product.total_remainder) {
            let color = _.find(this.selectedModelColors, ['id', product.color_id]);
            let size = _.find(this.selectedModel.product_sizes, ['id', product.size_id]);
            product.color_code = color ? color.code : null;
            product.size_name = size ? size.name : null;

            this.addToCart(product, this.quantityOfProduct, this.selectedModel.price);

            this.closeModelForm();
          }
          else {
            this.$notify({
              type: "error",
              message: this.$t('The product is not available'),
              showClose: true
            });
          }
        }
      },

      async addToCart(product, qty = 1, price = 0) {
        const hasProduct = await this.$store.getters["cart/getProduct"](
          product.id
        );
        if (product.total_remainder !== 0) {
          if (hasProduct) {
            const item = OrderItem.reverseProduct(product, qty, price);

            return await this.$store.dispatch("cart/add", item);
          }
          else {
            const order = await this.$store.getters["order/getOrder"];
            if (!order.retail_order_id) {
              await this.$store.dispatch("order/create", Order.newOrder());
            }
            const item = OrderItem.reverseProduct(product, qty, price);

            return await this.$store.dispatch("cart/add", item);
          }
        }
        else {
          this.$notify({
            type: "info",
            message: this.$t('The product was not found in warehouses'),
          });
        }
      },
      closeModelForm() {
        this.dialogModelForm = false;
        this.selectedModel = null;
        this.selectedModelColors = [];
        this.selectedModelProducts = [];
        this.selectedColorId = null;
        this.selectedSizeId = null;
        this.quantityOfProduct = 0;
      },
      getProductSizeNames(item) {
        var result = '';
        _.forEach(item.product_sizes, function (item, key) {
            if (key === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });

        return result;
      },
      returnToPrevCategory() {
        this.nested_categories.pop();
        // agar nested_categories da id qolmasa deme glavniyga otish kere bolmasa shotti ozida bitta oldinga kategoriyani malumotlarini olib kelish
        if (this.nested_categories.length == 0) {
          this.$emit("c-change", {
            page: "parent",
          });
        }
        else {
          this.categories = [];
          this.products = [];

          let prev_category_id = _.last(this.nested_categories);
          this.loadChildrenCategories(prev_category_id);
          this.loadProductsAndModelOfCategory(prev_category_id);
        }
      }
    }
  };
</script>


<style>
  .checkbox-style .el-radio__inner {
    border-color: #409eff;
    background-color: #fff;
    box-shadow: -1px 0 0 0 #409eff;
  }
  .checkbox-style .el-radio__input:focus + .el-radio__inner {
    box-shadow: -1px 0 0 0 #409eff;
    border-color: #409eff;
  }
  .checkbox-style.is-checked .el-radio__inner {
    border-color: #409eff;
    background-color: #409eff;
  }
</style>
