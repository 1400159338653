<template>
  <div class="promocodeModal modal-center x-right-top">
    <el-dialog
      :visible="open"
      width="40%"
      :center="true"
      :before-close="handleClose"
      :title="$t('Promocode')"
      @opened="afterOpen"
      @closed="afterLeave"
    >
      <el-row>
        <el-col>
          <el-input v-model="promocode"></el-input>
        </el-col>        
      </el-row>

      <el-row :gutter="20">
        <el-col v-for="(active_promocode, index) in active_promocodes" :key="'active_promocodes-' + index"  :span="8">
          <el-tag 
            @click="selectPromocode(active_promocode)"
            class="active-promocode-tag"
            :effect="(selectedPromocode && (selectedPromocode.id == active_promocode.id)) ? 'dark' : 'plain'">
            {{ active_promocode.code }}
          </el-tag>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import VueTypes from "vue-types";
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  
  props: {
    open: VueTypes.bool.isRequired,
  },
  data() {
    return {
      promocode: '',
      active_promocodes: [],
      selectedPromocode: null,
    };
  },

  computed: {
    ...mapGetters({
      products: "cart/getProducts",
    })
  },

  watch: {
    promocode: {
      handler(newVal) {
        if (newVal) {
          this.selectedPromocode = null;
        }

        if (newVal && (newVal.length >= 3)) {
          this.debouncedSearchPromocode()
        }
      }
    }
  },

  created() {
    this.debouncedSearchPromocode = debounce(this.searchPromocode, 1000);
  },

  methods: {
    ...mapActions({
      getActivePromocodes: "promocodes/getActivePromocodes",
      checkPromocodeIsActive: "promocodes/checkPromocodeIsActive",
      search: "promocodes/search"
    }),

    searchPromocode() {
      this.search({search_pattern: this.promocode})
          .then(res => {
            this.active_promocodes = res.promocodes;            
          })
          .catch(err => {
            this.$alert(err)
          })
    },

    afterOpen() {
      this.clearData();

      this.getActivePromocodes()
          .then(res => {
            this.active_promocodes = res.promocodes;            
          })
          .catch(err => {
            this.$alert(err)
          })
    },

    selectPromocode(promocode) {
      this.selectedPromocode = promocode;
      this.applyPromocode();
    },

    applyPromocode() {
      let cart_products_ids = [];
      this.products.forEach(item => {
        if (item.product) {
          cart_products_ids.push(item.product.id)
        }
      });

      if (this.selectedPromocode) {
        this.checkPromocodeIsActive({
            code: this.selectedPromocode.code,
            cart_products_ids: cart_products_ids,
          })
            .then(res => {
              let promocode_data = res.promocode;
              let products_ids = res.products_ids; // shu id li produktlaga skidka berish mn

              this.$store.dispatch("cart/changeDiscountUsingPromocode", {
                discount: promocode_data.discount_percentage,
                products_ids: products_ids,
                promocode_id: promocode_data.id
              });

              this.$store.dispatch("order/setPromocodeId", promocode_data.id);
              
              this.$emit("setPromocodeName", this.selectedPromocode.code);
              this.$emit("modal:close", false);
            })
            .catch(err => {
              this.$alert(err)
            })
      }
      else {
        this.$notify({
          type: "error",
          message: this.$t('Promocode not shown'),
          showClose: true
        });
      }
    },
    handleClose(done) {
      done();
      this.$emit("modal:close", false);
    },
    afterLeave() {
      this.clearData();
    },
    clearData() {
      this.promocode = '';
      this.active_promocodes = [];
      this.selectedPromocode = null;
    }
  }
};
</script>

<style scoped></style>
