export default {
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        close() {
            this.$emit("close-dialog");
        }
    }
}