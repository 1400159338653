<template>
  <div>
    <div class="login-new">
        <div class="left-login w-50">
          <img class="w-30" src="../../../public/img/logo2.png" alt="" />
          <el-tabs type="card" @tab-click="handleClick">
            <el-tab-pane :label="$t('Sign in')">
              <el-form ref="form" :model="form">
                <el-divider content-position="center">{{ $t('Using a phone number') }}</el-divider>
                <el-form-item >
                  <el-input v-model="form.phone" @keyup.enter.native="login()" :placeholder="$t('Telephone')"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="form.password" @keyup.enter.native="login()" type="password" show-password  placeholder="***************"></el-input>
                </el-form-item>
                <el-button @click="login()" type="success" round>{{ $t('Authorization') }}</el-button>
              </el-form>
            </el-tab-pane>
            <el-tab-pane :label="$t('Login with a QR code')">
              <div class="img-qr">
                <img  src="../../../public/img/qr-code-scan.png" alt="" />
              </div>
            </el-tab-pane>
          </el-tabs>

        </div>
        <div class="right-login  w-50">
          <img class="w-80" src="../../../public/img/auth-screens.png" alt="" />
        </div>
    </div>
  </div>
</template>

<script>
import auth from "../../api/auth/auth";

export default {
  name: "login",
  data() {
    return {
      correct: false,
      wrong: false,
      user_id: "",

      form: {
        phone: '+998',
        password: '',
      },

    };
  },
  methods: {
    async login() {
      const loading = this.$loading({
        customClass: "login-loading",
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });


      await auth
        .login(this.form)
        .then(async res => {
          if (res.data.result.success) {
            loading.close();
            const auth_data = res.data.result.data;
            await this.$store.dispatch("auth/login", auth_data);
            await this.$router.push({ name: "home" });
          }
        })
        .catch(err => {
          loading.close();
          return this.$notify({
            type: "error",
            message: err.response.data.error.message
          });
        });
    },

    handleClick(tab, event) {

    },
  }
};
</script>
<style lang="scss">
.login-loading .el-icon-loading {
  font-size: 4em;
}

.login-loading .el-loading-text {
  font-size: 24px;
}

.login-loading .refresh_button {
  font-size: 3em;
  cursor: pointer;
}

.login-loading .refresh_button i {
  color: white;
}
</style>
