<template>
  <div>
    <el-table
      :data="costTransactionHistory"
      border
      style="width: 100%">
      <el-table-column
        prop="id"
        :label="$t('n')"
        width="100">
      </el-table-column>
      <el-table-column
        prop="cost_name"
        :label="$t('Cost')">
      </el-table-column>
      <el-table-column
        prop="amount"
        :label="$t('Amount')">
      </el-table-column>
      <el-table-column
        prop="currency_name"
        :label="$t('currency')">
      </el-table-column>
      <el-table-column
        prop="payment_type_name"
        :label="$t('Payment type')">
      </el-table-column>
      <el-table-column
        prop="datetime"
        :label="$t('datetime')">
      </el-table-column>
      <el-table-column
        prop="comment"
        :label="$t('comment')">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import auth from '@/utils/auth';

  export default {  
    mounted() {
      this.getCostTransactionHistoryByRetail(auth.getShop());
    },

    computed: {
        ...mapGetters({
          costTransactionHistory: "costs/costTransactionHistory"
        })
    },

    methods: {
        ...mapActions({
          getCostTransactionHistoryByRetail: 'costs/getCostTransactionHistoryByRetail'
        })
    }
  }
</script>