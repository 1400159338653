<template>
    <div class="input__box">
        <AutoNumericVue
            v-model="price"
            @focus.native="onFocus"
            @blur="onBlur"
            :options="{
                digitGroupSeparator: ' ',
                allowDecimalPadding: false,
                decimalCharacter: ',',
                decimalCharacterAlternative: '.',
                roundingMethod: 'U',
                minimumValue: '0',
                caretPositionOnFocus: 'decimalLeft',
                selectOnFocus: false,
                decimalPlaces: 9,
                decimalPlacesShownOnFocus: 9,
                maximumValue: this.max,
            }"
        >
        </AutoNumericVue>
    </div>
</template>

<script>
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
    export default {
        name: 'price',
        components: {AutoNumericVue},
        props: {
            value: {},
            max: {
                type: String,
                default: '10000000000000',
            }
        },
        data() {
            return {
                price: this.value,
                focused: false
            }
        },
        watch: {
            price: function (newVal){
                this.$emit('input', newVal);
                this.$emit('change', newVal);
            },
            value: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.price = newVal;
                }
            }
        },
        methods: {
            onFocus() {
                this.focused = true;
                if (this.focused && (this.price == 0)) this.price = '';
            },
            onBlur() {
                this.focused = false;
                if(!this.focused && (this.price <= 0)) this.price = 0;
            }
        }
    }
</script>

<style>
.input__box {
    width: 100%;
    height: 100%;
}
.input__box input {
    width: 90% !important;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    outline: none;
    color: #606266;
    padding: 0px;
    font-size: 18px;
    text-align: center;
}
.input__box input:focus {
    border: 1px solid #00b2ff;
}


</style>
