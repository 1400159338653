export function SET_DATA(state, payload) {
  state.name = payload.name;
  state.token = payload.token;
  state.token_type = payload.token_type;
  state.role = payload.role;
  state.expires_at = payload.expires_at;
  // state.shop = payload.shop;
  state.last_login = payload.last_login;
}

export function LOG_OUT(state) {
  state.token = "";
  state.name = "";
}

export function SAVE_PERMISSIONS(state, permissions) {
  state.permissions = permissions;
}

export function SET_USER_SHOPS(state, shops){ state.user_shops = shops }
export function SET_DEFAULT_SHOP_ID(state, shop_id){ state.default_shop_id = shop_id }
export function SET_USER_BRANCHES(state, branches){ state.user_branches = branches }
export function SET_DEFAULT_BRANCH_ID(state, default_branch_id){ state.default_branch_id = default_branch_id }

