import { getSettingRetail } from '@/api/settingRetail';

export const actions = {
  getSettingRetail({ commit }) {
    return new Promise((resolve, reject) => {
      getSettingRetail().then(res => {
            resolve(res.data.result);
            commit("SET_SETTING", res.data.result.data.settingRetail);
        }).catch(err => {
            reject(err.response.data)
        })
    })
  }
}
