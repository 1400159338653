<template>
    <el-select :value="selected" @input="dispatch" remote
        :remote-method="searchProduct" @blur="blured" :placeholder="plc || $t('Products')" filterable clearable
        :size="size" class="d-block">
        <el-option v-for="item in products" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
</template>

<script>
import auth from '@/utils/auth';
import { mapActions } from 'vuex';
import mix from '@/utils/mixins/fields/form-item';

export default {
    mixins: [mix],
    props: {
        product_id: {
            default: null
        },
        mode: {
            type: Boolean,
            default: () => true
        }
    },

    data() {
        return {
            products: [],
            reservedProducts: []
        };
    },

    watch: {
        product_id: {
            handler: function () {
                this.dispatch(this.product_id)
            }
        },
        immediate: true,
        deep: true,
    },

    mounted() {
        this.fetchProducts();
    },

    methods: {
        ...mapActions({
            updateInventory: "products/inventory",
        }),

        searchProduct: _.debounce(function (pattern) {
            if (pattern) {
                this.updateInventory({ pattern: pattern, shop_id: auth.getShop() })
                    .then(res => {
                        this.products = res.products;
                    })
                    .catch(err => {
                        this.$alert(err);
                    });
            } else {
                this.setDefaults();
            }
        }, 800),

        fetchProducts() {
            this.updateInventory({ shop_id: auth.getShop() })
                .then(res => {
                    this.products = res.products;
                    this.reservedProducts = res.products;
                })
                .catch(err => {
                    this.$alert(err);
                });
        },

        setDefaults: _.debounce(function (pattern) {
            if (this.reservedProducts && this.reservedProducts.length > 0) {
                this.products = JSON.parse(JSON.stringify(this.reservedProducts));
            } else {
                this.fetchProducts();
            }
        }, 200),

        blured() {
            this.setDefaults();
        },

        dispatch(e) {
            this.$emit('input', e);
            this.selected = e;
            this.setDefaults();
        }
    },
}
</script>
