import { last, find, sumBy, round } from "lodash";
import { func } from "vue-types";

export function ADD_PRODUCT(state, item) {
  const product = find(state.products, ["product.id", item.product.id]);
  if (!product) {
    state.products.push({
      ...item
    });
    ACTIVE_ITEM(state, item.product.id);
  } else {
    const totalRemainderAll = sumBy(product.warehouses, "total_remainder");
    if (product.quantity < totalRemainderAll) {
      product.quantity += 1;
    }
    ACTIVE_ITEM(state, product.product.id);
  }
}

export function REMOVE_PRODUCT(state, index) {
  state.products.splice(index, 1);
  const item = last(state.products);
  if (item) {
    ACTIVE_ITEM(state, item.product.id);
  }
}

export function CLEAR_PRODUCTS(state) {
  state.products = [];
}

export function CHANGE_DISCOUNT(state, payload) {
  const item = find(state.products, ["product.id", payload.id]);
  item.discount_type = 'percent';
  item.discount = payload.discount;
  item.discount_sum = round(payload.discount * item.price / 100, 2);
  ACTIVE_ITEM(state, payload.id);
}

export function CHANGE_DISCOUNT_USING_PROMOCODE(state, payload) {
  // promocode orqali skidka berolidigan produktlaga skidkani yozish
  payload.products_ids.forEach(product_id => {
    const item = find(state.products, ["product.id", product_id]);
    item.discount_type = 'percent';
    item.discount = payload.discount; 
    item.discount_sum = round(payload.discount * item.price / 100, 2);     
    item.promocode_id = payload.promocode_id;
  });
}

export function CHANGE_NOTE(state, payload) {
  const item = find(state.products, ["product.id", payload.id]);
  item.note = payload.note;
  ACTIVE_ITEM(state, payload.id);
}

export function CHANGE_QUANTITY(state, payload) {
  const item = find(state.products, ["product.id", payload.id]);
  item.quantity = payload.quantity;

  // agar bitta sklad bolsa undagi qty niyam ozgartirish
  if (item.warehouses.length == 1 && item.warehouses[0].quantity) {
    if (item.warehouses[0].total_remainder >= payload.quantity) {
      item.warehouses[0].quantity = payload.quantity;
    }
  }
  
  ACTIVE_ITEM(state, payload.id);
}

export function CHANGE_PRICE(state, payload) {
  const item = find(state.products, ["product.id", payload.id]);  
  item.price = payload.price;
  ACTIVE_ITEM(state, payload.id);
}

export function CHANGE_WAREHOUSE(state, payload) {
  const item = find(state.products, ["product.id", payload.id]);
  item.warehouses = payload.warehouses;
  ACTIVE_ITEM(state, payload.id);
}

export function DECREMENT(state, id) {
  const item = find(state.products, ["product.id", id]);
  item.quantity -= 1;
  ACTIVE_ITEM(state, payload.id);
}

export function INCREMENT(state, id) {
  const item = find(state.products, ["product.id", id]);
  item.quantity += 1;
  ACTIVE_ITEM(state, payload.id);
}

export function SAVE_CURRENCY(state, payload) {
  state.currency = payload;
}

export function ACTIVE_ITEM(state, id) {
  state.activeItem = id;
}

export function CORRECT_CART_ITEM(state, payload) {
  const item = find(state.products, ["product.id", payload.product_id]);
  let {form, warehouses} = payload;
  if (form) {
    item.quantity = form.quantity;
    item.price = form.price;
    item.discount_type = form.discount_type;
    item.discount_sum = form.discount_sum;
    item.discount = form.discount_percent;
    item.note = form.note;
  }

  item.warehouses = warehouses;
  
  ACTIVE_ITEM(state, payload.product_id);
}
