import { getTrendProductsAndModels, inventory} from '@/api/products';

export const actions = {
  getTrendProductsAndModels({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      getTrendProductsAndModels(params).then(res => {
            resolve(res.data.result)
        }).catch(err => {
            reject(err.response.data)
        })
    })
  },

  inventory({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      inventory(params).then(res => {
            resolve(res.data.result.data);
            commit("SET_INVENTORY", res.data.result.data.products);
        }).catch(err => {
            reject(err.response.data)
        })
    })
  }
}
