import Vue from 'vue'
import { mapGetters } from 'vuex';

import { notify } from './alert'
Vue.prototype.$alert = notify;

window._ = require('lodash');

/**
 * Gloabl mixin
 */
Vue.mixin({
    data() {
        return {
            global_currency_symbol: process.env.VUE_APP_CURRENCY,
            global_backend_url: process.env.VUE_APP_BACKEND_URL,
            global_variable_is_work_with_branch: /^true$/i.test(process.env.VUE_APP_IS_WORK_WITH_BRANCH),
        }
    },
    computed: {
        ...mapGetters({
            global_permissions: "auth/getPermissions"
        })
    },
    methods: {        
        globalCheckHasPermission: function(value) {
            let temp = false;
            if (this.global_permissions) {
                if (value && value instanceof Array && value.length > 0) {
                    temp = true
                    value.forEach(element => {
                        temp = temp * this.global_permissions.some(perm => perm.slug === element)
                    });
                } else {
                    temp = this.global_permissions.some(perm => perm.slug === value)
                }
            }
            return temp;
        }
    },
});
