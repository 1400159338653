import request from '@/utils/request'

export function getDataOfModel(params) {
  return request({
      url: '/productModels/getDataOfModel',
      method: 'get',
      params
  })
}

