import { revertAndExchange } from '@/api/exchangeProducts';

export const actions = {
  revertAndExchange({ commit }, data) {
    return new Promise((resolve, reject) => {
      revertAndExchange(data).then(res => {
            resolve(res.data.result)
        }).catch(err => {
            reject(err.response.data)
        })
    })
  }
}
