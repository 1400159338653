import { getConsultantsOfShop } from '@/api/consultants';

export const actions = {    
  getConsultantsOfShop({ commit }, params) {
        return new Promise((resolve, reject) => {
          getConsultantsOfShop(params).then(res => {                
                commit("SET_INVENTORY", res.data.result.consultants);
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
}
