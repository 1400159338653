export const ru_messages = {
    save: "Сохранить",
    yes: "Да",
    no: "Нет",
    cancel: "Отменить",
    totalPrice: "Общая сумма",
    discount: "Скидка",
    elements: "Элементы",
    client: "Клиент",
    user: "User",
    cashier: "Кассир",
    seller: "Продовец",
    totalDiscont: "Общая скидка",
    print: "Печать",
    clear: "Очистить",
    pay: "Оплатить",
    postponement: "Отсрочка",
    name: "Наименование",
    namePerson: "Имя",
    price: "Цена",
    qty: "Кол",
    total: "Сумма",
    basket: "Корзина",
    applications: "Заявки",
    application: "Заявка",
    code: "Код",
    home: "Главная",
    payment: "Оплата",
    cash: "Наличный",
    card: "Карта",
    combination: "Комбинация",
    currency: "Валюта",
    changeClient: "Выберите клиент",
    deleteProduct: "Хотите удалить продукт?",
    delete: "Удалить",
    savePostPonement: "Сохранить заявку",
    warning: "Предупреждение",
    printMessage: "Хотите вывести на чек?",
    surrender: "Сдача",
    noProducts: "Нет товаров",
    add: "Добавить",
    close: "Закрыть",
    addClient: "Добавить новый клиент",
    number: "Номер",
    birthday: "Дата рождения",
    phone: "Номер телефон",
    note: "Примечание",
    all: "Все",
    logOutMessage: "Вы уверены, что хотите выйти из системы?",
    exit: "Выйти",
    warehouse: "Склад",
    remainder: "Остаток",
    clearCart: "Хотите очистить корзину?",
    paymentMessageCart: "Вы снимали деньги с карты? Сдача:",
    enterPassword: "Введите пароль",
    ok: "Ок",
    totalDiscount: "Общая скидка",
    canceled: "Отменено",
    quantity: 'Количество',
    comment: 'Комментарий',
    debt: 'Долг',
    'What type of payment do you want?': 'Какой тип оплаты вы хотите?',
    Default: 'По умолчанию',
    'According to details': 'По детально',
    totalDebt: 'Общий долг',
    paid_price: 'Оплачено',
    not_paid: 'Не оплачено',
    Return: 'Возврат',
    'Shipped': 'Отгружён',
    'measurement': 'Ед. изм.',
    'reason': 'Причина',
    'Select reason': 'Выбрать причину',
    'Qty of return': 'Кол. возврат',
    'Do you want to pay a refundable amount - total_price ?': 'Вы хотите оплатить возвратную сумму - {total_price} {currency_symbol} ?',
    'Refundable amount': 'Возвратная сумма',
    'created_at': 'Дата создания',
    'Product Name, Application number': 'Название продукта, Номер заявки',
    'Telephone': 'Телефон',
    'Authorization': 'Авторизация',
    'Back': 'Назад',
    'Sign in': 'Войти',
    'Login with a QR code': 'Войти с помощью QR-кода',
    'Using a phone number': 'С помощью номера',
    'In the Basket': 'В Корзинку',
    'Product model not selected': 'Не выбрано модель продукта',
    'Color not selected': 'Не выбрано цвет',
    'Size not selected': 'Не выбрано размер',
    'The product is not available': 'Продукт недоступно',
    'Available': 'Доступно',
    'Not available': 'Недоступно',
    'The product was not found in warehouses': 'Товар не найдена в складах',
    'Color': 'Цвет',
    'Promocode': 'Промокод',
    'Apply': 'Применить',
    'Promocode not shown': 'Промокод не показан',
    'Consultant': 'Консультант',
    'Consultants': 'Консультанты',
    'Not selected': 'Не выбрано',
    'To find': 'Найти',
    'Number of orders today': 'Кол. заказ сегодня',
    'Correct': 'Корректировка',
    'Warehouse not selected': 'Не выбрано склад',
    'Online': 'Онлайн',
    'Do you confirm?': 'Вы подтверждаете?',
    'Cost': 'Расход',
    'Payment type': 'Способ оплаты',
    'Amount': 'Сумма',
    'datetime': 'Время',
    'Cost Type': 'Тип расхода',
    'rate': 'Курс',
    'History': 'История',
    'This field is required': 'Это поле обязательно для заполнения',
    'The amount must be greater than zero': 'Сумма должна быть больше нуля',
    'n': '№',
    'Costs': 'Расходы',
    'balance': 'Баланс',
    'hour': 'ч',
    'minute': 'мин',
    'Type of card': 'Тип карты',
    'Card number': 'Номер карты',
    'Card balance': 'Баланс карты',
    'Withdrawal from the balance': 'Снятие с баланса',
    'Search client by card number': 'Поиск клиента по номеру карты',
    'History of cashout from client card': 'История вывода средств с карты клиента',
    'Cashout amount': 'Сумма вывода',
    'Revert & exchange': 'Возврат и обмен',
    'Exchange': 'Обмен',
    'Quantity of exchange': 'Количество обмена',
    'Product of exchange': 'Продукт обмена',
    'Products': 'Продукты',
    'Barcode': 'Штрих-код',
    'Exchange quantity': 'Количество обмена',
    'Reverted quantity': 'Возвращенное количество',
    'Product of revert': 'Продукт возврата',
    'You owe the client sum': 'Вы должны клиенту {total_price} {currency_symbol}. Вы сделаете оплату сейчас?',
    'The client owes your sum': 'Клиент должен вам {total_price} {currency_symbol}. Вы сделаете оплату сейчас?',
    'Total price of reverted products': 'Общая стоимость возвращенных продуктов',
    'Total price of exchange products': 'Общая стоимость продуктов обмена',
    'Debtor': 'Должник',
    'Shop': 'Магазин',
    'Final calculation': 'Окончательный расчет',
    'Client info is required at payment': 'Информация о клиенте обязательна при оплате',
    'Source': 'Источник',
    'New source': 'Новый источник',
    'Create a source and attach': 'Создать источник и прикрепить',
    'Branch': 'Филиал',
    'Branches': 'Филиалы',
    'Select the branch and shop where you will work': 'Выберите филиал и магазин, в которой вы будете работать',
    'Select the shop where you will work': 'Выберите магазин, в которой вы будете работать',
    'No branch selected': 'Не выбран филиал',
    'No shop selected': 'Не выбран магазин',
    'Please select a branch and store and Save': 'Пожалуйста, выберите филиал и магазин и Сохраните',
    'Please select a store and Save': 'Пожалуйста, выберите магазин и Сохраните',
    'Search by code': 'Поиск по коду',
    'Search by name': 'Поиск по наименованию',
}
