import request from '@/utils/request'

export function getTrendProductsAndModels(params) {
  return request({
      url: '/products/getTrendProductsAndModels',
      method: 'get',
      params
  })
}

export function inventory(params) {
  return request({
      url: '/products/inventory',
      method: 'get',
      params
  })
}
