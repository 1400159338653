<template>
  <div class="keyboard">

    <span class="nazad" @click="changePage('parent')">
      <i class="el-icon-back"></i>
    </span>

    <el-row :gutter="20" class="dfJ">
      <el-col :span="6">
        <div class="pras-list">
          <div class="flex1">
            <div class="itme-payments">
              <span>{{ $t("totalPrice") }}</span
              ><b>{{ (total.totalCostPrice + amountFromBalance) | formatNumber }}
                {{ currencySymbol }}</b>
            </div>
            <div class="itme-payments">
              <span>{{ $t("discount") }}</span
              ><b
                >{{ total.totalCostDiscount | formatNumber }}
                {{ currencySymbol }}</b
              >
            </div>
            <div class="itme-payments">
              <span>{{ $t("elements") }}</span
              ><b>{{ total.totalCostElements }}</b>
            </div>
            <div class="itme-payments">
              <span>{{ $t("client") }}</span
              ><b>{{ client.name }}</b>
              <span>{{ $t("Card balance") }}</span
              ><b>{{ client.card_balance | formatMoney(2) }}</b>
            </div>
          </div>

          <div class="paymentType" v-if="client.card_balance > 0">
            <div @click="openWithdrawalBalance()" class="open-promocode">
              {{ $t('Withdrawal from the balance') }} <br> 
              <span v-if="amountFromBalance > 0"> ({{ amountFromBalance  | formatMoney(2) }}) </span>
            </div>
          </div>

          <div class="paymentType">
            <div @click="openPromocodeModal()" class="open-promocode">
              {{ $t('Promocode') }} <br>
              <span v-if="promocodeName">({{ promocodeName }})</span>
            </div>
          </div>

          <div class="paymentType currency-t" @click="currencyModal = true">
            <!-- <div class="itme-paymentType">Dollar <b>$</b></div> -->
            <div class="itme-paymentType" >
              <b><i class="el-icon-back"></i>{{ currencySymbol }}</b>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="13">
        <div v-for="(type, index) in transaction.payment_types" :key="type.payment_type + '-' + index">
        
          <template v-if="(Object.keys(transaction.payment_types).length == 1)">
            <input
              v-model="type.amount"
              ref="input" 
              :id="type.payment_type" 
              :placeholder="$t(paymentTypeTitles[type.payment_type].name)"
              class="keyboard-input"
              autocomplete="off"
              @input="input"
              @focus="setFocus(type.payment_type)"
            />
          </template>

          <template v-if="(Object.keys(transaction.payment_types).length == 2)">
            <template v-if="type.payment_type == 'card'">
              <el-row>
                <el-col :span="6">
                  <div class="itme-bottom-keyboard-custom">
                    <el-button type="primary" @click="selectCard()">
                      <template v-if="(Object.keys(paymentTypeSubName).length === 0)">
                        {{ $t("Type of card") }}
                      </template>
                      <template v-else-if="(Object.keys(paymentTypeSubName).length > 0) && (paymentTypeSubName.type == 'combination')">
                        {{ paymentTypeSubName.name }}
                      </template>
                      <template v-else>
                        {{ $t("Type of card") }}
                      </template>
                    </el-button>
                  </div>
                </el-col>
                <el-col :span="18">
                  <input
                  v-model="type.amount"
                  ref="input" 
                  :id="type.payment_type" 
                  :placeholder="$t(paymentTypeTitles[type.payment_type].name)"
                  class="keyboard-input"
                  autocomplete="off"
                  @input="input"
                  @focus="setFocus(type.payment_type)"
                />
                </el-col>
              </el-row>
            </template>
            <template v-else>
              <input
                v-model="type.amount"
                ref="input" 
                :id="type.payment_type" 
                :placeholder="$t(paymentTypeTitles[type.payment_type].name)"
                class="keyboard-input"
                autocomplete="off"
                @input="input"
                @focus="setFocus(type.payment_type)"
              />
            </template>
          </template>
        </div>

        <div class="keyboard-nombeer">
          <el-button @click="append(1)">1</el-button>
          <el-button @click="append(2)">2</el-button>
          <el-button @click="append(3)">3</el-button>
          <el-button @click="append(4)">4</el-button>
          <el-button @click="append(5)">5</el-button>
          <el-button @click="append(6)">6</el-button>
          <el-button @click="append(7)">7</el-button>
          <el-button @click="append(8)">8</el-button>
          <el-button @click="append(9)">9</el-button>
          <el-button @click="append('dot')">.</el-button>
          <el-button @click="append(0)">0</el-button>
          <el-button @click="append('splice')"
            ><i class="el-icon-house"></i
          ></el-button>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="dfJ">
      <el-col :span="19">
        <div class="itme-bottom-keyboard">
          <el-button
            :class="activeButton === 'cash' ? 'activeButton' : ''"
            @click="changePaymentType('cash')"
            >{{ $t("cash") }}
          </el-button>
          <el-button
            :class="activeButton === 'card' ? 'activeButton' : ''"
            @click="changePaymentType('card')">
            <template v-if="(Object.keys(paymentTypeSubName).length === 0)">
              {{ $t("card") }}
            </template>
            <template v-else-if="(Object.keys(paymentTypeSubName).length > 0) && (paymentTypeSubName.type == 'card')">
              {{ paymentTypeSubName.name }}
            </template>
            <template v-else>
              {{ $t("card") }}
            </template>
          </el-button>
          <el-button
            :class="activeButton === 'combination' ? 'activeButton' : ''"           
            @click="changePaymentType('combination')"
          >
            {{ $t("combination") }}
          </el-button>
          <el-button           
            :class="activeButton === 'online' ? 'activeButton' : ''"
            @click="changePaymentType('online')"
            >
            <template v-if="(Object.keys(paymentTypeSubName).length === 0)">
              {{ $t("Online") }}
            </template>
            <template v-else-if="(Object.keys(paymentTypeSubName).length > 0) && (paymentTypeSubName.type == 'online')">
              {{ paymentTypeSubName.name }}
            </template>
            <template v-else>
              {{ $t("Online") }}
            </template>
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="dfJ">
      <el-col :span="19">
        <div class="itme-bottom-keyboard">
          <el-button type="success" @click="submit">{{ $t("pay") }}</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="modal-center currency x-right-top">
      <el-dialog
        :visible="currencyModal"
        width="50%"
        :center="true"
        @close="currencyModal = false"
      >

          <el-radio-group  v-model="currentCurrency"
            value-key="name"
            @change="changeCurrency" class="currency-my-khan">
            <el-radio-button
            v-for="currency in currencies"
            :key="currency.id"
            :label="currency"
            :value="currency">{{ currency.name }}</el-radio-button>
          </el-radio-group>
        <div class="buttonSModal">
          <el-button type="success" @click="currencyModal = false"
            >{{ $t("save") }}
          </el-button>
        </div>
      </el-dialog>
    </div>

    <div class="modal-center currency x-right-top typeCarts-modal">
      <el-dialog
        :visible="paymentTypeSubVisibleOnline"
        width="50%"
        :center="true"
        @close="paymentTypeSubVisibleOnline = false" 
        @closed="afterPaymentTypeSubClosed()"
      >
        <div v-loading="paymentTypeSubLoading">
            <el-radio-group v-model="paymentTypeSub">
              <el-radio-button v-for="(item, index) in paymentTypeSubsOnline" :key="index + '-payment-type-sub'" :label="item.key">
                <div v-if="(item.key == 'click')" class="img-typeCarts"><img src="../../public/img/Click-01.png" alt=""/></div>
                <div v-else-if="(item.key == 'payme')" class="img-typeCarts"><img src="../../public/img/payme-01.png" alt=""/></div>
                <div v-else-if="(item.key == 'uzum_bank')"  class="img-typeCarts"><img src="../../public/img/UZUM_BANK-01.png" alt="" /></div>
              </el-radio-button>
            </el-radio-group>
        </div>
      </el-dialog>

      <el-dialog
        :visible="paymentTypeSubVisibleCard"
        width="50%"
        :center="true"
        @close="paymentTypeSubVisibleCard = false" 
        @closed="afterPaymentTypeSubClosed()"
      >
        <div v-loading="paymentTypeSubLoading">
            <el-radio-group v-model="paymentTypeSub">
              <el-radio-button v-for="(item, index) in paymentTypeSubsCard" :key="index + '-payment-type-sub'" :label="item.key">
                <div v-if="(item.key == 'humo')" class="img-typeCarts"><img style="transform: scale(0.6);" src="../../public/img/humo.png" alt=""/></div>
                <div v-else-if="(item.key == 'uzcard')" class="img-typeCarts"><img style="transform: scale(0.6);" src="../../public/img/uzcard.png" alt=""/></div>
              </el-radio-button>
            </el-radio-group>
        </div>
      </el-dialog>
    </div>

    <promocode-modal
      :open="promocodeModal"
      @modal:close="promocodeModal = $event"
      @setPromocodeName="(name) => promocodeName = name"
    />

    <withdrawal-balance
      :open="withdrawalBalance"
      :currency="currentCurrency"
      :amountFromBalance="amountFromBalance"
      @modal:close="withdrawalBalance = $event"
      @setFromBalance="setFromBalance"
    />
  </div>
</template>

<script>
import Total from "../utils/Total";
import auth from "../utils/auth";
import OrderApi from "../api/Order";
import OrderItem from "../models/OrderItem";
import PromocodeModal from "./includeForKeyboard/promocode-modal";
import withdrawalBalance from "./includeForKeyboard/withdrawal-balance";
import { mapGetters, mapActions } from "vuex";
import { filter, flatMap, forEach, round } from "lodash";

export default {
  components: {
    PromocodeModal,
    withdrawalBalance
  },

  data() {
    return {
      radio1:  "",
      transaction: {
        currency_id: 1,
        rate: 1,
        payment_types: [
          { 
            payment_type: "cash",
            amount: ""
          }
        ]
      },
      paymentTypeTitles: {
        cash: {
          name: "cash"
        },
        card: {
          name: "card"
        },
        online: {
          name: "online"
        },
        from_balance: {
          name: "from_balance"
        }
      },
      activeButton: "cash",
      values: [],
      focusedInput: null,
      currentCurrency: "",
      currencySymbol: "",
      currencyModal: false,
      promocodeModal: false,
      paymentTypeSubVisibleOnline: false,
      paymentTypeSubVisibleCard: false,
      paymentTypeSub: null,
      paymentTypeSubLoading: false,
      paymentTypeSubName: {},
      withdrawalBalance: false,
      amountFromBalance: 0,
      promocodeName: null
    };
  },

  computed: {
    ...mapGetters({
      products: "cart/getProducts",
      currencies: "cart/getCurrency",
      client: "order/getClient",
      paymentTypeSubsOnline: 'paymentTypeSubs/paymentTypeSubsOnline',
      paymentTypeSubsCard: 'paymentTypeSubs/paymentTypeSubsCard'
    }),

    total() {
      const total = Total.totalCost(this.products, this.currentCurrency.rate, this.amountFromBalance);
      return total;
    }
  },

  watch: {
    paymentTypeSub: {
      handler(newVal) {
        if (newVal) {
          if (this.transaction.payment_types.length == 1) {
            if (this.transaction.payment_types[0].payment_type == 'online') {
              this.paymentTypeSubName.name = (_.find(this.paymentTypeSubsOnline, { 'key': newVal })).name;
              this.transaction.payment_types[0].payment_type_sub = newVal;
              this.paymentTypeSubName.type = 'online';
              this.paymentTypeSubVisibleOnline = false;
            } else if(this.transaction.payment_types[0].payment_type == 'card') {
              this.paymentTypeSubName.name = (_.find(this.paymentTypeSubsCard, { 'key': newVal })).name;
              this.transaction.payment_types[0].payment_type_sub = newVal;
              this.paymentTypeSubName.type = 'card';
              this.paymentTypeSubVisibleCard = false;
            }
          } else if(this.transaction.payment_types.length == 2) {
            this.paymentTypeSubName.name = (_.find(this.paymentTypeSubsCard, { 'key': newVal })).name;
            this.transaction.payment_types[1].payment_type_sub = newVal;
            this.paymentTypeSubName.type = 'combination';
            this.paymentTypeSubVisibleCard = false;
          }
        }
      }
    },
    focusedInput: {
      immediate: true,
      handler(newVal) {
        if (this.focusedInput !== newVal) {
          this.values = [];
        }
      }
    },
    client: {
      immediate: true,
      handler(newVal) {
        this.amountFromBalance = 0;
      }
    },
    total: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // TODO:: soxranit qilgandan keyin shotta xatolik beradi
          this.transaction.payment_types[0].amount = this.formatNumber(
            String(newVal.totalCostPrice)
          );
        }
      }
    },
    currencies: {
      immediate: true,
      handler(newVal) {
        const activeCurrency = filter(newVal, "active")[0];
        if (activeCurrency) {
          this.currentCurrency = activeCurrency.name;
          this.currencySymbol = activeCurrency.symbol;
          this.transaction.rate = activeCurrency.rate;
          this.transaction.currency_id = activeCurrency.id;
        }
      }
    },
    currentCurrency: {
      handler(newVal) {
        this.transaction.rate = newVal.rate;
        this.transaction.currency_id = newVal.id;
      }
    }
  },

  methods: {
    ...mapActions({
		  getPaymentTypeSubOnline: "paymentTypeSubs/getPaymentTypeSubOnline",
      getPaymentTypeSubCard: "paymentTypeSubs/getPaymentTypeSubCard",
      emptyPaymentTypeSub: "paymentTypeSubs/emptyPaymentTypeSub"
    }),
    setFromBalance(fromBalance) {
      this.amountFromBalance = fromBalance;
    },
    changePage(page = "parent") {
      this.$emit("c-change", {
          page: page,
      });
    },
    formatNumber(value) {
      if (value.indexOf(".") >= 0) {
        let decimal_pos = value.indexOf(".");

        let left_side = value.substring(0, decimal_pos);
        let right_side = value.substring(decimal_pos);

        left_side = left_side
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        right_side = right_side
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        if (right_side.length > 2) {
          if (left_side.charAt(0) === "0") {
            left_side = left_side.slice(1) + right_side.slice(-3, -2);
          } else {
            left_side += right_side.slice(-3, -2);
          }
          left_side.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          right_side = right_side.slice(-2);
        }
        value = left_side + "." + right_side;
      } else {
        value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
      return value;
    },

    input(e) {
      const activeInput = filter(this.transaction.payment_types, [
        "payment_type",
        this.focusedInput
      ])[0];
      activeInput.amount = this.formatNumber(e.target.value);
    },

    setFocus(type) {
      this.focusedInput = type;
    },

    selectCard() {
      this.fetchPaymentTypeSubCard(); 
    },

    append(number) {
      const activeInput = filter(this.transaction.payment_types, [
        "payment_type",
        this.focusedInput
      ])[0];
      if (
        typeof number === "number" ||
        number === "dot" ||
        number === "splice"
      ) {
        switch (number) {
          case "splice":
            activeInput.amount = activeInput.amount.substring(
              0,
              activeInput.amount.length - 1
            );
            break;
          case "dot":
            if (!activeInput.amount.includes(".")) {
              activeInput.amount += ".";
            }
            break;
          default:
            const value = (activeInput.amount += number);
            activeInput.amount = this.formatNumber(value);
            break;
        }
      }
    },
    async changePaymentType(type) {
      switch (type) {
        case "cash":
          this.setPaymentTypeSubNameEmpty();
          this.activeButton = type;
          this.focusedInput = type;

          this.transaction.payment_types = [
            {
              payment_type: type,
              amount: this.formatNumber(String(this.total.totalCostPrice))
            }
          ];
          break;
        case "card":
          this.activeButton = type;
          this.focusedInput = type;
          this.fetchPaymentTypeSubCard(); 
          this.transaction.payment_types = [
            {
              payment_type: type,
              amount: this.formatNumber(String(this.total.totalCostPrice))
            }
          ];
          break;
        case "combination":
          this.setPaymentTypeSubNameEmpty();
          this.activeButton = type;
          this.transaction.payment_types = [
            {
              payment_type: "cash",
              amount: ""
            },
            {
              payment_type: "card",
              amount: ""
            }
          ];
          break;
        case "online":
          this.activeButton = type;
          this.focusedInput = type;
          this.fetchPaymentTypeSubOnline(); 

          this.transaction.payment_types = [
            {
              payment_type: type,
              amount: this.formatNumber(String(this.total.totalCostPrice))
            }
          ];
        break;
      }
    },

    submit() {
      const paymentMessages = {
        cash: {
          message: this.$t("surrender")
        },
        card: {
          message: this.$t("paymentMessageCart")
        }
      };
      let message;
      const transactions = this.transaction;
      transactions.payment_types = flatMap(
        transactions.payment_types,
        payment_type => {
          return {
            ...payment_type,
            amount:
              payment_type.amount !== 0
                ? Number(payment_type.amount.toString().replace(/ /gi, ""))
                : 0
          };
        }
      );
      
      let totalPaymentSumm = 0;
      forEach(transactions.payment_types, function(item) {
        totalPaymentSumm += item.amount;
      });

      const includeCashType = flatMap(
          transactions.payment_types,
          "payment_type"
        );

      if (includeCashType.length > 1) {
        message = paymentMessages["cash"].message;
      } else {
        if (includeCashType[0] != 'online') {
          message = paymentMessages[includeCashType].message;
        }
      }

      // sdacha summasi 0 dan kichik bolsa 0 qilib olish
      const deliveryPrice = round(
          (totalPaymentSumm - this.total.totalCostPrice < 0) ? 0 : totalPaymentSumm - this.total.totalCostPrice,
          2
        );

      if ((includeCashType.length == 1) && (includeCashType[0] == 'online')) {
        message = this.$t("Do you confirm?");
      } else {
        message = message + "  " + deliveryPrice + "  " + this.currencySymbol;
      }

      this.$confirm("", message, {
        confirmButtonClass: "success",
        confirmButtonText: this.$t("save"),
        cancelButtonText: this.$t("cancel"),
        center: true
      })
        .then(async () => {
          if (
            transactions.payment_types[0].payment_type === "cash" &&
            !transactions.payment_types[1] &&
            transactions.payment_types[0].amount > this.total.totalCostPrice
          ) {
            transactions.payment_types[0].amount = this.total.totalCostPrice;
          }
          const loading = this.$loading({
            customClass: "login-loading",
            lock: true,
            text: "Загрузка",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });

          const order = await this.$store.getters["order/getOrder"];
          order.items = OrderItem.rawResources(this.products);
          order.transactions = JSON.parse(JSON.stringify(transactions));

          if (this.amountFromBalance > 0) {
              order.transactions.payment_types.push({
                payment_type: "from_balance",
                amount: this.amountFromBalance
              })
          }

          await OrderApi.createOrderWithTransaction(order)
            .then(async response => {
              if (response.data.result.success) {
                const createResponse = response;
                this.$confirm(this.$t("printMessage"), {
                  confirmButtonClass: "success",
                  confirmButtonText: this.$t("yes"),
                  cancelButtonText: this.$t("no"),
                  center: true
                })
                  .then(async () => {
                    const data = {
                      retail_order_id:
                        createResponse.data.result.data.retailOrder.id,
                      is_with_transaction: true,
                      change: deliveryPrice
                    };
                    await OrderApi.getCashBox(data, true)
                      .then(async response => {
                        loading.close();
                        await auth.removeClientData();
                        await this.$store.dispatch("cart/clear");
                        await this.$store.dispatch("order/getCount");
                        await this.$store.dispatch("order/clear");
                        this.$message({
                          type: "success",
                          message: createResponse.data.result.message,
                          showClose: true
                          // position: "bottom-right",
                        });
                        const WinPrint = window.open(
                          "",
                          "",
                          "left=0,top=0,toolbar=0,scrollbars=0,status=0"
                        );
                        WinPrint.document.write(response.data);
                        WinPrint.document.close();
                        WinPrint.focus();
                        setTimeout(() => {
                          WinPrint.print();
                          WinPrint.close();
                        }, 1000);
                        this.$emit("c-change", {
                          page: "parent",
                        });
                      })
                      .catch(err => {
                        loading.close();
                      });
                  })
                  .catch(async err => {
                    loading.close();
                    await auth.removeClientData();
                    await this.$store.dispatch("cart/clear");
                    await this.$store.dispatch("order/getCount");
                    await this.$store.dispatch("order/clear");
                    this.$message({
                      type: "success",
                      message: createResponse.data.result.message,
                      showClose: true
                      // position: "bottom-right",
                    });
                    this.$emit("c-change", {
                      page: "parent",
                    });
                  });
              }
            })
            .catch(err => {
              loading.close();
              if (err.response && err.response.data.error.code === 422) {
                let ms = "";
                for (const key in err.response.data.validation_errors) {
                  if (
                    err.response.data.validation_errors.hasOwnProperty(key)
                  ) {
                    const element = err.response.data.validation_errors[key];
                    ms += element.join("<br>") + "<br>";
                  }
                }
                this.$message({
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                  title: this.$t("warning"),
                  message: ms,
                  duration: 15000,
                  showClose: true
                });
              } else {
                this.$message({
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                  message: err.message,
                  showClose: true
                });
              }
            });
        })
        .catch(err => {
          // loading.close();
        });
    },

    fetchPaymentTypeSubCard() {
      this.paymentTypeSubVisibleCard = true;
      if (this.paymentTypeSubsCard && this.paymentTypeSubsCard.length === 0) {
        this.paymentTypeSubLoading = true;
        this.getPaymentTypeSubCard().then(res => {
          this.paymentTypeSubLoading = false;
        })
        .catch(err => {
          this.paymentTypeSubLoading = false;
        });
      }
    },

    fetchPaymentTypeSubOnline() {
      this.paymentTypeSubVisibleOnline = true;
      if (this.paymentTypeSubsOnline && this.paymentTypeSubsOnline.length === 0) {
        this.paymentTypeSubLoading = true;
        this.getPaymentTypeSubOnline().then(res => {
          this.paymentTypeSubLoading = false;
        })
        .catch(err => {
          this.paymentTypeSubLoading = false;
        });
      }
    },

    setPaymentTypeSubNameEmpty() {
      this.paymentTypeSubName = {};
    },

    afterPaymentTypeSubClosed() {
      this.paymentTypeSub = null;
    },

    changeCurrency(e) {
      this.currencyModal = false;
      this.transaction.rate = e.rate;
      this.transaction.currency_id = e.id;
      this.currencySymbol = e.symbol;
    },

    openPromocodeModal() {
      this.promocodeModal = true;
    },

    openWithdrawalBalance() {
      this.withdrawalBalance = true;
    }
  },
  mounted() {
    this.setFocus("cash");
  }
};
</script>

<style>
.keyboard-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 6.5vw;
  font-size: 3vw;
  text-align: right;
  border-radius: 0px;
}

.keyboard-input:hover {
  border-color: #c0c4cc;
}

.itme-bottom-keyboard-custom .el-button {
  flex: 1;
  width: 100%;
  height: 6.5vw;
  margin-top: 0;
  font-size: 1.3vw;
}
</style>
