<template>
  <div>
    <div>
      <el-form ref="form" :model="form" label-width="120px">
          <el-form-item :label="$t('quantity')" class="quantity">
            <el-input-number v-model="form.quantity" @change="changeQuantity" :min="0" :max="total_all_warehouses_remainder"></el-input-number>
          </el-form-item>

          <el-form-item :label="$t('price')" class="price">
            <price-input v-model="form.price"></price-input>
          </el-form-item>

          <el-form-item :label="$t('discount')" class="discount">
            <div class="d-flex">
              <el-radio-group v-model="form.discount_type"
                value-key="name" class="discount_type currency-my-khan">
                <el-radio-button label="sum">{{ $t('total') }}</el-radio-button>
                <el-radio-button label="percent">%</el-radio-button>
              </el-radio-group>
              <price-input v-if="form.discount_type == 'sum'" v-model="form.discount_sum" @change="changeDiscountSum" class="discount_sum"></price-input>
              <el-input-number v-else-if="form.discount_type == 'percent'" v-model="form.discount_percent" @change="changeDiscountPercent" class="discount_percent" :min="0" :max="100"></el-input-number>
            </div>
          </el-form-item>

          <div v-loading="is_loading" class="div-table-warehouses">
            <div v-if="!warehouses">
              <p class="notify_title">
                Склады по продукту {{ (item && item.product) ? item.product.name : '' }} не найден
              </p>
            </div>
            <table class="table table-warehouses table-striped table-hover modal-table-p">
              <thead>
                <tr>
                  <th class="th-checkbox">
                    <label class="form-checkbox">
                      <input v-model="selectAll" type="checkbox" @click="select" />
                      <i class="form-icon"></i>
                    </label>
                  </th>
                  <th class="th-warehouse">{{ $t('warehouse') }}</th>
                  <th class="th-remainder">{{ $t('remainder') }}</th>
                  <th class="th-quantity">{{ $t('quantity') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(warehouse, warehouse_index) in warehouses" :key="warehouse.id">
                  <td>
                    <label class="form-checkbox">
                      <input
                        v-model="selected"
                        type="checkbox"
                        :value="warehouse.id"
                        :disabled="warehouse.total_remainder === 0"
                        size="medium"
                      />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>{{ warehouse.name }}</td>
                  <td>{{ warehouse.total_remainder }}</td>
                  <td class="quantity">
                    <el-input-number
                      v-model="warehouse.quantity"
                      @change="changeWarehouseQuantity($event, warehouse_index)"
                      :min="0"
                      :max="warehouse.total_remainder">
                    </el-input-number>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <el-form-item :label="$t('note')" class="note">
            <el-input
              v-model="form.note"
              type="textarea"
              :rows="2">
            </el-input>
          </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="d-flex buttonSModal">
      <el-button @click="submit()" type="primary">Сохранить</el-button>
    </span>
  </div>
</template>
<script>

import VueTypes from "vue-types";
import { filter, flatMap, includes, find, sumBy, round } from "lodash";
import PriceInput from "@/components/global/crm-price-input"
import Warehouse from "@/api/Warehouse";
import auth from "@/utils/auth";

export default {
  props: ['item'],
  components: {
    PriceInput
  },
  data() {
    return {
      is_loading: false,
      selected: [],
      selectAll: false,
      warehouses: [],

      form: {
          quantity: 0,
          price: 0,
          discount_type: 'percent',
          discount_percent: 0,
          discount_sum: 0,
          note: '',
      },
    }
  },
  computed: {
    total_all_warehouses_remainder() {
      if (this.selected.length) {
        const warehouses = filter(this.warehouses, warehouse =>
            includes(this.selected, warehouse.id)
          );

        return sumBy(warehouses, "total_remainder");
      }
      else {
        return 0;
      }
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.warehouses.length > 0 && this.warehouses.length === newVal.length) {
          this.selectAll = true;
        } else {
          this.selectAll = false;
        }

        let warehouses = filter(this.warehouses, warehouse =>
          includes(newVal, warehouse.id)
        );
        this.form.quantity = sumBy(warehouses, "quantity");
      }
    }
  },
  methods: {
    afterOpen() {
      if (this.item) {
        this.form.quantity = this.item.quantity;
        this.form.price = this.item.price;
        this.form.discount_type = this.item.discount_type;
        this.form.discount_percent = this.item.discount;
        this.form.discount_sum = this.item.discount_sum;
        this.form.note = this.item.note;
      }

      this.getWarehouses();
    },
    submit() {
      if (this.selected.length) {
        const warehouses = filter(this.warehouses, warehouse =>
            includes(this.selected, warehouse.id)
          ),
          product_id = this.item.product.id;

        const data = {
          product_id,
          warehouses,
          form: this.form
        };
        return this.$emit("change-cart-item", data);
      } else {
        this.$notify({
          type: "error",
          message: this.$t('Warehouse not selected'),
          showClose: true
        });
      }
    },
    getWarehouses() {
      this.warehouses = [];
      this.is_loading = true;

      const clientShop = auth.getShop();
      Warehouse.getWarehouses({
        product_id: this.item ? this.item.product.id : null,
        shop_id: clientShop,
        barcode: this.item ? this.item.barcode : '',
      })
        .then(res => {
          const { result } = res.data;

          result.data.warehouses.forEach(item => {
            let quantity = 0;
            let old_selected_warehouse = find(this.item ? this.item.warehouses : [], ['id', item.id]);
            if (old_selected_warehouse) {
              if (old_selected_warehouse.quantity) {
                quantity = old_selected_warehouse.quantity;
              }
              else {
                quantity = this.item.quantity;
              }
            }

            this.warehouses.push({
              id: item.id,
              name: item.name,
              total_remainder: parseFloat(item.total_remainder),
              quantity: quantity,
            });

            this.selectDefaults();
          });

          // skladlani olib kelgandan keyin "total_all_warehouses_remainder" > 0 boladi. ungacha form.quantity = 0 bolib turadi
          this.form.quantity = this.item.quantity;

          this.is_loading = false;
          return result.data.warehouses;
        })
        .catch(err => {
          this.is_loading = false;
        });
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.warehouses) {
          if (this.warehouses[i].total_remainder > 0) {
            this.selected.push(this.warehouses[i].id);
          }
        }
      }
    },
    selectDefaults() {
      this.selected = [];
      const warehouseIds = flatMap(this.item ? this.item.warehouses : [], "id");
      this.selected = warehouseIds;
    },
    changeQuantity(val) {
      if (this.warehouses.length == 1) {
        this.warehouses.forEach(item => {
          // select qilingan skladni qty sini ozgartirish
          if (includes(this.selected, item.id)) {
            item.quantity = val;

            return false;
          }
        });
      }
      // 2 ta yoki undan kop sklad bolsa skladlarni ostatkasigacha ozgartirish
      else if (this.warehouses.length > 1) {
        this.warehouses.forEach(item => {
          if (includes(this.selected, item.id)) {
            let need_qty = val > item.total_remainder ? item.total_remainder : val;

            item.quantity = need_qty;

            val -= need_qty;

            if (val <= 0) {
              return false;
            }
          }
        });
      }
    },
    changeWarehouseQuantity(val, warehouse_index) {
      let warehouses = filter(this.warehouses, warehouse =>
        includes(this.selected, warehouse.id)
      );
      this.form.quantity = sumBy(warehouses, "quantity");
    },
    changeDiscountSum(val) {
      if (this.form.price > 0) {
        this.form.discount_percent = round(val * 100 / this.form.price, 2);
      }
      else {
        this.form.discount_percent = 0;
      }
    },
    changeDiscountPercent(val) {
      this.form.discount_sum = round(val * this.form.price / 100, 2);
    },
    afterLeave() {
      this.clearData();
    },
    clearData() {
      this.is_loading = false;
      this.selected = [];
      this.selectAll = false;
      this.warehouses = [];
      this.$refs['form'].resetFields();
    },
  },
}
</script>
