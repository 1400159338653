import { filter, find } from "lodash";

export function CREATE_ORDER(state, payload) {
  // Order.newOrder qilib yuborganda eski malumotla bolmidi. eski malumotla state da boladi. 
  // shuning uchun state dan olinib qayta biriktirib qoyilvotti  
  let promocode_id = state.order.promocode_id ? state.order.promocode_id : null;
  let consultant_id = state.order.consultant_id ? state.order.consultant_id : null;

  state.order = payload;
  state.order.promocode_id = promocode_id;
  state.order.consultant_id = consultant_id;
}

export function SAVE_COUNT(state, payload) {
  state.orderCount = payload;
}

export function SET_CLIENT_ID(state, payload) {
  state.order.client_id = payload.id;
  state.client = payload;
}

export function SET_COMMENT(state, comment) {
  state.order.comment = comment;
}

export function SET_PROMOCODE_ID(state, promocode_id) {  
  state.order.promocode_id = promocode_id;
}

export function SET_CONSULTANT(state, consultant) {  
  state.order.consultant_id = consultant ? consultant.id : null;
  state.order.consultant_name = consultant ? consultant.name : null;
}

export function CLEAR(state) {
  state.order = {};
  state.client = {};
}
