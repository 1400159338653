import { getProductRemainder } from '@/api/warehouses';

export const actions = {
  getProductRemainder({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      getProductRemainder(params).then(res => {
          commit('SET_PRODUCTS_REMAINDER', res.data.result.data);
          resolve(res.data.result.data)
        }).catch(err => {
          reject(err.response.data)
        })
    })
  },

  add({ commit }, payload) {
    commit('SET_WAREHOUSES', payload);
  },

  clear({ commit }) {
    commit('CLEAR_WAREHOUSES');
  }
}