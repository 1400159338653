<template>
  <el-drawer
    title="I am the title"
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    append-to-body
    direction="rtl"
    size="100%"
    @open="loadData"
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="clents-blocks" v-loading="loading">
      <div class="title-modal">
        <p class="flex1">{{ $t("Exchange") }}</p>
        <el-button
          v-if="globalCheckHasPermission('exchangeProducts.create')"
          type="success"
          style="margin-right: 1vw"
          :loading="waiting"
          @click="submit()"
        >
          {{ $t("save") }}
        </el-button>
        <el-button
          type="danger" plain
          style="margin-right: 1vw"
          @click="$emit('drawer:close', false)"
        >
          {{ $t("close") }}
        </el-button>
      </div>

      <el-main>
        <el-row>
          <el-col>
            <el-button
              type="primary"
              @click="openModalForSelectReason()"
              plain
            >
              {{ $t("reason") }}
            </el-button>
          </el-col>
        </el-row>

        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>{{ $t('Product of revert') }}</th>
              <th>{{ $t('Shipped') }}</th>
              <th>{{ $t('Qty of return') }}</th>
              <th>{{ $t('measurement') }}</th>
              <th>{{ $t('price') }}</th>
              <th>{{ $t('totalPrice') }}</th>
              <th>{{ $t('Product of exchange') }}</th>
              <th>{{ $t('Quantity of exchange') }}</th>
              <th>{{ $t('measurement') }}</th>
              <th>{{ $t('price') }}</th>
              <th>{{ $t('totalPrice') }}</th>
              <th>{{ $t('delete') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.retail_order_item_id + '-retail_order_items'">
              <td>{{ item.product_name }}</td>
              <td>{{ item.shipped_qty | formatNumber }}</td>
              <td>
                <el-input-number
                  v-model="item.return_quantity"
                  :min="0"
                  :max="item.shipped_qty"
                ></el-input-number>
              </td>
              <td>{{ item.measurement_name }}</td>
              <td>{{ item.price | formatMoney(2) }}</td>
              <td>{{ (item.price * item.return_quantity) | formatMoney(2) }}</td>
              <td>
                <el-button type="success" @click="openExchangeProduct(item)">{{ item.exchangeProductInfo && item.exchangeProductInfo.exchangeProduct ? item.exchangeProductInfo.exchangeProduct.name : $t('Product of exchange') }}</el-button>
              </td>
              <td>
                <el-input-number
                  v-if="(item.exchangeProductInfo && item.exchangeProductInfo.exchangeProduct)"
                  v-model="item.exchangeProductInfo.exchange_quantity"
                  @change="exchangeQuantityChanged()"
                  :min="0"
                  :max="item.exchangeProductInfo.maxExchangeQty"
                ></el-input-number>
                <el-input-number v-else disabled></el-input-number>
              </td>
              <td>{{ item.exchangeProductInfo && item.exchangeProductInfo.exchangeProduct ? item.exchangeProductInfo.exchangeProduct.measurement_name : '' }}</td>
              <td>{{ (item.exchangeProductInfo ? item.exchangeProductInfo.price : 0) | formatMoney(2) }}</td>
              <td>{{ ((item.exchangeProductInfo ? (item.exchangeProductInfo.price - item.exchangeProductInfo.discount_amount) : 0) * (item.exchangeProductInfo ? item.exchangeProductInfo.exchange_quantity : 0)) | formatMoney(2) }}</td>
              <td>
                <el-button type="danger" icon="el-icon-delete" circle/>
              </td>
            </tr>
          </tbody>
        </table>

        <el-descriptions style="margin-top: 40px;" :title="$t('Final calculation')" direction="vertical" :column="4" border>
          <el-descriptions-item :label="$t('Total price of reverted products')">{{ totalRevertPrice }}</el-descriptions-item>
          <el-descriptions-item :label="$t('Total price of exchange products')">{{ totalExchangePrice }}</el-descriptions-item>
          <el-descriptions-item :label="$t('debt')">{{ Math.abs(parseFloat(totalExchangePrice) - parseFloat(totalRevertPrice)) }}</el-descriptions-item>
          <el-descriptions-item :label="$t('Debtor')">{{ (totalExchangePrice > totalRevertPrice) ? $t('client') : (totalExchangePrice < totalRevertPrice) ? $t('Shop') : '' }}</el-descriptions-item>
        </el-descriptions>
      </el-main>
    </div>

    <select-reason
      :open="selectReasonDrawer"
      :old_reason_id="reason_id"
      @setReasonId="setReasonId"
      @drawer:close="selectReasonDrawer = false"
      ref="selectReason"
      />

    <div class="modal-center currency x-right-top">
      <el-dialog
        :visible="paymentForRevertPriceAskModal"
        width="40%"
        append-to-body
        :center="true"
        @close="paymentForRevertPriceAskModal = false"
      >
        <span slot="title">
          <h2 style="color: black">
            {{ $t(((debit < 0) ? 'You owe the client sum' : 'The client owes your sum'), { 'total_price': diffenerce_of_exchange, 'currency_symbol': global_currency_symbol }) }}
          </h2>
        </span>
        <div>
          <el-row>
            <el-col :span="11">
              <el-button class="types-button" type="primary" @click="openPaymentForRevertPrice()">
                {{ $t("pay") }}
              </el-button>
            </el-col>
            <el-col :offset="2" :span="11">
              <el-button class="types-button" type="warning" @click="$emit('drawer:close', false)">
                {{ $t("cancel") }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>

    <PaymentOfExchange
      :open="paymentForRevertPriceDrawer"
      :order="order"
      :amount="diffenerce_of_exchange"
      :debit="debit"
      @drawer:close="closePaymentForRevertPriceDrawer"
      />

    <div class="correct-product-modal modal-center x-right-top praduct-info">
      <el-dialog
          :visible.sync="exchangeProductDialogVisible"
          width="40%" 
          append-to-body
          @opened="dialogOpened('exchangeProductDialogChild')"
          @closed="dialogClosed('exchangeProductDialogChild')"
          center>
          <ExchangeProduct :returnItem="selectedItem" @setExchangeProductInfo="setExchangeProductInfo" ref="exchangeProductDialogChild"></ExchangeProduct>
      </el-dialog>
    </div>
  </el-drawer>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import PriceInput from "@/components/global/crm-price-input"
  import SelectReason from "./include/select-reason";
  import PaymentOfExchange from "./include/PaymentOfExchange";
  import ExchangeProduct from "./include/ExchangeProduct";
  import dialog from '@/utils/mixins/dialog-util';
  import OrderApi from "@/api/Order";

export default {
  mixins: [dialog],
  props: ['open', 'order'],
  components: { PriceInput, SelectReason, PaymentOfExchange, ExchangeProduct },

  data() {
    return {
      items: [],
      loading: false,
      reason_id: null,
      selectReasonDrawer: false,
      waiting: false,
      diffenerce_of_exchange: 0,
      paymentForRevertPriceAskModal: false,
      paymentForRevertPriceDrawer: false,
      exchangeProductDialogVisible: false,
      selectedItem: null,
      debit: -1,
      totalExchangePrice: 0
    }
  },

  computed: {
      ...mapGetters({
        shippedProductsForReturn: "orders/shippedProductsForReturn",
      }),

      totalRevertPrice() {
        return _.sumBy(this.items, function(o) { return (o.return_quantity * o.price); });
      },
  },

  methods: {
    ...mapActions({
				getShippedProductsForReturn: 'orders/getShippedProductsForReturn',
        revertAndExchange: 'exchangeProducts/revertAndExchange',
    }),

    exchangeQuantityChanged() {
      this.totalExchangePrice = _.sumBy(this.items, function(o) { return (o.exchangeProductInfo && o.exchangeProductInfo.exchange_quantity && o.exchangeProductInfo.price) ? ((o.exchangeProductInfo.exchange_quantity) * (o.exchangeProductInfo.price - o.exchangeProductInfo.discount_amount)) : 0; });
    },

    async loadData() {
      this.setDefaultValueData();

      if (!this.loading && this.order) {
        this.loading = true;
        this.getShippedProductsForReturn(this.order.id)
            .then(res => {
              this.items = JSON.parse(JSON.stringify(this.shippedProductsForReturn));

              _.forEach(this.items, function(value) {
                value.exchangeProductInfo = {
                    exchange_product_id: null,
                    exchange_quantity: 0,
                    maxExchangeQty: 0,
                    barcode: null,
                    price: 0,
                    discount_type: 'percent',
                    discount_amount: 0,
                    discount: 0,
                    warehouses: [],
                    exchangeProduct: null
                };
              });
              this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.$alert(err)
            });
      }
    },

    openExchangeProduct(item) {
      this.exchangeProductDialogVisible = true;
      this.selectedItem = item;
    },

    afterOpen() {

    },

    setExchangeProductInfo: function(exchangeProductInfo) {
      this.exchangeProductDialogVisible = false;
      (_.find(this.items, { 'retail_order_item_id': exchangeProductInfo.retail_order_item_id })).exchangeProductInfo = exchangeProductInfo;
      this.exchangeQuantityChanged();
    },

    submit() {
      if (!this.waiting) {
        const form = this.getFilteredForm();

        this.waiting = true;
        this.revertAndExchange(form)
            .then(res => {
                this.$alert(res);
                this.waiting = false;

                this.debit = ((form.total_returned_products_price - form.total_exchanged_products_price) > 0 ? -1 : 1)
                this.diffenerce_of_exchange = Math.abs(form.total_returned_products_price - form.total_exchanged_products_price);
                if (this.diffenerce_of_exchange > 0) {
                  this.paymentForRevertPriceAskModal = true;
                }
                else {
                  this.$confirm(this.$t("printMessage"), {
                      confirmButtonClass: "success",
                      confirmButtonText: this.$t("yes"),
                      cancelButtonText: this.$t("no"),
                      center: true
                  })
                    .then(async () => {
                        const data = {
                            retail_order_id: this.order ? this.order.id : null,
                            is_with_transaction: true,
                            change: 0
                        };
                        await OrderApi.getCashBox(data, true)
                          .then(async response => {
                              const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                              WinPrint.document.write(response.data);
                              WinPrint.document.close();
                              WinPrint.focus();
                              setTimeout(() => {
                                WinPrint.print();
                                WinPrint.close();
                              }, 1000);                            
                          })
                          .catch(err => {
                            
                          });
                    })
                    .catch(async err => {
                        
                    });

                  return this.$emit("drawer:close", false);
                }
            })
            .catch(err => {
                this.waiting = false;
                this.$alert(err)
            })
      }
    },

    getFilteredForm() {
      let filtered_items = _.filter(this.items, function(o) {
          return ((parseFloat(o.return_quantity) > 0) && o.exchangeProductInfo && o.exchangeProductInfo.exchangeProduct && (o.exchangeProductInfo.exchange_quantity > 0));
      });

      return {
          branch_id: this.order ? this.order.branch_id : null,
          retail_order_id: this.order ? this.order.id : null,
          reason_id: this.reason_id,
          total_returned_products_price: _.sumBy(filtered_items, function(o) { return (o.return_quantity * o.price); }),
          total_exchanged_products_price: _.sumBy(filtered_items, function(o) { return (o.exchangeProductInfo.exchange_quantity * (o.exchangeProductInfo.price - o.exchangeProductInfo.discount_amount)); }),
          returning_products: _.map(filtered_items, function(item) {
              return {
                retail_order_item_id: item.retail_order_item_id,
                return_product_id: item.product_id,
                return_product_price: item.price,
                return_quantity: parseFloat(item.return_quantity),
                product_id: item.exchangeProductInfo.exchangeProduct.id,
                barcode: item.exchangeProductInfo.barcode,
                exchange_quantity: item.exchangeProductInfo.exchange_quantity,
                exchange_product_price: item.exchangeProductInfo.price,
                discount_type: item.exchangeProductInfo.discount_type,
                discount_amount: item.exchangeProductInfo.discount_amount,
                discount: item.exchangeProductInfo.discount,
                warehouses: _.map(_.filter(item.exchangeProductInfo.warehouses, function(n) { return (n.total_remainder > 0) && (n.exchange_quantity > 0); }), function(wh) {
                  return {
                    id: wh.id,
                    exchange_quantity: wh.exchange_quantity
                  }
                }),
              }
          })
        }
    },

    openPaymentForRevertPrice() {
      this.paymentForRevertPriceAskModal = false;
      this.paymentForRevertPriceDrawer = true;
    },

    closePaymentForRevertPriceDrawer() {
      this.paymentForRevertPriceDrawer = false;
      this.$emit("drawer:close", false);
    },

    openModalForSelectReason() {
      this.selectReasonDrawer = true;
    },

    setReasonId(reason_id) {
      this.reason_id = reason_id;
    },

    handleClose(done) {
      done();
      return this.$emit("drawer:close", false);
    },

    afterLeave() {
      this.setDefaultValueData();
    },

    setDefaultValueData() {
      this.items = [];
      this.reason_id = null;
      this.diffenerce_of_exchange = 0;
      this.paymentForRevertPriceAskModal = false;
      this.paymentForRevertPriceDrawer = false;
    }
  },
}
</script>

<style scoped lang="scss">
.drawer {
  overflow-y: scroll;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  color: black;
}
.table th {
  border-bottom-width: 0.1rem;
}

.table td,
.table th {
  border-bottom: 0.05rem solid #dadee4;
  padding: 0.6rem 0.4rem;
}
.table.table-hover tbody tr:hover {
  background: #eef0f3;
}
.types-button {
  width: 100%;
  line-height: 2;
  font-size: 23px;
}
</style>

