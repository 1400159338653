import { inventory } from '@/api/reasons';

export const actions = {    
    inventory({ commit }) {
        return new Promise((resolve, reject) => {
            inventory().then(res => {
                commit("SET_INVENTORY", res.data.result.data.reasons);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
}
