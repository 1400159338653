import { inventory } from '@/api/setting/sources';

export const actions = {    
  inventory({ commit }, params) {
        return new Promise((resolve, reject) => {
          inventory(params).then(res => {                
                commit("SET_INVENTORY", res.data.result.data);
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
}
