import auth from "../utils/auth";

class Order {
  static reverseResource(order) {
    return {
      retail_order_id: order.id ? order.id : null,
      is_with_nds: order.is_with_nds ? order.is_with_nds : false,
      branch_id: order.branch ? order.branch.id : null,
      shop_id: order.shop ? order.shop.id : auth.getShop(),
      client_id: order.client ? order.client.id : auth.getClientId(),
      comment: order.comment ? order.comment : '',
      promocode_id: order.promocode ? order.promocode.id : null,
      consultant_id: order.consultant ? order.consultant.id : null,
      consultant_name: order.consultant ? order.consultant.name : null,
    };
  }

  static newOrder() {
    let default_branch_id = localStorage.getItem('default_branch_id');
    default_branch_id = default_branch_id === null ? null : parseInt(default_branch_id);

    return {
      retail_order_id: null,
      is_with_nds: false,
      branch_id: default_branch_id,
      shop_id: auth.getShop(),
      client_id: auth.getClientId(),
      comment: '',
      promocode_id: null,
      consultant_id: null,
      consultant_name: null,
    };
  }
}

export default Order;
