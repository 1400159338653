<template>
  <el-drawer
    title="I am the title"
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    append-to-body
    direction="rtl"
    size="50%"
    @open="loadData"
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="clents-blocks" v-loading="loading">
      <div class="title-modal">
        <p class="flex1">{{ $t("Select reason") }}</p>
        <el-button          
          type="success"
          style="margin-right: 1vw"
          @click="submit()"
        >
          {{ $t("save") }}
        </el-button>
        <el-button
          type="danger" plain
          style="margin-right: 1vw"
          @click="$emit('drawer:close', false)"
        >
          {{ $t("close") }}
        </el-button>
      </div>

      <el-main>
        <el-row>
          <el-col :span="24" v-for="(reason, index) in reasons" :key="index + '-reasons'" class="reason_button_div nee-ww">
            <el-radio v-model="reason_id" :label="reason.id">{{ reason.name }}</el-radio>
          </el-col>
        </el-row>
      </el-main>
    </div>

  </el-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ['open', 'old_reason_id'],
  data() {
    return {      
      reason_id: null,
      loading: false,

    }
  },
  computed: {
    ...mapGetters({
      reasons: "reasons/inventory",
    })
  },
  methods: {
    ...mapActions({
			loadReasonsInventory: "reasons/inventory",
    }),

    async loadData() {
      if (_.size(this.reasons) == 0) await this.loadReasonsInventory();
      this.reason_id = this.old_reason_id;
    },
    
    afterOpen() {
      
    },
    
    submit() {
      this.$emit("setReasonId", this.reason_id);
      this.$emit("drawer:close", false);
    },
    handleClose(done) {
      done();
      return this.$emit("drawer:close", false);
    },
    afterLeave() {
      this.reason_id = null;
    }
  }
}
</script>

<style scoped>
.reason_button_div {
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  padding: 12px 20px 0 10px;
  box-sizing: border-box;
  height: 40px;
}
</style>
