import OrderApi from "../../../api/Order";

export async function create({ commit }, payload) {
  commit("CREATE_ORDER", payload);
}

export async function getCount({ commit }) {
  const quantity = (await OrderApi.getOrdersQuantity()).data.result.data
    .countRetailOrders;
  commit("SAVE_COUNT", quantity);
}

export function getClosedRetailOrders({ commit }, params = {}) {
  return new Promise((resolve, reject) => {
    OrderApi.getClosedRetailOrders(params).then(res => {          
          // commit("SET_LIST", res.data.result.data.retailOrders);          
          // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
          resolve(res.data.result)
      }).catch(err => {
          reject(err.response.data)
      })
  })
}

export function getShippedProductsOfRetailOrder({ commit }, retail_order_id) {
  return new Promise((resolve, reject) => {
    OrderApi.getShippedProductsOfRetailOrder(retail_order_id).then(res => {
          resolve(res.data.result)
      }).catch(err => {
          reject(err.response.data)
      })
  })
}

export function returnBack({ commit }, data) {
  return new Promise((resolve, reject) => {
    OrderApi.returnBack(data).then(res => {
          resolve(res.data.result)
      }).catch(err => {
          reject(err.response.data)
      })
  })
}

export function createReturnTransaction({ commit }, data) {
  return new Promise((resolve, reject) => {
    OrderApi.createReturnTransaction(data).then(res => {
          resolve(res.data.result)
      }).catch(err => {
          reject(err.response.data)
      })
  })
}

export function removeItem({ commit }, payload) {
  commit("REMOVE_ORDER_ITEM", payload);
}

export function setClientID({ commit }, id) {
  commit("SET_CLIENT_ID", id);
}

export function setComment({ commit }, comment) {
  commit("SET_COMMENT", comment);
}

export function setPromocodeId({ commit }, promocode_id) {
  commit("SET_PROMOCODE_ID", promocode_id);
}

export function setConsultant({ commit }, consultant) {
  commit("SET_CONSULTANT", consultant);
}

export function clear({ commit }) {
  commit("CLEAR");
}
