import request from "../utils/request";

class product {
  static async getProducts() {
    return await request.get("/products/inventory");
  }

  static async getProductViaQuery(data = {}) {
    return await request.get("/products/inventory", { params: data });
  }

  static async getProductByCode(data) {
    return await request.get("/products/getProductByCode", { params: data });
  }
}

export default product;
