import { mapGetters, mapActions } from 'vuex';
import paymentTypes from '@/includes/fields/selects/crm-payment-type';
import priceInput from "@/components/global/crm-price-input";
import costTypes from '@/includes/fields/selects/crm-cost';
import currencies from '@/includes/fields/selects/crm-currency';

export default {
    components: {
        paymentTypes,
        priceInput,
        costTypes,
        currencies
    },

    data() {
        return {
            form: {}
        }
    },

    computed: {
        ...mapGetters({
            model: "costs/model",
            rules: "costs/rules",
            currencies: 'currencies/inventory'
        })
    },

    methods: {
        ...mapActions({
            createCostTransaction: 'costs/createCostTransaction',
            updateInventory: 'currencies/inventory',
            getCostTransactionHistoryByRetail: 'costs/getCostTransactionHistoryByRetail',
            getCashoutFromLoyaltyCardHistory: 'costs/getCashoutFromLoyaltyCardHistory'
        }),

        setDefaultCurrency() {
            const active_currency = _.find(this.currencies, 'active');
            if (active_currency) {
                this.form.currency = active_currency;
                this.form.currency_id = active_currency.id;
                this.form.rate = active_currency.rate;
            }
        },

        updateCurrency() {
            const currency = _.find(this.currencies, { 'id': this.form.currency_id });
            if (currency) {
                this.form.currency = currency;
                this.form.rate = currency.reverse ? currency.reversed_rate : currency.rate;
            }
        },
    }
}