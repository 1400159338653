import { sumBy, ceil } from "lodash";

const Total = {
  totalRemainderPercentage: products => {
    products = products.map(product => {
      const { total_remainder, total_receive } = product;
      let percentage = Number(
        ((total_remainder / total_receive) * 100).toFixed(2)
      );
      if (isNaN(percentage)) {
        percentage = 0;
      }
      return {
        ...product,
        totalPercentage: percentage
      };
    });
    return products;
  },

  // per  product
  totalPrices: item => {
    const { quantity, price, discount_type, discount, discount_sum } = item;
       
    let total = 0,
        discount_price = 0;

    // Skidka protsentta kiritilsayam summasi hisoblangan boladi. oshani olish
    total = (price - discount_sum) * quantity;
    discount_price = discount_sum * quantity;
    
    return {
      total: total,
      discountPrice: discount_price
    };
  },

  // all products
  totalCost: (products, rate = 1, amountFromBalance = 0) => {
    products.map(product => Total.totalPrices(product));
    return {
      totalCostPrice: ceil(
        parseFloat(((sumBy(products, "total.total") / rate) - amountFromBalance)),
        2
      ),
      totalCostDiscount: ceil(
        parseFloat(sumBy(products, "total.discountPrice") / rate),
        2
      ),
      totalCostElements: parseFloat(sumBy(products, "quantity"))
    };
  }
};

export default Total;
