import { inventory } from '@/api/paymentTypes';

export const actions = {
  inventory({ commit }) {
    return new Promise((resolve, reject) => {
      inventory().then(res => {
            resolve(res.data.result)
            commit("SET_INVENTORY", res.data.result.data.paymentTypes);
        }).catch(err => {
            reject(err.response.data)
        })
    })
  }
}
