import request from '@/utils/request'

export function getActivePromocodes() {
  return request({
      url: '/promocodes/getActivePromocodes',
      method: 'get'
  })
}

export function checkPromocodeIsActive(data) {
  return request({
      url: '/promocodes/checkPromocodeIsActive',
      method: 'post',
      data
  })
}

export function search(params) {
  return request({
      url: '/promocodes/search',
      method: 'get',
      params
  })
}
