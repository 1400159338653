<template>
  <div>
    <el-tabs type="card">
      <el-tab-pane :label="$t('Cost')">
          <el-card v-loading="loading">
              <el-form ref="costTransactionForm" :model="form" :rules="rules" label-width="20px" size="large" label-position="top">
                  <el-row :gutter="20">
                      <el-col :span="8">
                        <template v-if="currentPaymentTypeKey != 'from_balance'">
                          <cost-types v-model="form.transactionable_id"
                              :cost_id="form.transactionable_id" :size="'large'" :prop="'transactionable_id'"></cost-types>
                        </template>

                        <payment-types v-model="form.payment_type_id"
                              :payment_type_id="form.payment_type_id" :size="'large'"></payment-types>

                        <el-form-item v-if="currentPaymentTypeKey == 'from_balance'">
                          <el-row>
                            <el-input
                            ref="searchInput"
                            :placeholder="$t('Search client by card number')"
                            suffix-icon="el-icon-search"
                            v-model="search"
                            @keydown.enter.native="onEnter"
                          ></el-input>
                          </el-row>
                          <el-row>
                            <el-col>
                              <span><b>{{ $t('client') }}:</b> {{ client ? client.client_name : '' }}</span>
                            </el-col>
                            <el-col>
                              <span><b>{{ $t('Card balance') }}:</b> {{ (client ? client.card_balance : '') | formatMoney(2) }}</span>
                            </el-col>
                          </el-row>
                        </el-form-item>
                      </el-col>
                      
                      <el-col :span="8">
                          <template v-if="currentPaymentTypeKey == 'from_balance'">
                            <el-form-item :label="$t('Amount')" prop="amount">
                                <price-input style="width: 100%;" v-model="form.amount" :max="String(maxBalance)"></price-input>
                            </el-form-item>
                          </template>
                          <template v-else>
                            <el-form-item :label="$t('Amount')" prop="amount">
                                <price-input style="width: 100%;" v-model="form.amount"></price-input>
                            </el-form-item>
                          </template>

                          <currencies v-model="form.currency_id" :currency_id="form.currency_id"
                              @c-change="updateCurrency()" :size="'large'"></currencies>

                          <el-form-item v-if="form.currency && !form.currency.active" :label="$t('rate')" prop="rate">
                              <el-input style="width: 100%;" v-model="form.rate" type="number"></el-input>
                          </el-form-item>
                      </el-col>

                      <el-col :span="8">
                          <el-form-item :label="$t('datetime')"  prop="datetime">
                              <el-date-picker 
                                style="width: 100%;" 
                                prefix-icon="el-icon-date" 
                                v-model="form.datetime" 
                                type="datetime"
                                format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm">
                              </el-date-picker>
                          </el-form-item>
                          <el-form-item :label="$t('comment')" prop="comment">
                              <el-input type="textarea" size="mini" v-model="form.comment"
                                  :placeholder="$t('comment')" clearable :rows="2"></el-input>
                          </el-form-item>
                      </el-col>
                  </el-row>

                  <el-row>
                    <el-col style="display: grid; place-content: center;">
                      <el-button @click="save()" type="success">{{ $t('save') }}</el-button>
                    </el-col>
                  </el-row>
              </el-form>
          </el-card>
      </el-tab-pane>
      <el-tab-pane :label="$t('History')"><cost-transaction-history></cost-transaction-history></el-tab-pane>
      <el-tab-pane :label="$t('History of cashout from client card')"><cashout-from-loyalty-card-history></cashout-from-loyalty-card-history></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import auth from '@/utils/auth';
  import dialog from '@/utils/mixins/dialog';
  import costs from '@/utils/mixins/models/costs';
  import costTransactionHistory from "./costTransactionHistory";
  import cashoutFromLoyaltyCardHistory from "./cashoutFromLoyaltyCardHistory";
  import { debounce } from "lodash";

  export default {  
    mixins: [costs, dialog],
    components: {
      costTransactionHistory,
      cashoutFromLoyaltyCardHistory
    },

    data() {
      return {
          search: ''
        }
    },

    created() {
      this.debouncedFindClient = debounce(this.fetchClient, 1000);
    },

    computed: {
      ...mapGetters({
        paymentTypes: 'paymentTypes/inventory',
        client: 'clients/client'
      }),

      currentPaymentTypeKey() {
        let self = this;
        return ((this.paymentTypes && (this.paymentTypes.length > 0) && self.form.payment_type_id) ? (_.find(self.paymentTypes, function(o) { return o.id == self.form.payment_type_id; })).key : '');
      },

      maxBalance() {
        return ((this.client && this.client.card_balance) ? this.client.card_balance : 0);
      }
    },

    watch: {
      currentPaymentTypeKey: {
        handler(newVal) {
          if (newVal == 'from_balance') {
            setTimeout(() => {
              this.$refs.searchInput.focus();
            }, 200);
          }
        }
      },

      search: {
        handler(newVal) {
          if (newVal) {
            this.debouncedFindClient();
          }
        }
      }
    },

    methods: {
      ...mapActions({
        getClientByCardNumber: "clients/getClientByCardNumber",
        emptyClient: "clients/emptyClient"
      }),

      fetchClient() {
        this.loading = true;
        this.getClientByCardNumber({card_number: this.search})
            .then(res => {
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.$alert(err)
            });
      },

      async afterOpen() {
        if (this.currencies && this.currencies.length === 0) {
         await this.updateInventory()
        } 

        this.form = JSON.parse(JSON.stringify(this.model));
        this.setDefaultCurrency();
        if (_.isFunction(this.$refs['costTransactionForm'].resetFields)) {
            this.$refs['costTransactionForm'].resetFields()
        }
      },

      onEnter(event) {
        event.preventDefault();
      },

      save() {
        if (!this.loading) {
            this.$refs['costTransactionForm'].validate((valid) => {
                if (valid) {    
                  this.loading = true;
                  this.form = _.omit(this.form, 'currency');

                  if (this.currentPaymentTypeKey == 'from_balance') {
                    this.form.card_id = this.client.card_id;
                  }
                  this.form.payment_type_key = this.currentPaymentTypeKey;

                  let default_branch_id_inlocal_storage = localStorage.getItem('default_branch_id');
                  this.form.branch_id = default_branch_id_inlocal_storage === null ? null : parseInt(default_branch_id_inlocal_storage);
                  this.form.shop_id = auth.getShop();
                  this.createCostTransaction(this.form)
                      .then(res => {  
                          this.close();
                          this.getCashoutFromLoyaltyCardHistory(auth.getShop());
                          this.getCostTransactionHistoryByRetail(auth.getShop());
                          this.loading = false;
                      })
                      .catch(err => {
                          this.loading = false;
                          this.$alert(err)
                      });
                  }
            });
        }
      },

      afterLeave() {
        this.form = JSON.parse(JSON.stringify(this.model));
        this.emptyClient();
      }
    }
  }
</script>
