import { getParentCategories, getChildrenCategories, getProductsAndModelOfCategory } from '@/api/category';

export const actions = {    
  getParentCategories({ commit }) {
        return new Promise((resolve, reject) => {
          getParentCategories().then(res => {                
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getChildrenCategories({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        getChildrenCategories(params).then(res => {
              resolve(res.data.result)
          }).catch(err => {
              reject(err.response.data)
          })
      })
    },

    getProductsAndModelOfCategory({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        getProductsAndModelOfCategory(params).then(res => {
              resolve(res.data.result)
          }).catch(err => {
              reject(err.response.data)
          })
      })
    },
}
