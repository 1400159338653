import { getShippedProductsForReturn } from '@/api/orders';

export const actions = {
  getShippedProductsForReturn({ commit }, retail_order_id) {
    return new Promise((resolve, reject) => {
      getShippedProductsForReturn(retail_order_id).then(res => {
            resolve(res.data.result.data);
            commit("SET_SHIPPED_PRODUCTS", res.data.result.data.shippedProductsForReturn);
        }).catch(err => {
            reject(err.response.data)
        })
    })
  }
}
