<template>
  <div>
    <el-table
      :data="cashoutFromLoyaltyCardHistory"
      border
      style="width: 100%">
      <el-table-column
        prop="id"
        :label="$t('n')"
        width="100">
      </el-table-column>
      <el-table-column
        prop="client_name"
        :label="$t('client')">
      </el-table-column>
      <el-table-column
        prop="card_number"
        :label="$t('Card number')">
      </el-table-column>
      <el-table-column
        prop="cashout_amount"
        :label="$t('Cashout amount')">
      </el-table-column>
      <el-table-column
        prop="currency_name"
        :label="$t('currency')">
      </el-table-column>
      <el-table-column
        prop="datetime"
        :label="$t('datetime')">
      </el-table-column>
      <el-table-column
        prop="comment"
        :label="$t('comment')">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import auth from '@/utils/auth';

  export default {  
    mounted() {
      this.getCashoutFromLoyaltyCardHistory(auth.getShop());
    },

    computed: {
        ...mapGetters({
          cashoutFromLoyaltyCardHistory: "costs/cashoutFromLoyaltyCardHistory"
        })
    },

    methods: {
        ...mapActions({
          getCashoutFromLoyaltyCardHistory: 'costs/getCashoutFromLoyaltyCardHistory'
        })
    }
  }
</script>