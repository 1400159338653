export default {
    methods: {
        dialogOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].afterOpen)) {
                    this.$refs[ref].afterOpen()
                }
            }
        },
        dialogClosed(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].afterLeave)) {
                    this.$refs[ref].afterLeave()
                }
            }
        }
    }
}