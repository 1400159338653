<template>
  <div class="right_blocks">
    <div class="right-top-tab">
      <el-tabs
        type="card"
        v-model="activeTab"
        @tab-click="getOrders"
        class="tabb"
      >
        <el-tab-pane name="cart">
          <div slot="label" class="right-top-icon">
            <i class="el-icon-shopping-bag-1"></i>
            <div>{{ $t("basket") }}</div>
          </div>
          <!-- content-block-tabl -->

          <div class="content-block-tabl">
            <div class="itme-colom">
              <!-- <div  v-dragscroll.y="true">
                <el-collapse v-model="active" accordion>
                  <el-collapse-item
                    v-for="(item, index) in products"
                    :key="item.product.id"
                    :name="item.product.id"
                    :ref="item.product.id"
                    :id="item.product.id"
                  >
                    <template slot="title">
                      <el-row class="title-collapse">
                        <el-col :span="10">
                          <v-clamp :max-lines="2">
                            {{ item.product.name }}
                          </v-clamp>
                        </el-col>
                        <el-col :span="2">{{ item.quantity }}</el-col>
                        <el-col :span="6">{{ item.price }}</el-col>
                        <el-col :span="6">{{ item.total.total }}</el-col>
                      </el-row>
                    </template>
                    <cart-item
                      :item="item"
                      :permissions="{
                        delete: deleteItem
                      }"
                      @emitComment="setComment"
                      @emitDiscount="setDiscount"
                      @emitWarehouses="setWarehouses"
                      @emitQuantity="changeQuantity($event, index)"
                      @emitPrice="changePrice($event, index)"
                      @removeProduct="removeProduct($event, index)"
                    />
                  </el-collapse-item>
                </el-collapse>
              </div> -->

              <!-- start itme-prast-new -->
              <div
                  v-for="(item, index) in products"
                  :key="'card-products-' + index">
                <div class="itme-prast-new" >
                  <div class="name-p-new" @click="openDialogToEditCardItem(item)">
                    <b>{{ item.product.name }} </b>
                    <span v-if="item.product && item.product.color_code" :style="{ backgroundColor: item.product.color_code }"> {{ item.product.size_name }} </span>
                  </div>
                  <div class="name-qty-new">
                    <el-input-number @change="changeQuantity($event, item, index)" v-model="item.quantity" :min="0" :max="item.total_remainder"></el-input-number>
                  </div>
                  <div class="name-price-new">
                    <span>{{ item.price | formatNumber(2) }}</span>
                    <b>{{ global_currency_symbol }}</b>
                  </div>
                  <div class="name-total-new">
                    <span>{{ item.total.total | formatNumber(2) }}</span>
                    <b>{{ global_currency_symbol }}</b>
                  </div>
                  <div class="name-delet-new">
                    <el-button @click="removeProduct(item, index)" type="danger" icon="el-icon-delete" circle></el-button>
                  </div>
                </div>
              </div>
              <!-- end itme-prast-new -->
            </div>

              <el-button
                  class="ocslit"
                  :disabled="!hasProducts"
                  type="danger"
                  @click="clear"
                  icon="el-icon-circle-close"
                >
                  <!-- {{ $t("clear") }} -->
              </el-button>

            <div class="itme-prast">
              <div class="payments">
                <el-row>
                  <el-col :span="24">
                    <div class="itme-payments new-itme-payments font12">
                      <span>{{ $t("totalPrice") }}</span>
                      <b>{{ totalCost.totalCostPrice | formatMoney }}</b>
                    </div>

                    <div class="itme-payments new-itme-payments">
                      <span> {{ $t("elements") }}</span>
                      <b>{{ totalCost.totalCostElements }}</b>
                    </div>

                    <div class="itme-payments new-itme-payments" @click="setDiscount('all')">
                      <span> <i class="el-icon-back"></i> {{ $t("discount") }}</span>
                      <b class="cs-a"> {{ totalCost.totalCostDiscount | formatMoney }} </b>
                    </div>

                    <div class="itme-payments new-itme-payments"  @click="openConsultantDrawer()">
                      <span :class="consultant_name ? 'success' : ''">
                        <i class="el-icon-back"></i> {{ $t("Consultant") }}
                      </span>
                      <b class="cs-a" :class="consultant_name ? 'success' : ''">
                        {{ consultant_name ? consultant_name : $t("Not selected") }}
                      </b>
                    </div>

                    <div class="itme-payments new-itme-payments" @click="clientDrawer.open = true">
                      <span :class="client ? 'success' : ''">
                        <i class="el-icon-back"></i> {{ $t("client") }}
                      </span>
                      <b class="cs-a" :class="client ? 'success' : ''">
                        {{ client ? client : $t("changeClient") }}
                      </b>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div class="itme-bottom bottonpadding">
                <el-button
                  v-if="page == 'payment'"
                  type="success"
                  @click="changePage('parent')"
                >
                  {{ $t("home") }}
                </el-button>
                <el-button
                  v-else
                  v-can="createTransaction"
                  :disabled="!hasProducts"
                  type="success"
                  @click="changePage('payment')"
                >
                  {{ $t("pay") }}
                </el-button>
                <el-button
                  type="warning"
                  @click="costCreateDialog = true"
                >
                  {{ $t("Cost") }}
                </el-button>
                <el-button
                  v-if="globalCheckHasPermission('retailOrders.revert')"
                  type="warning"
                  @click="openReturnDrawer()"
                >
                  {{ $t("Return") }}
                </el-button>
                <el-button
                  v-can="create_update"
                  :disabled="!hasProducts"
                  type="primary"
                  @click="saveOrder"
                  >{{ $t("postponement") }}
                </el-button>
                <el-button
                  @click="print"
                  >
                  {{ $t("print") }}
                </el-button>
              </div>
            </div>
            <!--  -->
          </div>
          <!-- content-block-tabl -->
        </el-tab-pane>
        <el-tab-pane name="order" @tab-click="getOrders">
          <div slot="label" class="right-top-icon">
            <i class="el-icon-tickets"></i>
            <span class="son">{{ currentOrdersQuantity }}</span>
            <div>{{ $t("applications") }}</div>
          </div>
          <!-- content-block-tabl -->
          <div class="content-block-tabl">
            <order-modal :orders="orders" @change:order="getOrderItems" />
          </div>
          <!-- content-block-tabl -->
        </el-tab-pane>
      </el-tabs>
    </div>

    <clients-drawer
      :open="clientDrawer.open"
      @drawer:close="clientDrawer.open = $event"
      @change:client="saveClient"
    />
    <!-- end clents -->

    <warehouses-drawer
      :open="warehouseDrawer.open"
      :item="warehouseDrawer.item"
      @drawer:close="warehouseDrawer.open = $event"
      @change:warehouse="saveWarehouse"
    />
    <!-- end warehouse -->

    <discount-modal
      :open="discountModal.open"
      :discount="discountModal.discount"
      :product-id="discountModal.id"
      @modal:close="discountModal.open = $event"
      @change:discount="saveDiscount"
    />
    <!-- end discountModal -->
    <comment-modal
      :open="commentModal.open"
      :note="commentModal.note"
      :product-id="commentModal.id"
      @modal:close="commentModal.open = $event"
      @change:note="saveNote"
    />
    <!-- end commentModal -->

    <comment-for-order-modal
      :open="commentForOrderModal.open"
      :comment="commentForOrderModal.comment"
      @modal:close="commentForOrderModal.open = $event"
      @change:commentForOrder="saveCommentForOrder"
    />

    <orders-for-return
      :open="ordersForReturnDrawer"
      @drawer:close="ordersForReturnDrawer = $event"
    />

    <consultant-drawer
      :open="consultantDrawer"
      @drawer:close="consultantDrawer = $event"
      @setConsultantToOrder="setConsultantToOrder"
    />

    <div class="correct-product-modal modal-center x-right-top praduct-info">
      <el-dialog
          :title="$t('Correct') + ' ' + ((selectedItem && selectedItem.product) ? selectedItem.product.name : '')"
          :visible.sync="editCardItemDialogVisible"
          width="40%"
          @opened="dialogOpened('dialogCorrectCartItemChild')"
          @closed="dialogClosed('dialogCorrectCartItemChild')"
          center>
          <correct-cart-item :item="selectedItem" @change-cart-item="changeCartItem" ref="dialogCorrectCartItemChild"></correct-cart-item>
      </el-dialog>
    </div>

    <div class="modal-center x-right-top">
      <el-dialog
          :title="$t('Costs')"
          :visible.sync="costCreateDialog"
          width="70%"
          @opened="dialogOpened('costCreateDialogChild')"
          @closed="dialogClosed('costCreateDialogChild')"
          center>
          <cost-create :item="selectedItem" ref="costCreateDialogChild" @close-dialog="costCreateDialog = false"></cost-create>
      </el-dialog>
    </div>

  </div>
</template>
<script>
  import { sumBy, map, includes } from "lodash";
  import { mapGetters, mapActions } from "vuex";
  import OrderApi from "../api/Order";
  import Order from "../models/Order";
  import auth from "../utils/auth";
  import Total from "../utils/Total";
  import OrderItem from "../models/OrderItem";
  import OrderModal from "./OrderModal";
  import DiscountModal from "./DiscountModal";
  import CommentModal from "./CommentModal";
  import CommentForOrderModal from "./CommentForOrderModal";
  import ClientsDrawer from "./ClientsDrawer";
  import WarehousesDrawer from "./WarehousesDrawer";
  // import CartItem from "./CartItem";
  import OrdersForReturn from "./includeForReturn/orders";
  import ConsultantDrawer from "./includeForHeaderRight/consultantDrawer";
  import VueTypes from "vue-types";
  import CorrectCartItem from "./includeForHeaderRight/correct-cart-item";
  import costCreate from "./costs/costCreate";
  import dialog from '@/utils/mixins/dialog-util';
  import i18n from "@/plugins/i18n";

export default {
  mixins: [dialog],

  props: {
    ordersQuantity: VueTypes.number,
    page: VueTypes.string,
  },

  data() {
    return {
      editCardItemDialogVisible: false,
      warehouseDrawer: {
        open: false,
        item: {}
      },
      clientDrawer: {
        open: false
      },
      discountModal: {
        open: false,
        id: 0,
        discount: 0
      },
      commentModal: {
        note: "",
        open: false,
        id: 0
      },
      commentForOrderModal: {
        comment: "",
        open: false,
      },
      create_update: false,
      deleteItem: false,
      createTransaction: false,
      active: 1,
      activeTab: "cart",
      client: null,
      user: null,
      orderId: 0,
      orders: {},
      totalCost: null,
      currentOrdersQuantity: 0,
      ordersForReturnDrawer: false,
      consultantDrawer: false,
      selectedItem: null,
      costCreateDialog: false
    };
  },

  components: {
    OrderModal,
    WarehousesDrawer,
    ClientsDrawer,
    CommentModal,
    CommentForOrderModal,
    DiscountModal,
    // CartItem,
    OrdersForReturn,
    ConsultantDrawer,
    CorrectCartItem,
    costCreate
  },

  computed: {
    ...mapGetters({
      hasProducts: "cart/hasProducts",
      quantity: "cart/getQuantity",
      activeItem: "cart/getActiveItem",
      hasEmptyWarehouses: "cart/hasEmptyWarehouse",
      ordersCount: "order/getOrderCount",
      order: "order/getOrder",
      permissions: "auth/getPermissions",
      settingRetail: 'settingRetail/setting',
    }),

    products() {
      let products = this.$store.getters["cart/getProducts"];
      products = products.map(item => {
        item.total_remainder = sumBy(item.warehouses, "total_remainder");
        item.total = Total.totalPrices(item);
        return { ...item };
      });

      this.totalCost = Total.totalCost(products);
      this.client = auth.getClientName();

      return products;
    },

    consultant_name() {
      let order_data = this.$store.getters["order/getOrder"];
      return order_data ? order_data.consultant_name : null;
    }
  },

  created() {
    if (_.isEmpty(this.settingRetail)) {this.getSettingRetail()} 
    this.user = auth.getName();
  },

  watch: {
    products: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.activeTab === "order") {
          this.activeTab = "cart";
        }
      }
    },
    permissions: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (this.order.retail_order_id) {
            this.create_update = includes(
              map(this.permissions, "slug"),
              "retailOrders.create"
            );
          } else {
            this.create_update = includes(
              map(this.permissions, "slug"),
              "retailOrders.update"
            );
          }
          this.deleteItem = includes(
            map(this.permissions, "slug"),
            "retailOrders.deleteItem"
          );
          this.createTransaction = includes(
            map(this.permissions, "slug"),
            "retailOrders.createTransaction"
          );
        }
      }
    },

    activeItem: {
      immediate: true,
      handler(newVal) {
        this.active = newVal;
      }
    },

    ordersQuantity: {
      immediate: true,
      handler(newVal) {
        this.currentOrdersQuantity = newVal;
      }
    },
    ordersCount: {
      immediate: true,
      handler(newVal) {
        this.currentOrdersQuantity = newVal;
      }
    }
  },

  methods: {
    ...mapActions({
      getSettingRetail: 'settingRetail/getSettingRetail',
    }),

    changePage(page) {
      if (page === 'payment') {
        if (!_.isEmpty(this.settingRetail)) {
          if ((this.settingRetail.is_client_required_at_payment === true) && !this.client) {
            this.$notify({
              title: i18n.t('warning'),
              message: i18n.t('Client info is required at payment'),
              type: 'warning'
            });
          } else {
            this.$emit("c-change", page);
          }
        } else {
          this.getSettingRetail().then(res => {
              if ((this.settingRetail.is_client_required_at_payment === true) && !this.client) {
                this.$notify({
                  title: i18n.t('warning'),
                  message: i18n.t('Client info is required at payment'),
                  type: 'warning'
                });
              } else {
                this.$emit("c-change", page);
              }
            })
            .catch(err => {
              
            });
        }
      } else {
        this.$emit("c-change", page);
      }
    },

    setDiscount(e) {
      this.discountModal.open = true;
      this.discountModal.id = e.id ? e.id : e;
      this.discountModal.discount = e.discount ? e.discount : 0;
    },

    setComment(e) {
      this.commentModal.open = true;
      this.commentModal.id = e.id;
      this.commentModal.note = e.note;
    },

    setCommentForOrder() {
      this.commentForOrderModal.open = true;
      this.commentForOrderModal.comment = this.order ? this.order.comment : '';
    },

    setWarehouses(item) {
      this.warehouseDrawer = {
        open: true,
        item
      };
    },

    saveDiscount(e) {
      if (e.id === "all") {
        // onshiy skidka berilsa hamma itemni discount_type ni 'percent' qilish
        this.discountModal.open = e.open;
        if (e.discount || e.discount === 0) {
          map(this.products, ({ product }) => {
            e.id = product.id;
            this.$store.dispatch("cart/changeDiscount", e);
          });
        }
      } else {
        this.discountModal.open = e.open;
        if (e.discount || e.discount === 0) {
          this.$store.dispatch("cart/changeDiscount", e);
        }
      }
    },

    saveNote(e) {
      this.commentModal.open = e.open;
      this.$store.dispatch("cart/changeNote", e);
    },

    saveCommentForOrder(e) {
      this.commentForOrderModal.open = e.open;
      this.$store.dispatch("order/setComment", e.comment);
    },

    saveClient(e) {
      if (e) {
        auth.setClientData(e);
        this.$store.dispatch("order/setClientID", e);
        return (this.client = e.name);
      }
    },

    saveWarehouse(e) {
      const { warehouses, id } = e;
      this.warehouseDrawer.open = e.open;
      const quantityByProductId = this.quantity(id);
      const totalRemainder = sumBy(warehouses, "total_remainder");
      let newQuantity = parseFloat(sumBy(warehouses, "quantity"));

      // korzinkadigi qty ni, sklad tanlashda korsatgan kolichestvalani yigindisiga, yoki maximum ostatkasiga ozgartish
      if (totalRemainder < quantityByProductId && totalRemainder < totalQuantity) {
        newQuantity = totalRemainder;
      }

      this.$store.dispatch("cart/changeQuantity", {
        id,
        quantity: newQuantity
      });

      this.$store.dispatch("cart/changeWarehouse", {
        ...e
      });
    },

    async changeQuantity(val, item, index) {
      if (val === 0) {
        await this.removeProduct(item, index);
      }
      else {
        this.$store.dispatch("cart/changeQuantity", {
          id: (item && item.product) ? item.product.id : null,
          quantity: val
        });
      }
    },

    async changePrice(e, index) {
      this.$store.dispatch("cart/changePrice", {
        id: e.id,
        price: e.value
      });
    },

    removeProduct(e, index) {
      this.$confirm(this.$t("deleteProduct"), {
        confirmButtonText: this.$t("delete"),
        cancelButtonText: this.$t("cancel"),
        confirmButtonClass: "danger",
        showClose: true,
        center: true
      })
        .then(async () => {
          if (e.id) {
            await OrderApi.deleteOrderItem(e.id)
              .then(async res => {
                if (res.status === 200) {
                  await this.$store.dispatch("cart/remove", index);
                  return this.$cNotify.displayMessage(
                    res.data.result.message,
                    "success"
                  );
                }
              })
              .catch(err => {
                return this.$cNotify.displayMessage(err.message, "warning", {
                  dangerouslyUseHTMLString: true
                });
              });
          } else {
            await this.$store.dispatch("cart/remove", index);
            return this.$cNotify.displayMessage("Удалено.", "success");
          }
        })
        .catch(() => {});
    },
    clear() {
      this.$confirm(this.$t("clearCart"), {
        confirmButtonText: this.$t("clear"),
        cancelButtonText: this.$t("cancel"),
        confirmButtonClass: "danger",
        showClose: true,
        center: true
      })
        .then(() => {
          this.$store.dispatch("cart/clear");
          this.$store.dispatch("order/clear");
          auth.removeClientData();
          this.client = "";
          return this.$message({
            type: "success",
            message: "Очищено",
            showClose: true
          });
        })
        .catch(() => {});
    },
    saveOrder() {
      if (this.hasEmptyWarehouses) {
        this.$cNotify.displayNotify(
          "У вас есть пустые склады в продукте.",
          "error"
        );
      } else {
        const loading = this.$loading({
          customClass: "login-loading",
          lock: true,
          text: "Загрузка",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        let is_loading = true;
        this.$confirm(this.$t("savePostPonement"), {
          confirmButtonText: this.$t("save"),
          confirmButtonClass: "success",
          cancelButtonText: this.$t("cancel"),
          center: true
        })
          .then(async () => {
            const products = await this.$store.getters["cart/getProducts"];
            const order = await this.$store.getters["order/getOrder"];
            order.items = OrderItem.rawResources(products);
            await OrderApi.createOrder(order)
              .then(async response => {
                if (response.data.result.success) {
                  loading.close();
                  is_loading = false;
                  await auth.removeClientData();
                  await this.$store.dispatch("order/getCount");
                  await this.$store.dispatch("cart/clear");
                  await this.$store.dispatch("order/clear");
                  this.$emit("refresh:products", true);
                  const message =
                    response.data.result.message +
                    " " +
                    `ID ${response.data.result.data.retailOrder.id}`;
                  this.$cNotify.displayMessage(message, "success");
                }
              })
              .catch(err => {
                loading.close();
                is_loading = false;
                if (err.response && err.response.status === 422) {
                  let ms = "";
                  for (const key in err.response.data.validation_errors) {
                    if (
                      err.response.data.validation_errors.hasOwnProperty(key)
                    ) {
                      const element = err.response.data.validation_errors[key];
                      ms += element.join("<br>") + "<br>";
                    }
                  }
                  this.$cNotify.displayMessage(ms, "warning", {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    title: this.$t("warning"),
                    duration: 10000
                  });
                } else {
                  this.$cNotify.displayMessage(err.message, "warning", {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    title: this.$t("warning"),
                    duration: 10000
                  });
                }
              });
          })
          .catch(() => {
            loading.close();
            is_loading = false;
          });
      }
    },
    async getOrders(tab) {
      if (tab.name === "order") {
        const { result } = (await OrderApi.getOrders()).data;
        if (result.success) {
          this.orders = result.data;
        } else {
          this.orders = [];
        }
      }
    },
    async getOrderItems(order_id) {
      let is_loading = true;
      const loading = this.$loading({
        customClass: "login-loading",
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      await OrderApi.getOrderItems(order_id)
        .then(async response => {
          const { result } = response.data;
          if (result.success) {
            loading.close();
            is_loading = false;
            const orderResult = result.data.retailOrder;
            if (orderResult.client) {
              await auth.setClientData(orderResult.client);
            } else {
              await auth.removeClientData();
            }
            const items = OrderItem.reverseResources(
              orderResult.retail_order_items
            );
            const order = Order.reverseResource(orderResult);
            await this.$store.dispatch("cart/clear");
            await this.$store.dispatch("warehouses/clear");
            await this.$store.dispatch("order/clear");
            await this.$store.dispatch("order/create", order);
            await items.map(item => this.$store.dispatch("cart/add", item));
            this.activeTab = "cart";
            return true;
          }
        })
        .catch(err => {
          loading.close();
          is_loading = false;
          if (err.response.data.error.code === 404) {
            this.$$cNotify.displayMessage(
              err.response.data.error.message,
              "error",
              {
                dangerouslyUseHTMLString: true,
                duration: 5000
              }
            );
          }
        });
    },
    print() {
      if (this.hasProducts) {
        this.$confirm(this.$t("printMessage"), {
          confirmButtonClass: "success",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          center: true
        })
          .then(async () => {
            const products = await this.$store.getters["cart/getProducts"];
            const order = await this.$store.getters["order/getOrder"];
            order.items = OrderItem.rawResources(products);
            await OrderApi.getCashBox(order, false)
              .then(async response => {
                const WinPrint = window.open(
                  "",
                  "",
                  "left=0,top=0,toolbar=0,scrollbars=0,status=0"
                );
                WinPrint.document.write(response.data);
                WinPrint.document.close();
                WinPrint.focus();
                setTimeout(() => {
                  WinPrint.print();
                  WinPrint.close();
                }, 1000);
              })
              .catch(() => {});
          })
          .catch(() => {});
      }
    },
    openReturnDrawer() {
      this.ordersForReturnDrawer = true;
    },
    openConsultantDrawer() {
      this.consultantDrawer = true;
    },
    setConsultantToOrder(data) {
      if (data) {
        this.$store.dispatch("order/setConsultant", data);
      }
    },
    openDialogToEditCardItem(item) {
      this.selectedItem = item;
      this.editCardItemDialogVisible = true;
    },
    changeCartItem(data) {
      if (data) {
        this.$store.dispatch("cart/correctCartItem", data);
      }

      this.selectedItem = null;
      this.editCardItemDialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.success {
  color: limegreen !important;
}

.danger {
  color: red !important;
}
</style>
